import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { getSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { Input } from 'tio-ui/components/forms';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import CardGeneric from 'tio-ui/components/card-generic';
import Component from '@glimmer/component';
import TioAlert from 'tio-common/components/tio/alert';
import TioButton from 'tio-common/components/tio/button';
import TioClickableText from 'tio-common/components/tio/clickable-text';
import type IntlService from 'ember-intl/services/intl';
import type StoreService from 'tio-common/services/store';
import type TasParticipantModel from 'tio-common/models/tas-participant';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';
interface StartDateEligibilityComponentArgs {
    Args: {
        canApplyToProgram: boolean;
        applyToProgram: (date: string) => void;
        buttonText: string;
        instanceForExistingApp?: TASProgramInstanceModel;
        isMultipleInstanceProgram: boolean;
        programTemplate: TasProgramTemplate;
        tasParticipant: TasParticipantModel;
    };
}
export default class StartDateEligibilityComponent extends Component<StartDateEligibilityComponentArgs> {
    @service
    store: StoreService;
    @service
    intl: IntlService;
    @tracked
    enteredDate: string;
    @tracked
    eligibilityError = false;
    @tracked
    errors = '';
    @tracked
    loading = false;
    inputClasses = {
        base: 'mr-4',
        input: 'max-h-10'
    };
    calculateEligibility(periodOfEligibility1: [string, string][]) {
        if (periodOfEligibility1.length === 0) {
            return false;
        }
        if (periodOfEligibility1.length === 1) {
            const programEligibility1 = periodOfEligibility1.flat();
            return (this.enteredDate >= programEligibility1[0]! && this.enteredDate <= programEligibility1[1]!);
        }
        for (const eligibility1 of periodOfEligibility1){
            if (this.enteredDate >= eligibility1[0] && this.enteredDate <= eligibility1[1]) {
                return true;
            }
        }
    }
    get canApply() {
        return !(this.enteredDate !== undefined && this.args.canApplyToProgram && !this.eligibilityError);
    }
    get startEndSubmission() {
        const lock1 = getSingleValueForTasField('LOCK_EMPLOYEE_STATUS_BASED_ON', this.args.programTemplate.fields) as string;
        const lockType1: Record<string, string> = {
            COURSE_START_DATE: 'start',
            COURSE_END_DATE: 'end'
        };
        return lockType1[lock1];
    }
    @action
    enterDate(value1: string) {
        this.eligibilityError = false;
        this.errors = '';
        this.enteredDate = value1;
    }
    @action
    async checkEligibilityandSubmit() {
        this.loading = true;
        const { id: id1 } = this.args.tasParticipant;
        const { code: code1 } = this.args.programTemplate;
        const programEligibility1 = await this.store.adapterFor('tas-participant').periodOfEligibility(id1, code1);
        if (this.calculateEligibility(programEligibility1)) {
            this.args.applyToProgram(this.enteredDate);
        } else {
            this.loading = false;
            this.eligibilityError = true;
            this.errors = this.intl.t('start_date_eligibility.submitError');
        }
    }
    static{
        template(`
    <CardGeneric class="mx-0">
      <:header>
        <div class="flex items center" data-test-eligibility-card>
          <div class="flex-grow font-semibold">
            {{t "eligibility_check"}}
          </div>
        </div>
      </:header>
      <:body>
        <div>
          <p>
            {{t "start_date_eligibility.instruction" startEndSubmission=this.startEndSubmission}}
          </p>
        </div>
        <div class="flex my-8">
          <Input
            id="eligibility"
            @type="date"
            @classes={{this.inputClasses}}
            @value={{this.enteredDate}}
            @onChange={{this.enterDate}}
            @errors={{this.errors}}
          />
          <TioButton
            @onClick={{this.checkEligibilityandSubmit}}
            @disabled={{this.canApply}}
            @loading={{this.loading}}
            class="uppercase w-60"
            data-test-submit-eligibility
          >
            {{@buttonText}}
          </TioButton>
        </div>
        {{#if @instanceForExistingApp}}
          <div class="flex text-sm py-2">
            <span>
              {{t "tuition_assistance.you_already_have_an_active_program"}}
            </span>
            <span class="text-center mx-1">
              <TioClickableText
                @linkTo={{if
                  @isMultipleInstanceProgram
                  "authenticated.tuition-assistance.dashboard"
                  "authenticated.tuition-assistance.programs.instance.index"
                }}
                @linkToModel={{unless @isMultipleInstanceProgram @instanceForExistingApp}}
                @textClass="text-sm font-medium text-ocean-600 hover:text-ocean-800 underline"
              >
                {{t "click_here"}}
              </TioClickableText>
            </span>
            <span>
              {{t "tuition_assistance.to_view_program_details"}}
            </span>
          </div>
        {{/if}}
        {{#if this.eligibilityError}}
          <TioAlert @type="error" @allowDismiss={{false}} class="mb-4">
            <:header>
              <p class="font-bold" data-test-eligibility-error>
                {{t
                  "start_date_eligibility.errorHeader"
                  startEndSubmission=this.startEndSubmission
                }}
              </p>
            </:header>
            <:body>
              <p class="text-sm">
                {{t "start_date_eligibility.errorText"}}
              </p>
            </:body>
          </TioAlert>
        {{/if}}
      </:body>
    </CardGeneric>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
