import { template } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { Divider } from 'tio-ui/components/utilities';
import { t } from 'ember-intl';
const QuestionsLoanForgivenessIncomeDrivenComponent: TOC = template(`
  <div class="pb-10">
    {{! Income-Driven Loan Forgiveness }}
    <div class="p-4 mt-6 bg-gray-50">
      <h1 class="text-midnight text-xl font-semibold">{{t
          "pslf.questions.income_driven.idlf.title"
        }}</h1>
      <p class="pt-4">{{t "pslf.questions.income_driven.idlf.description"}}</p>
      <div class="pt-6 flex flex-col sm:grid sm:grid-cols-2">
        <div class="pr-3">
          <ul class="p-4 list-disc">
            <li>{{t "pslf.questions.income_driven.idlf.item_1"}}</li>
          </ul>
        </div>
        <div class="sm:pl-3">
          <ul class="p-4 list-disc">
            <li>{{t "pslf.questions.income_driven.idlf.item_2"}}</li>
          </ul>
        </div>
      </div>
    </div>

    {{! Am I Eligible? }}
    <div class="p-4 mt-6 bg-gray-50">
      <h1 class="text-midnight text-xl font-semibold">{{t "pslf.questions.am_i_eligible"}}</h1>
      <div class="pt-6 flex flex-col sm:grid sm:grid-cols-2">
        <div class="pr-3">
          <h1 class="font-semibold text-gray-600">{{t
              "pslf.questions.income_driven.borrower_eligibility.title"
            }}</h1>
          <p class="pt-4">{{t "pslf.questions.income_driven.borrower_eligibility.description"}}</p>
        </div>
        <div class="sm:pl-3">
          <h1 class="pt-4 sm:pt-0 font-semibold text-gray-600">{{t
              "pslf.questions.income_driven.loan_types.title"
            }}</h1>
          <p class="pt-4">{{t "pslf.questions.income_driven.loan_types.description"}}</p>
        </div>
      </div>
    </div>

    {{! Plan Details }}
    <div class="p-4 mt-6 bg-gray-50">
      <h1 class="text-midnight text-xl font-semibold">{{t "pslf.questions.plan_details"}}</h1>
      <div class="pt-6 pb-2 flex flex-col sm:grid sm:grid-cols-3">
        <div>
          <p class="pt-4 font-semibold text-gray-600">{{t
              "pslf.questions.income_driven.plan.forgiven_after.title"
            }}</p>
          <p>{{t "pslf.questions.income_driven.plan.forgiven_after.description"}}</p>
        </div>
        <div class="sm:ml-5">
          <p class="pt-4 font-semibold text-gray-600">{{t
              "pslf.questions.income_driven.plan.max_forgiveness.title"
            }}</p>
          <p>{{t "pslf.questions.income_driven.plan.max_forgiveness.description"}}</p>
        </div>
        <div>
          <p class="pt-4 font-semibold text-gray-600">{{t
              "pslf.questions.income_driven.plan.tax_info.title"
            }}</p>
          <p>{{t "pslf.questions.income_driven.plan.tax_info.description"}}</p>
        </div>
      </div>
      <Divider class="my-2" />
      <div class="flex flex-col sm:grid sm:grid-cols-2">
        <div class="p-3">
          <h1 class="font-semibold text-lg text-gray-600">{{t
              "pslf.questions.income_driven.plan.pros.title"
            }}</h1>
          <ul class="p-4 pt-0 list-disc">
            <li>{{t "pslf.questions.income_driven.plan.pros.item_1"}}</li>
          </ul>
        </div>
        <div class="p-3">
          <h1 class="font-semibold text-lg text-gray-600">{{t
              "pslf.questions.income_driven.plan.cons.title"
            }}</h1>
          <ul class="p-4 pt-0 list-disc">
            <li>{{t "pslf.questions.income_driven.plan.cons.item_1"}}</li>
            <li>{{t "pslf.questions.income_driven.plan.cons.item_2"}}</li>
          </ul>
        </div>
      </div>
    </div>

    {{! Next Steps }}
    <div class="p-4 mt-6 bg-gray-50">
      <h1 class="text-midnight text-xl font-semibold">
        {{t "pslf.questions.next_steps"}}
      </h1>
      <div class="pt-6 flex flex-col sm:grid sm:grid-cols-2">
        <div class="pr-3">
          <h1 class="font-semibold text-gray-600">
            {{t "pslf.questions.teacher.make_the_switch.title"}}
          </h1>
          <ul class="p-4 list-disc">
            <li>
              {{t "pslf.questions.income_driven.make_the_switch.item_1" htmlSafe=true}}
            </li>
          </ul>
          <h1 class="pt-6 font-semibold text-gray-600">
            {{t "pslf.questions.need_more_help.title"}}
          </h1>
          <p class="pt-4">
            {{t "pslf.questions.need_more_help.description" htmlSafe=true}}
          </p>
        </div>
        <div class="pl-3">
        </div>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default QuestionsLoanForgivenessIncomeDrivenComponent;
