import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router';
import type StoreService from 'tio-common/services/store';
import type TasUnifiedInstitutionModel from 'tio-common/models/tas-unified-institution';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasCourseModel from 'tio-common/models/tas-course';
import type TasApplicationModel from 'tio-common/models/tas-application';

export type AuthenticatedTuitionAssistanceProgramsInstanceCertificateEditRouteModel = {
  instance: TasProgramInstanceModel;
  application: TasApplicationModel;
  selectedSchool?: TasUnifiedInstitutionModel;
  course?: TasCourseModel;
};

export default class AuthenticatedTuitionAssistanceProgramsInstanceCertificateEditRoute extends Route {
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare intl: IntlService;

  includes = [
    'tas-assets',
    'tas-courses',
    'tas-courses.tas-assets',
    'tas-program-instance',
    'tas-program-instance.tas-assets',
    'tas-program-instance.tas-program-template',
    'tas-program-instance.tas-program-template.conditions.public-assets',
    'tas-program-instance.tas-program-template.company.company-setting',
    'tas-program-instance.employee.person',
    'tas-program-instance.employee.tas-participant',
    'tas-program-instance.employee.company',
    'tas-assistances',
    'tags',
  ];

  async model({
    tas_application_id,
  }: {
    tas_application_id: string;
  }): Promise<AuthenticatedTuitionAssistanceProgramsInstanceCertificateEditRouteModel> {
    const application = await this.store.findRecord('tas-application', tas_application_id, {
      include: this.includes.join(','),
      reload: true,
    });

    const instance = application.tasProgramInstance;
    const activeCourse = application.tasCourses?.find((course: TasCourseModel) => course.isActive);

    let selectedSchool: TasUnifiedInstitutionModel | undefined;

    try {
      const tasInstitutionId = instance.fields['TAS_INSTITUTION_ID']?.values?.[0];
      const dapipInstitutionId = instance.fields['DAPIP_INSTITUTION_ID']?.values?.[0];

      if (tasInstitutionId || dapipInstitutionId) {
        const filter: Record<string, string | number> = {
          'tas-program-template': instance.tasProgramTemplate.id,
        };

        if (tasInstitutionId) {
          filter['dapip-id'] = dapipInstitutionId;
        }

        if (tasInstitutionId) {
          filter['institution-id'] = tasInstitutionId;
        }
        const results = await this.store.query('tas-unified-institution', {
          filter,
          page: { limit: 1, offset: 0 },
        });
        selectedSchool = results.objectAt(0);
      }
    } catch (e) {
      console.error('Error attempting to load previously selected school', e);
    }
    return {
      instance: instance,
      application: application,
      selectedSchool: selectedSchool,
      course: activeCourse,
    };
  }
}
