import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { eq } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { Textarea } from '@ember/component';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import TioAlert from 'tio-common/components/tio/alert';
import TioButton from 'tio-common/components/tio/button';
import type IntlService from 'ember-intl/services/intl';
import type PslfFormModel from 'tio-common/models/pslf-form';
import type SessionService from 'tio-employee/services/session';
export interface WhatNextComponentSignature {
    Args: {
        model: PslfFormModel;
    };
}
export default class WhatNextComponent extends Component<WhatNextComponentSignature> {
    @service
    intl: IntlService;
    @service
    session: SessionService;
    @tracked
    textInput = '';
    @tracked
    dictionaryKey = '';
    @tracked
    keyIndex = 0;
    @tracked
    clipboardError = '';
    constructor(owner1: unknown, args1: WhatNextComponentSignature['Args']){
        super(owner1, args1);
        this.decideDictionaryKey();
    }
    get studentLoanCoachRoute() {
        return '/repaying-student-debt/askjeni';
    }
    get companyName() {
        let name1;
        const form1 = this.args.model;
        const employerName1 = form1.employerDisplayName;
        switch(this.dictionaryKey){
            case 'currentEmployer':
                name1 = employerName1;
                break;
            case 'priorCertifyEmployment':
                name1 = employerName1;
                break;
            case 'priorSendFormYourself':
                name1 = employerName1.length == 0 ? 'your former employer' : employerName1;
                break;
            case 'currentSendFormYourself':
                name1 = employerName1.length == 0 ? 'your employer' : employerName1;
                break;
            default:
                break;
        }
        return name1 || '';
    }
    get form() {
        return this.args.model;
    }
    get formAttachment() {
        // @ts-expect-error: nothing in this code sets this.attachments. Is this copy / paste leftover?
        if (!this.form || !this.attachments?.length) {
            return null;
        }
        return (this.form.attachments.find((attachment1)=>{
            return attachment1.tags.includes(`document_id:${this.form?.documentId}`);
        }) || null);
    }
    get formDownloadUrl() {
        return this.formAttachment?.signedUrl || '';
    }
    get showDownloadButton() {
        return !!this.formDownloadUrl && this.isMailInForm;
    }
    get isMailInForm() {
        return [
            'priorSendFormYourself',
            'currentSendFormYourself'
        ].includes(this.dictionaryKey);
    }
    get keys() {
        const { companyName: companyName1 } = this;
        const dictionary1 = {
            currentEmployer: {
                title: 'pslf.what_next.what_next',
                description: this.intl.t('pslf.what_next.current_description', {
                    companyName: companyName1
                }),
                iconText: 'pslf.what_next.check_your_email',
                bodyHeading: this.intl.t('pslf.what_next.current_body_heading'),
                bodyDescription: this.intl.t('pslf.what_next.current_body_description', {
                    companyName: companyName1
                }),
                bulletMsg: 'pslf.what_next.current_bullet_msg',
                bulletpoints: [
                    'pslf.what_next.current_bulletpoint_1',
                    'pslf.what_next.current_bulletpoint_2',
                    'pslf.what_next.current_bulletpoint_3'
                ]
            },
            priorCertifyEmployment: {
                title: 'pslf.what_next.what_next',
                description: this.intl.t('pslf.what_next.prior_certify_description', {
                    companyName: companyName1
                }),
                iconText: 'pslf.what_next.check_your_email',
                bodyHeading: this.intl.t('pslf.what_next.prior_certify_body_heading', {
                    companyName: companyName1
                }),
                bodyDescription: this.intl.t('pslf.what_next.prior_certify_body_description'),
                bulletMsg: 'pslf.what_next.prior_certify_bullet_msg',
                bulletpoints: [
                    'pslf.what_next.prior_certify_bulletpoint_1',
                    'pslf.what_next.prior_certify_bulletpoint_2',
                    'pslf.what_next.prior_certify_bulletpoint_3'
                ]
            },
            priorClosed: {
                title: 'pslf.what_next.what_next',
                description: this.intl.t('pslf.what_next.prior_closed_description'),
                iconText: 'pslf.what_next.prior_closed_icon_text',
                bodyHeading: this.intl.t('pslf.what_next.prior_closed_body_heading'),
                bodyDescription: this.intl.t('pslf.what_next.prior_closed_body_description'),
                bulletMsg: 'pslf.what_next.prior_certify_bullet_msg',
                bulletpoints: [
                    'pslf.what_next.prior_closed_bulletpoint_1',
                    'pslf.what_next.prior_closed_bulletpoint_2'
                ],
                bulletPointLink: this.studentLoanCoachRoute,
                bulletPointLinkText: 'pslf.what_next.prior_closed_bulletpoint_link_text',
                bulletpointDescriptions: [
                    'pslf.what_next.prior_closed_bullet_desc_1',
                    'pslf.what_next.prior_closed_bullet_desc_2',
                    'pslf.what_next.prior_closed_bullet_desc_3'
                ]
            },
            currentSendFormYourself: {
                title: 'pslf.what_next.what_next',
                description: this.intl.t('pslf.what_next.send_description', {
                    companyName: companyName1
                }),
                iconText: 'pslf.what_next.send_icon_text',
                bodyHeading: this.intl.t('pslf.what_next.send_body_heading', {
                    companyName: companyName1
                }),
                bulletMsg: 'pslf.what_next.send_bullet_msg',
                bulletpoints: [
                    'pslf.what_next.send_bulletpoint_1_current',
                    'pslf.what_next.send_bulletpoint_2',
                    'pslf.what_next.send_bulletpoint_3'
                ],
                footer: 'pslf.what_next.send_footer'
            },
            priorSendFormYourself: {
                title: 'pslf.what_next.what_next',
                description: this.intl.t('pslf.what_next.send_description', {
                    companyName: companyName1
                }),
                iconText: 'pslf.what_next.send_icon_text',
                bodyHeading: this.intl.t('pslf.what_next.send_body_heading', {
                    companyName: companyName1
                }),
                bulletMsg: 'pslf.what_next.send_bullet_msg',
                bulletpoints: [
                    'pslf.what_next.send_bulletpoint_1_prior',
                    'pslf.what_next.send_bulletpoint_2',
                    'pslf.what_next.send_bulletpoint_3'
                ],
                footer: 'pslf.what_next.send_footer'
            }
        };
        // @ts-expect-error: need to make object a type and then key off of that type
        return dictionary1[this.dictionaryKey];
    }
    copyToClipboard = async ()=>{
        try {
            await navigator.clipboard.writeText(this.textInput);
        } catch (e1) {
            this.clipboardError = 'Error copying to clipboard.';
        }
    };
    @action
    hideClipboardError() {
        this.clipboardError = '';
    }
    @action
    computeBulletPointText(arg1: string) {
        const bulletPointLink1 = this.keys.bulletPointLink;
        const bulletPointLinkText1 = this.keys.bulletPointLinkText && this.intl.t(this.keys.bulletPointLinkText);
        const result1 = this.intl.t(arg1, {
            link: bulletPointLink1 && bulletPointLinkText1 && `<a class="underline" target="_blank" href=${bulletPointLink1}>${bulletPointLinkText1}</a>`,
            companyName: this.companyName
        });
        return result1;
    }
    addEmailText() {
        const translationKey1 = this.args.model.borrowerFormType === 'priorEmployer' ? 'pslf.what_next.send_text_input_prior' : 'pslf.what_next.send_text_input_current';
        this.textInput = this.intl.t(translationKey1, {
            contactName: this.args.model.employerFields['employer-contact-name'] || '{employer}',
            companyName: this.args.model.employerFields['employer-name'] || '{your company}',
            startDate: this.args.model.employerFields['employment-start-date'] || '{start date}',
            endDate: this.args.model.employerFields['employment-end-date'] || '{end date}',
            borrowerName: this.args.model.borrowerFields['borrower-name'] || '{your employee}'
        })// This needs to be manually replaced because the intl library mishandles newlines
        .replaceAll('\\n', '\n');
    }
    decideDictionaryKey() {
        const form1 = this.args.model;
        const isCurrentEmployerForm1 = form1.isCurrentEmployer;
        this.addEmailText();
        if (form1.borrowerFields['borrower-print-and-mail'] === true) {
            this.dictionaryKey = isCurrentEmployerForm1 ? 'currentSendFormYourself' : 'priorSendFormYourself';
            return;
        }
        if (isCurrentEmployerForm1) {
            this.dictionaryKey = 'currentEmployer';
            return;
        }
        if (form1.borrowerFields['borrower-prior-employer-closed']) {
            this.dictionaryKey = 'priorClosed';
        } else if (form1.borrowerFields['borrower-employer-email']) {
            this.dictionaryKey = 'priorCertifyEmployment';
        } else {
            this.dictionaryKey = 'priorSendFormYourself';
        }
    }
    static{
        template(`
    <div class="m-auto md:w-[790px] mt-8">
      <h2 class="text-2xl text-center w-full">{{t this.keys.title}}</h2>
      <div class="text-lg my-10"> {{this.keys.description}}</div>
      <div class="flex">
        <div class="text-xl flex flex-col items-center mr-10 hidden md:flex min-w-[182px]">
          <MaterialIcon @icon="info_outline" class="text-[36px] text-ocean-600" />
          <h3 class="text-center mt-4 font-semibold uppercase text-base">{{t
              this.keys.iconText
            }}</h3>
        </div>
        <div>
          <h3 class="text-2xl font-semibold"> {{this.keys.bodyHeading}}</h3>
          <div class="mt-4 text-base"> {{this.keys.bodyDescription}} </div>
          <div class="font-semibold my-8 text-base"> {{t this.keys.bulletMsg}}</div>

          {{#each this.keys.bulletpoints as |bulletpoint index|}}
            <div class="flex flex-col my-4">
              <div class="flex">
                <div class="flex">
                  <MaterialIcon @icon="check" class="mr-4 text-ocean-600 font-size-[24px]" />
                </div>
                <span>
                  {{! template-lint-disable no-triple-curlies }}
                  {{{this.computeBulletPointText bulletpoint}}}
                </span>
              </div>
              {{#if (eq index 0)}}
                {{!prettier-ignore}}
                <ul class="list-disc list-inside mb-2 ml-[4em] marker:text-ocean-600">
              {{#each this.keys.bulletpointDescriptions as |bpDescription|}}
                <li class="align-center mt-1">
                  {{t bpDescription}}
                </li>
              {{/each}}
            </ul>
              {{/if}}
            </div>
          {{/each}}

          <div class="what-next-actions">
            {{#unless this.isMailInForm}}
              <TioButton
                class="my-8"
                @outlined={{true}}
                @linkTo="authenticated.pslf.dashboard.index"
              >
                {{t "continue"}}
                <MaterialIcon @icon="chevron_right" />
              </TioButton>
            {{/unless}}
            {{#if this.isMailInForm}}
              <TioButton class="my-8" @outlined={{true}} @onClick={{this.copyToClipboard}}>
                <MaterialIcon @icon="content_copy" />
                {{t "pslf.what_next.send_button_2"}}
              </TioButton>
            {{/if}}
            {{#if this.clipboardError}}
              <TioAlert @type="error" @allowDismiss={{true}} @onDismiss={{this.hideClipboardError}}>
                <:body>
                  {{this.clipboardError}}
                </:body>
              </TioAlert>
            {{/if}}

            {{#if this.showDownloadButton}}
              <TioButton
                rel="noopener noreferrer"
                class="my-8"
                target="_blank"
                @outlined={{true}}
                @href={{this.formDownloadUrl}}
              >
                <MaterialIcon @icon="file_download" />
                <h6>
                  {{t "pslf.what_next.print_mail"}}
                </h6>
              </TioButton>
            {{/if}}
          </div>
          {{#if this.keys.footer}}
            <div class="font-semibold my-4"> {{t this.keys.footer}}</div>
          {{/if}}
          {{#if this.isMailInForm}}
            <Textarea
              id="mail-in"
              area-label="Mail in"
              @value={{this.textInput}}
              class="p-4 text-area mt-4 border-gray w-full h-[380px] border-2 rounded text-lg"
            />
            <TioButton
              @outlined={{true}}
              class="my-4"
              color="accent"
              @linkTo="authenticated.pslf.dashboard.index"
            >
              {{t "continue"}}
              <MaterialIcon @icon="chevron_right" />
            </TioButton>
          {{/if}}
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
