import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import PslfSingleForm from 'tio-employee/components/pslf/dashboard/start-new/single-form';
import RouteTemplate from 'ember-route-template';
import StartNewFormFaqs from 'tio-employee/components/pslf/dashboard/start-new/form-faqs';
import TioButton from 'tio-common/components/tio/button';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import type { IntlService } from 'ember-intl';
import type EmployeeModel from 'tio-common/models/employee';
import type PartnerService from 'tio-employee/services/partner';
import type PslfFormModel from 'tio-common/models/pslf-form';
import type RouterService from '@ember/routing/router-service';
export interface S {
    Args: {
        model: {
            inProgressCurrentEmployerForm: PslfFormModel;
            inProgressPriorEmployerForm: PslfFormModel;
            employee: EmployeeModel;
        };
    };
}
let PslfDashboardStartNewRoute = class PslfDashboardStartNewRoute extends Component<S> {
    @service
    router: RouterService;
    @service
    partner: PartnerService;
    @service
    intl: IntlService;
    @tracked
    borrowerFormType = 'currentEmployer';
    @tracked
    showForm = false;
    @action
    scrollToElement() {
        let element1 = document.getElementById('form-div');
        if (element1) {
            element1.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }
    @action
    startNewForm() {
        this.showForm = true;
        setTimeout(()=>{
            this.scrollToElement;
        }, 2);
    }
    get isPriorSelected() {
        return this.borrowerFormType === 'priorEmployer';
    }
    get showFormTypeRadioGroup() {
        return this.partner.isEnabledPriorEmployerPslf;
    }
    get startNewButtonLabel() {
        return this.intl.t('pslf.start_new.start_my_form');
    }
    static{
        template(`
    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb @route="authenticated.pslf.dashboard.index" @label="Dashboard" />
      <b.crumb
        @route="authenticated.pslf.dashboard.start-new"
        @label={{t "pslf.start_new.start_new_form"}}
      />
    </TioPageBreadcrumbs>

    <div class="flex flex-col items-center">

      <section class="my-4 md:my-6 max-w-screen-md">
        <p class="font-bold text-2xl pb-8">
          {{t "pslf.start_new.pslf_form_header"}}
        </p>
        <p>{{t "pslf.start_new.pslf_preamble"}}</p>
        {{#if this.showForm}}
          <div class="flex flex-col items-center" id="form-div">
            <div class="w-full max-w-screen-md">
              <PslfSingleForm @employee={{@model.employee}} />
            </div>
          </div>
        {{else}}
          <TioButton @onClick={{this.startNewForm}} class="my-8 w-full max-w-72 mx-auto md:mx-0">
            {{t "pslf.start_new.start_my_form"}}
          </TioButton>
        {{/if}}
      </section>
      {{#unless this.showForm}}
        <StartNewFormFaqs class="mb-6 mt-4 w-full max-w-screen-md" />
      {{/unless}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(PslfDashboardStartNewRoute);
