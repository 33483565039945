import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import type PslfProfileModel from 'tio-common/models/pslf-profile';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import TioButton from 'tio-common/components/tio/button';
import PslfMyDocumentsMyFormsTable from 'tio-employee/components/pslf/my-documents/my-forms-table';
import PslfMyDocumentsUploadsTable from 'tio-common/components/pslf/my-documents/uploads-table';
import PslfMyDocumentsPslfUploadModal from 'tio-employee/components/pslf/my-documents/pslf-upload-modal';
import AppReadSecurityButton from 'tio-employee/components/app/read-security-button';
interface S {
    Args: {
        model: PslfProfileModel;
    };
}
let PslfMyDocumentsRoute = class PslfMyDocumentsRoute extends Component<S> {
    @tracked
    showUploadModal = false;
    @tracked
    limit = 10;
    @action
    openUploadModal() {
        this.showUploadModal = true;
    }
    @action
    closeUploadModal() {
        this.showUploadModal = false;
    }
    static{
        template(`
    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb @route="authenticated.pslf.dashboard.index" @label="Dashboard" />
      <b.crumb @route="authenticated.pslf.dashboard.my-documents" @label="Forms & Documents" />
    </TioPageBreadcrumbs>

    <h3 class="text-lg font-semibold text-left h-12 align-bottom">
      {{t "pslf.document_repo.my_pslf_forms"}}
      <TioButton class="float-end" @onClick={{this.openUploadModal}}>
        {{t "upload_documents"}}
      </TioButton>
    </h3>
    <PslfMyDocumentsMyFormsTable class="my-4" @limit={{this.limit}} @employee={{@model.employee}} />

    <h3 class="text-lg font-semibold text-left">
      {{t "pslf.profiles.uploads"}}
    </h3>
    <PslfMyDocumentsUploadsTable
      class="my-4"
      @limit={{this.limit}}
      @profile={{@model}}
      @allowDelete={{true}}
    />

    <AppReadSecurityButton class="my-14" />

    {{#if this.showUploadModal}}
      <PslfMyDocumentsPslfUploadModal
        class="mx-5"
        @onCancel={{this.closeUploadModal}}
        @didSave={{this.closeUploadModal}}
        @profile={{@model}}
        @employee={{@model.employee}}
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(PslfMyDocumentsRoute);
