import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import AccountLinkingSlr from 'tio-employee/components/account-linking/slr';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import AppPage from 'tio-employee/components/app/page';
import AppPageContent from 'tio-employee/components/app/page/content';
import { t } from 'ember-intl';
let AccountActivityNewRouteComponent = class AccountActivityNewRouteComponent extends Component {
    @service
    router: RouterService;
    @action
    onLinkingSuccess() {
        this.router.transitionTo('authenticated.slr.linking');
    }
    @action
    didCancel() {
        this.router.transitionTo('authenticated.slr.linking');
    }
    static{
        template(`
    <AppPage @title={{t "account_linking.add_account"}}>
      <AppPageContent>
        <AccountLinkingSlr @onSuccess={{this.onLinkingSuccess}} @onCanceled={{this.didCancel}} />
      </AppPageContent>
    </AppPage>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AccountActivityNewRouteComponent);
