import EmberRouter from '@ember/routing/router';
import config from 'tio-employee/config/environment';
import ENV from 'tio-employee/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

// NOTE: The paths below MUST match the paths within the Vue app. When the user
//       clicks a link or button in the Vue app that triggers a navigation, the
//       Ember app receives a message to navigate to that same path. If the
//       paths aren't the same, that navigation will fail. [twl 19.Aug.22]
//
Router.map(function () {
  this.route('freestyle');
  this.route('welcome');
  this.route('login');
  this.route('auth-by-token');
  this.route('auth-failed');
  this.route('role-select');
  this.route('logout');
  this.route('logged-out');
  this.route('enroll');
  this.route('register', function () {
    this.route('index', { path: '/' });
    this.route('confirmation');
  });
  this.route('forgot-password', function () {
    this.route('index', { path: '/' });
    this.route('confirmation');
  });
  this.route('reset-password', function () {
    this.route('index', { path: '/' });
    this.route('confirmation');
  });
  this.route('redirect');
  this.route('pslf-employer', { path: 'pslf-employer/:form_document_id' }, function () {
    this.route('cancelled');
    this.route('non-partner-verification');
  });

  if (ENV.environment === 'test') {
    this.route('test-start');
  }

  if (
    ENV.environment === 'development' ||
    ENV.tioEnvironment === 'vodka' ||
    ENV.tioEnvironment === 'local'
  ) {
    this.route('kitchen-sink', function () {
      this.route('index', { path: '/' });
      this.route('employee');
    });
  }

  this.route('self-registration');

  this.route('authenticated', { path: '/' }, function () {
    // --------------------------------------------------------
    // Employee routes
    // --------------------------------------------------------
    this.route('contact-us');
    this.route('dashboard', function () {
      this.route('index', { path: '/' });
      this.route('your-dashboard');
    });
    this.route('account-activity', function () {
      this.route('index', { path: '/' });
      this.route('accounts');
      this.route('loan-projections');
      this.route('new');
    });

    this.route('pslf', function () {
      this.route('index', { path: '/' });
      this.route('linking');
      this.route('dashboard', function () {
        this.route('my-documents');
        this.route('start-new');
        this.route('recommendations');
        this.route('forgiveness-estimator', function () {
          this.route('index', { path: '/' });
          this.route('results', { path: ':recommendation_id' }, function () {
            this.route('index', { path: '/' });
            this.route('next-steps');
          });
        });
      });
      this.route('form', function () {
        this.route('sign', { path: ':form_id/sign' });
        this.route('sign-form');
        this.route('what-next');
      });
      this.route('questions', function () {
        this.route('index');
        this.route('income-driven');
        this.route('teacher');
        this.route('state-and-local');
      });
      this.route('form-overview');
    });
    this.route('tuition-assistance', function () {
      this.route('index', { path: '/' });
      this.route('dashboard');
      this.route('dependent-programs');
      this.route('my-payment-details');
      this.route('messages');
      this.route('mentoring');
      this.route('program-overview', {
        path: 'program-overview/:tas_program_template_id',
      });

      this.route('programs', function () {
        this.route('instance', { path: ':tas_program_instance_id' }, function () {
          // Route for pre-approved program instance overview and details
          this.route('index', { path: '/' });
          this.route('messages');

          // Routes for program application pre-approval forms
          // (the model is a TasProgramInstance, which we get from the parent 'instance' route)
          this.route('apply', function () {
            this.route('index', { path: '/' });
            this.route('employee-information');
            this.route('program-information');
            this.route('review');
          });

          // Routes for certificate programs (model will include TasProgramInstance, TasApplication, & TasCourse)
          this.route('certificate', function () {
            this.route('index', { path: '/' });
            this.route('new');
            this.route('edit', {
              path: 'edit/:tas_application_id',
            });
          });

          // Routes for courses applications (the model is a TasApplication)
          this.route('course-applications', function () {
            this.route('new');

            this.route('show', { path: ':tas_application_id' }, function () {
              this.route('index', { path: '/' });
              this.route('messages');
              this.route('certificate');

              this.route('pre-approval', function () {
                this.route('index', { path: '/' });
                this.route('add-course');
                this.route('edit-course', {
                  path: 'edit-course/:tas_course_id',
                });
                this.route('review');
              });

              this.route('complete', function () {
                this.route('course-evidence', {
                  path: 'course-evidence/:tas_course_id',
                });
                this.route('review');
              });
            });
          });
        });
      });
    });
    this.route('repaying-student-debt', function () {
      this.route('index', { path: '/' });
      this.route('askjeni', function () {
        this.route('index', { path: '/' });
        this.route('schedule');
      });
      this.route('repayment-strategy-finder', function () {
        this.route('index', { path: '/' });
        this.route('dashboard', function () {
          this.route('index', { path: '/' });
          this.route('recommendations');
          this.route('recommendation-history');
          this.route('questionnaire', function () {
            this.route('index', { path: '/' });
            this.route('results', { path: ':recommendation_id' }, function () {
              this.route('index', { path: '/' });
              this.route('next-steps');
            });
          });
        });
      });
      this.route('loan-forgiveness', function () {
        this.route('index');
        this.route('income-driven');
        this.route('teacher');
        this.route('state-and-local');
      });
      this.route('repayment-options', function () {
        this.route('index', { path: '/' });
        this.route('refinancing');
      });
    });
    this.route('ten-twenty-cancellation');
    this.route('profile', function () {
      this.route('index', { path: '/' });
    });
    this.route('planning-for-college', function () {
      this.route('index', { path: '/' });
      this.route('college-cost-calculator');
      this.route('scholarship');
      this.route('529-tools', function () {
        this.route('index');
        this.route('savings');
        this.route('faqs');
      });
    });
    this.route('questions-you-have', function () {
      this.route('index', { path: '/' });
      this.route('paying-for-college', function () {
        this.route('index', { path: '/' });
        this.route('private-loans');
        this.route('plus-loans');
      });
      this.route('repayment-plans', function () {
        this.route('index', { path: '/' });
        this.route('graduated');
        this.route('extended');
        this.route('income-driven');
      });
      this.route('repayment-options', function () {
        this.route('index', { path: '/' });
        this.route('refinancing');
      });
      this.route('loan-forgiveness', function () {
        this.route('index', { path: '/' });
        this.route('income-driven');
        this.route('teacher');
        this.route('state-and-local');
      });
      this.route('glossary');
    });
    this.route('idr', function () {
      this.route('index', { path: '/' });
      this.route('dashboard', function () {
        this.route('index', { path: '/' });
        this.route('recommendations');
        this.route('recommendation-history');
        this.route('forgiveness-estimator', function () {
          this.route('index', { path: '/' });
          this.route('results', { path: ':recommendation_id' }, function () {
            this.route('index', { path: '/' });
            this.route('next-steps');
          });
        });
      });
    });

    this.route('syf', function () {
      this.route('index', { path: '/' });
      this.route('account-confirmation');
      this.route('linking-confirmation', { path: 'linking-confirmation/:institution_id' });
      this.route('post-enrollment');
      this.route('account-linking');
      this.route('enrollment');
      this.route('dashboard');
      this.route('payments', function () {
        // unused as of https://tuitionio.atlassian.net/browse/DEV-585 - james 2024-02-12
        // this.route('missing');
        this.route('new');
      });
      this.route('insights', function () {
        this.route('four-oh-one-k', { path: '401k' });
        this.route('idr');
        this.route('pslf');
      });
    });

    this.route('slr', function () {
      this.route('linking');
      this.route('linking-confirmation', { path: 'linking-confirmation/:institution_id' });
      this.route('dashboard');
    });

    if (ENV.featureFlags.observabilityUploads) {
      this.route('observability', function () {
        this.route('upload');
        // TODO: url param for identifier for whichever thing this confirms
        this.route('confirmation');
      });
    }

    if (
      ENV.tioEnvironment === 'staging' ||
      ENV.tioEnvironment === 'vodka' ||
      ENV.tioEnvironment === 'local'
    ) {
      this.route('tas', function () {
        this.route('dashboard', function () {
          this.route('summary');
          this.route('applications');
          this.route('history');
          this.route('benefits');
          this.route('network');
        });
        this.route('getting-started', {
          // essentially program-overview
          path: ':tas_program_template_id/getting-started',
        });

        // For now this is going to be very specific to Type 2 because normally we also have a program instance id
        this.route('applications', function () {
          this.route('show', { path: ':tas_application_id/show' });
          this.route('history', { path: ':tas_application_id/history' });
          this.route('type-two', function () {
            this.route('new', {
              path: ':tas_application_id/new',
            });
          });
          this.route('type-three', function () {
            this.route('new', {
              path: ':tas_application_id/new',
            });
          });
        });
      });
    } else {
      // Always available /getting-started route but keeping in else block to remember to remove
      this.route('tas', function () {
        this.route('getting-started', {
          path: ':tas_program_template_id/getting-started',
        });
      });
    }

    // --------------------------------------------------------
    // Admin routes
    // --------------------------------------------------------
    this.route('admin', function () {
      this.route('plans', { path: ':company_id' });
      this.route('employees', function () {
        this.route('index', { path: '/' });
        this.route('show', { path: ':employee_id' });
        this.route('add');
      });
      this.route('eligibility-files', function () {
        this.route('index', { path: '/' });
        this.route('show', { path: ':eligibility_file_job_log_id' });
      });
      this.route('reports', function () {
        this.route('index', { path: '/' });
        this.route('show', { path: ':quicksight_dashboard_id' });
      });
      this.route('pslf', function () {
        this.route('forms', { path: '/' });
        this.route('form', function () {
          this.route('sign', { path: ':form_id/sign' });
          this.route('review', { path: ':form_id/review' });
          this.route('success', { path: ':form_id/success' });
        });
      });
      this.route('tuition-assistance', function () {
        this.route('index', { path: '/' });
        this.route('dashboard');
        this.route('payments');
        this.route('program-templates', function () {
          this.route('show', { path: ':tas_program_template_id' });
        });
        this.route('applications', function () {
          this.route('index', { path: '/' });
          this.route('show', { path: ':tas_application_id' }, function () {
            this.route('index', { path: '/' });
            this.route('messages');
            this.route('readonly');
          });
        });
        this.route('program-instances', function () {
          this.route('index', { path: '/' });
          this.route('show', { path: ':tas_program_instance_id' }, function () {
            this.route('index', { path: '/' });
            this.route('messages');
            this.route('readonly');
          });
        });
      });
      this.route('reporting', function () {
        this.route('index', { path: '/' });
        this.route('tas-payables');
        this.route('tas-payments');
        this.route('tas-applications');
        this.route('tas-programs');
      });
    });
  });
});
