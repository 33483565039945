import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import AppPage from 'tio-employee/components/app/page';
import QuestionsGlossary from 'tio-employee/components/questions/glossary';
export default RouteTemplate(template(`
    <AppPage class="w-[95vw] sm:w-fit" @title={{t "questions_you_have.glossary.glossary"}}>
      <h1>{{t "questions_you_have.glossary.glossary_subtitle"}}</h1>
      <QuestionsGlossary />
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
