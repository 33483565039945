import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { concat } from '@ember/helper';
import { htmlSafe } from '@ember/template';
import { LinkTo } from '@ember/routing';
import { on } from '@ember/modifier';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import { t } from 'ember-intl';
export interface AccountLinkingFooterSignature {
    Args: {
        isMohela: boolean;
        routeForSkipStep?: string;
    };
}
let AccountLinkingFooterComponent = class AccountLinkingFooterComponent extends Component<AccountLinkingFooterSignature> {
    @tracked
    showTechSupport = false;
    @action
    toggleTechSupport() {
        this.showTechSupport = !this.showTechSupport;
    }
    static{
        template(`
    {{! template-lint-disable no-inline-styles }}
    <div class="bg-white w-full">
      <div class="border-t border-gray-200 px-4 grid grid-cols-3 text-sm">
        <button
          type="button"
          {{on "click" this.toggleTechSupport}}
          class="text-ocean-600 col-span-1 mt-4"
        >
          <div class="flex items-center">
            <MaterialIcon @icon="info" class="mr-2" />
            <div class="underline">
              {{t "syf.footer.tech_support.default"}}
            </div>
          </div>
        </button>
        <div class="col-span-1 mt-4 text-center">
          {{#if @routeForSkipStep}}
            <p class="mb-2">{{t "syf.footer.not_able_to_find_your_servicer"}}</p>
            <LinkTo @route={{@routeForSkipStep}} class="text-ocean-600 underline">
              {{t "syf.footer.skip_this_step_for_now"}}
            </LinkTo>
          {{/if}}
          {{#if @isMohela}}
            <p class="mb-2">{{t "syf.footer.mohela" htmlSafe=true}}</p>
          {{/if}}
        </div>

        {{#if this.showTechSupport}}
          <section class="col-span-3 my-4 p-3 right-16">
            <div class="flex w-full items-center justify-between text-gray-600">
              <h2 class="text-lg font-medium mb-4">
                {{t "syf.footer.tech_support.adding_my_student_loans"}}
              </h2>
              <button
                type="button"
                {{on "click" this.toggleTechSupport}}
                class="flex h-full justify-center items-start self-start"
              >
                <MaterialIcon @icon="close" />
              </button>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div class="col-span-1">
                <h4 class="font-semibold mb-2">{{t "syf.footer.tech_support.question_one"}}</h4>
                <p class="leading-loose">{{t "syf.footer.tech_support.answer_one"}}</p>

                <h4 class="font-semibold mb-2">{{t "syf.footer.tech_support.question_two"}}</h4>
                <p class="leading-loose">{{htmlSafe (t "syf.footer.tech_support.answer_two")}}</p>
              </div>
              <div class="col-span-1">
                <h4 class="font-semibold mb-2">{{t "syf.footer.tech_support.question_three"}}</h4>
                <p class="leading-loose">{{t "syf.footer.tech_support.answer_three"}}</p>
              </div>
              <div class="col-span-1">
                <h4 class="font-semibold mb-2">{{t
                    "syf.footer.tech_support.still_have_questions"
                  }}</h4>
                <a href="mailto:support@tuition.io" class="leading-loose">
                  {{concat (t "contact_us.email_us") ": " (t "contact_us.tio_support_email")}}
                </a>
                <p class="leading-loose">
                  {{concat (t "contact_us.call_us") ": " (t "contact_us.tio_support_phone")}}
                </p>
              </div>
            </div>
          </section>
        {{/if}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default AccountLinkingFooterComponent;
