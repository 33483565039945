import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import AppPage from 'tio-employee/components/app/page';
import RouteTemplate from 'ember-route-template';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';
import TioPageHeaderTitle from 'tio-common/components/tio/page/header/title';
import TuitionAssistanceProgramTemplateReviewProgramSetupReview from 'tio-common/components/tuition-assistance/program-template-review/program-setup-review';
import TuitionAssistanceProgramTemplateReviewApplicationConfigReview from 'tio-common/components/tuition-assistance/program-template-review/application-config-review';
import TuitionAssistanceProgramTemplateReviewCourseApplicationSetup from 'tio-common/components/tuition-assistance/program-template-review/course-application-setup';
import TuitionAssistanceProgramTemplateReviewApproverInstructionsAndFaqs from 'tio-common/components/tuition-assistance/program-template-review/approver-instructions-and-faqs';
import TuitionAssistanceProgramTemplateReviewNotifications from 'tio-common/components/tuition-assistance/program-template-review/notifications';
interface S {
    Args: {
        model: TasProgramTemplate;
    };
}
export default RouteTemplate<S>(template(`
    <AppPage>
      <div>
        <TioPageHeaderTitle>
          {{t "common.program_review"}}
        </TioPageHeaderTitle>
        <TuitionAssistanceProgramTemplateReviewProgramSetupReview @model={{@model}} />
        <TuitionAssistanceProgramTemplateReviewApplicationConfigReview @model={{@model}} />
        <TuitionAssistanceProgramTemplateReviewCourseApplicationSetup @model={{@model}} />
        <TuitionAssistanceProgramTemplateReviewApproverInstructionsAndFaqs @model={{@model}} />
        <TuitionAssistanceProgramTemplateReviewNotifications @model={{@model}} />
      </div>
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
