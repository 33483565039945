import { template } from "@ember/template-compiler";
import { array } from '@ember/helper';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import AppPageContent from 'tio-employee/components/app/page/content';
import ApproverComments from 'tio-common/components/tuition-assistance/approver-comments';
import Component from '@glimmer/component';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import TuitionAssistanceApplicationDetailsApprovalQueueStatus from 'tio-common/components/tuition-assistance/application-details/approval-queue-status';
import TuitionAssistanceApplicationDetailsApproverOverview from 'tio-common/components/tuition-assistance/application-details/approver-overview';
import TuitionAssistanceApplicationDetailsEmployeeInformation from 'tio-common/components/tuition-assistance/application-details/employee-information';
import TuitionAssistanceApplicationDetailsProgramStatusApproval from 'tio-common/components/tuition-assistance/application-details/program-status-approval';
import TuitionAssistanceProgramDetailsAppDetailsAppHistory from 'tio-common/components/tuition-assistance/program-details/app-details/app-history';
import TuitionAssistanceProgramDetailsApplicationInformation from 'tio-common/components/tuition-assistance/program-details/application-information';
import TuitionAssistanceProgramDetailsProgramInfoCard from 'tio-common/components/tuition-assistance/program-details/program-info-card';
import type SessionContextService from 'tio-employee/services/session-context';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
interface RouteSignature {
    Args: {
        model: TasProgramInstanceModel;
    };
}
let TuitionAssistanceProgramInstancesShowIndexRouteComponent = class TuitionAssistanceProgramInstancesShowIndexRouteComponent extends Component<RouteSignature> {
    @service
    sessionContext: SessionContextService;
    static{
        template(`
    {{pageTitle (t "tuition_assistance.program_details.default")}}

    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb
        @route="authenticated.admin.tuition-assistance.program-instances.index"
        @label="Applications"
      />
      <b.crumb
        @route="authenticated.admin.tuition-assistance.program-instances.show"
        @label={{@model.programName}}
      />
    </TioPageBreadcrumbs>

    <AppPageContent>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div class="col-span-1 space-y-5">
          <TuitionAssistanceApplicationDetailsEmployeeInformation
            @instance={{@model}}
            @profileRoute="authenticated.admin.employees.show"
            @routeModel={{@model.employee.id}}
          />
          <TuitionAssistanceProgramDetailsApplicationInformation
            @application={{@model}}
            @isAdminView={{true}}
            @canRequestRepayment={{this.sessionContext.user.isAccountOwnerOrEmployeeAdmin}}
            @isReadonlyView={{false}}
            @requestingApp="employee"
            class="w-full"
          />
          <TuitionAssistanceProgramDetailsProgramInfoCard
            @programInstance={{@model}}
            @showAttachments={{true}}
            class="w-full"
          />
        </div>

        <div class="col-span-1 space-y-5">
          <TuitionAssistanceApplicationDetailsApproverOverview
            @programTemplate={{@model.tasProgramTemplate}}
          />
        </div>
        <div class="col-span-1">
          <ApproverComments
            @commentable={{@model}}
            @commentableType="TasProgramInstance"
            @authoredByPerson={{this.sessionContext.user.person}}
            @commentTags={{array "approver"}}
          />
        </div>
      </div>

      <TuitionAssistanceApplicationDetailsApprovalQueueStatus
        @application={{@model}}
        @consumingApp="employee"
        @isReadonlyView={{false}}
      />

      <TuitionAssistanceApplicationDetailsProgramStatusApproval
        @instance={{@model}}
        @routePrefix="authenticated.admin"
      />

      <TuitionAssistanceProgramDetailsAppDetailsAppHistory
        @application={{@model}}
        @viewType="admin"
      />
    </AppPageContent>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(TuitionAssistanceProgramInstancesShowIndexRouteComponent);
