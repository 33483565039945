import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { concat } from '@ember/helper';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import CardGeneric from 'tio-common/components/card/generic';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import tippy from 'ember-tippy/modifiers/tippy';
import type AccountModel from 'tio-common/models/account';
import type LoanModel from 'tio-common/models/loan';
import Table from 'tio-common/components/table/index';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
export interface AccountTableSignature {
    Args: {
        account: AccountModel;
    };
    Element: HTMLElement;
}
const tableRowCellClass = 'px-2 py-1 border border-gray-300';
interface TableRowSignature {
    Args: {
        loan: LoanModel;
        paid?: boolean;
    };
}
const TableRow: TOC<TableRowSignature> = template(`
  <tr>
    <td class={{tableRowCellClass}}>{{@loan.name}}</td>
    <td class={{tableRowCellClass}}>{{@loan.loanTypeDisplayName}}</td>
    <td class={{tableRowCellClass}}>
      {{#if @loan.repaymentPlan}}
        {{if
          @paid
          (t "accounts.paid_in_full")
          (t (concat "common.repayment_plans." @loan.repaymentPlan))
        }}
      {{else}}
        {{t "common.unknown"}}
      {{/if}}
    </td>
    <td class={{tableRowCellClass}}>{{if @paid (t "accounts.paid_in_full") @loan.status}}</td>
    <td class={{tableRowCellClass}}>
      {{t
        "common.accounts.interest_due"
        interestPercentage=@loan.interestRate
        interestDue=(safeFormatNumber @loan.interestBalance style="currency" currency="USD")
      }}
    </td>
    <td class={{tableRowCellClass}}>
      {{safeFormatNumber @loan.currentBalance style="currency" currency="USD"}}
    </td>
    <td class={{tableRowCellClass}}>
      {{safeFormatNumber @loan.originalAmount style="currency" currency="USD"}}
      {{t "common.on"}}
      {{safeFormatDate @loan.originalDate month="short" day="2-digit" year="numeric"}}
    </td>
  </tr>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default class AccountTable extends Component<AccountTableSignature> {
    @tracked
    isExpanded = true;
    get account() {
        return this.args.account;
    }
    get accountNumber() {
        return this.account['payable-account-number'] || 'unknown';
    }
    get loans() {
        return this.account.activeLoans.flat();
    }
    get unpaidLoans() {
        return this.loans.filter((l1)=>l1.currentBalance);
    }
    get unpaidLoansTotalBalance() {
        return this.unpaidLoans.reduce((a1, b1)=>a1 + b1.currentBalance, 0);
    }
    get paidLoans() {
        return this.loans.filter((loan1)=>loan1.status === 'PAID_IN_FULL');
    }
    get address() {
        return this.account?.postalAddress;
    }
    get addressLines() {
        return this.address ? [
            1,
            2,
            3
        ].filter((n1)=>{
            return this.address[`addressLine${n1}`];
        }).map((n1)=>{
            return this.address[`addressLine${n1}`];
        }).join(' ') : null;
    }
    get mailingAddress() {
        if (this.addressLines && this.address) {
            return `${this.addressLines}, ${this.address.city}, ${this.address.state}, ${this.address.postalCode}`;
        } else {
            return null;
        }
    }
    @action
    toggleExpand() {
        this.isExpanded = !this.isExpanded;
    }
    static{
        template(`
    <div class="mt-2">
      <button
        type="button"
        class="text-ocean-600 hover:text-ocean-800 text-left flex flex-row items-center mt-2"
        {{on "click" this.toggleExpand}}
      >
        <MaterialIcon @icon={{if this.isExpanded "remove" "add"}} class="text-ocean-600 mr-2" />
        <span class="text-ocean-600 font-semibold my-2">
          {{this.account.institutionName}}
        </span>
      </button>
      <div class="mb-2 ml-2">
        <span class="block text-sm text-gray-700">{{t "accounts.last_updated"}}
          {{safeFormatDate
            this.account.providerAttemptAt
            month="short"
            day="2-digit"
            year="numeric"
          }}</span>
      </div>
      {{#if this.isExpanded}}
        <div class="flex flex-col sm:grid sm:gap-2 sm:grid-cols-2 mb-2">
          <CardGeneric>
            <:title>{{t "accounts.payment_details"}}</:title>
            <:content>
              <table class="border-collapse text-sm text-gray-80">
                <thead>
                  <tr>
                    <th>{{t "accounts.account_number"}}</th>
                    <th>{{t "accounts.address"}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{this.accountNumber}}</td>
                    <td>{{this.mailingAddress}}</td>
                  </tr>
                </tbody>
              </table>
            </:content>
          </CardGeneric>
          <CardGeneric>
            <:title>
              {{t "accounts.account_details"}}
            </:title>
            <:content>
              <table class="border-collapse text-sm text-gray-80">
                <thead>
                  <tr>
                    <th>{{t "accounts.regular_monthly_payment"}}</th>
                    <th>{{t "accounts.balance"}}</th>
                    <th>{{t "accounts.principal"}}</th>
                    <th>{{t "accounts.interest_total"}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="px-2">
                      {{safeFormatNumber
                        this.account.providerRegularMonthlyPayment
                        style="currency"
                        currency="USD"
                      }}
                    </td>
                    <td class="px-2">
                      {{safeFormatNumber this.account.loanTotal style="currency" currency="USD"}}
                    </td>
                    <td class="px-2">
                      {{safeFormatNumber
                        this.account.principalBalance
                        style="currency"
                        currency="USD"
                      }}
                    </td>
                    <td class="px-2">
                      {{safeFormatNumber
                        this.account.interestBalance
                        style="currency"
                        currency="USD"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </:content>
          </CardGeneric>
        </div>
      {{/if}}
      {{! UNPAID LOANS - EXPANDABLE TABLE }}
      <Table @isLoading={{false}} ...attributes>
        <:thead>
          <tr class="font-semibold text-left bg-ocean-50">
            {{#let "px-2 py-1 border border-gray-300" as |cellClass|}}
              <th class={{cellClass}}>{{t "common.name"}}</th>
              <th class={{cellClass}}>
                {{t "common.type"}}
                <MaterialIcon
                  @icon="info"
                  class="text-lg ml-1 leading-none text-ocean-600"
                  {{tippy (t "accounts.type_info") placement="top"}}
                />
              </th>
              <th class={{cellClass}}>
                {{t "accounts.repayment_plan"}}
                <MaterialIcon
                  @icon="info"
                  class="text-lg ml-1 leading-none text-ocean-600"
                  {{tippy (t "accounts.plan_info") placement="top"}}
                />
              </th>
              <th class={{cellClass}}>
                {{t "status"}}
                <MaterialIcon
                  @icon="info"
                  class="text-lg ml-1 leading-none text-ocean-600"
                  {{tippy (t "accounts.status_info") placement="top"}}
                />
              </th>
              <th class={{cellClass}}>
                {{t "pslf_dashboard.actions.dialog.interest_rate"}}
                <MaterialIcon
                  @icon="info"
                  class="text-lg ml-1 leading-none text-ocean-600"
                  {{tippy (t "accounts.rate_info") placement="top"}}
                />
              </th>
              <th class={{cellClass}}>{{t "current_balance"}}</th>
              <th class={{cellClass}}>{{t "accounts.date_borrowed"}}</th>
            {{/let}}
          </tr>
        </:thead>
        <:tbody>
          {{#each this.unpaidLoans as |loan|}}
            <TableRow @loan={{loan}} />
          {{/each}}
          {{#each this.paidLoans as |loan|}}
            <TableRow @loan={{loan}} @paid={{true}} />
          {{/each}}
        </:tbody>
        <:tfoot>
          <tr class="font-semibold text-left bg-ocean-50">
            {{#let "px-2 py-1 border border-gray-300" as |cellClass|}}
              <td class={{cellClass}} colspan="7">
                <div class="text-right">
                  {{t "accounts.sub_total"}}
                  {{safeFormatNumber this.unpaidLoansTotalBalance style="currency" currency="USD"}}
                </div>
                <div class="text-right">
                  {{t "accounts.total_balance"}}
                  {{safeFormatNumber this.account.principalBalance style="currency" currency="USD"}}
                </div>
              </td>
            {{/let}}
          </tr>
        </:tfoot>
      </Table>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
