/* eslint-disable ember/no-get */

// NOTE: This file isn't typed great, but it is a service we are working to
// remove so didn't put a lot of effort into types.
import Service from '@ember/service';
import { getOwner } from '@ember/application';
import { get } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { WindowMessenger } from 'tio-employee/utils/windowMessenger';
import type RouterService from './router';
import type SessionContextService from './session-context';
import type RootLoggerService from './root-logger';
import type SessionService from './session';
import type PslfFormService from './pslf-form';
import type RoleViewModel from 'tio-common/models/role-view';

const nonSsoSubdomains = ['employee', 'localhost', 'tio-employee-shell'];

type CredentialsType = {
  email: string;
  password: string;
};

type RoleType = {
  roleType: string;
  relationshipType: string;
  companyId: number;
};

/**
 * A service for integrating a Vue app with this Ember app. For more details on
 * how this works, see {@tutorial vue-integration}.
 *
 * This service has 3 main responsibilities:
 *
 * 1. Manage Iframe
 *    Load the Vue app into an iframe and manage the visibility and position of
 *    the iframe so the Vue app appears seamlessly as part of the Ember app.
 *
 * 2. Send Messages
 *    Send messages to the Vue app to keep the Vue app in sync with this app.
 *
 * 3. Receive Messages
 *    Receive messages from the Vue app to keep this app in sync with the Vue
 *    app and to manage things like app initialization and authentication.
 *
 * Messages sent to and from the Vue app must have a `type` property to indicate
 * the type of message and an optional `payload` property, which contains the
 * data for that specific message.
 *
 *
 * ### Incoming Messages
 *
 * Messages received from the Vue app include:
 *
 * `loaded`
 * : After the Vue app has loaded and initialized its integration with this app.
 *
 * `reloading`
 * : Sent just before the Vue app reloads to indicate it is disconnecting until
 *   the next `loaded` message is received.
 *
 * `authChanged`
 * : Sent whenever the authentication for the Vue app changes, such as a user
 *   fully logging in or logging out.
 *
 * `mounted`
 * : After a page has mounted within the Vue app.
 *
 * `navigate`
 * : After the route has changed within the Vue app, except that this is not
 *   sent when the route change was triggered by a `navigate` message sent by
 *   this integration (thus avoiding loops).
 *
 * `redirect`
 * : When the top-level window needs to be redirected to a URL to activate a
 *   SSO flow.
 *
 *
 * ### Outgoing Messages
 *
 * Messages sent to the Vue app include:
 *
 * `connect`
 * : After this app receives a `loaded` message from the Vue app to establish
 *   a secure communication channel and retrieve the authentication info from
 *   the Vue app. Once this message is received by the Vue app, it locks onto
 *   the URL from this app to prevent other windows from listening to its
 *   messages.
 *
 * `login`
 * : To log into the Vue app with specific `credentials` and a `role`.
 *
 * `logout`
 * : To log out of the Vue app.
 *
 * `navigate`
 * : Whenever the `Tio::EmberVue` component is used to render a Vue route within
 *   this app, or when this integration wants the Vue app to display a specific
 *   route.
 *
 * `changeRole`
 * : After the user's current role has changed in this app.
 *
 * @memberof services
 */
class VueIntegrationService extends Service {
  @service declare router: RouterService;
  @service declare session: SessionService;
  @service declare sessionContext: SessionContextService;
  @service declare rootLogger: RootLoggerService;
  @service declare pslfForm: PslfFormService;

  /**
   * A utility class used to send and receive messages with a target window.
   */
  #messenger!: WindowMessenger;

  /**
   * The state of this service. States are:
   *
   * `disabled`
   * : Configured to be disabled (e.g. no `vueAppUrl`) in the environment
   *
   * `connecting`
   * : Establishing a connection with the Vue app
   *
   * `connected`
   * : Established a connection with the Vue app, but either no authentication
   *   information has not been received yet or no user is logged in
   *
   * `authenticated`
   * : Established a connection and received authentication information from
   *   the Vue app
   *
   * `error`
   * : A critical error occurred and the integration may no longer operate
   *   correctly.
   *
   * `destroyed`
   * : This service has been destroyed and should no longer be used.
   *
   * NOTE: The state of this service can be determined by looking at the color
   *       of the down chevron menu button for the current user in the header.
   *       See `components/app/header/current-user-menu.js` for which colors map
   *       to which states. [twl 13.Sep.22]
   */
  @tracked state = 'disabled';

  /**
   * Whether a route managed by this integration is currently being loaded by
   * the Vue app.
   */
  @tracked isRouteLoading = false;

  /**
   * The last route that Vue reported mounting.
   */
  @tracked activeVueRoute = '';

  /**
   * The logger instance for this class.
   */
  logger!: ReturnType<RootLoggerService['get']>;

  /**
   * The URL of the Vue application being managed by this integration.
   *
   * @private
   */
  vueAppUrl;

  /**
   * An iframe that always exists in the DOM once this service is created. This
   * prevents the iframe from reloading the application each time a component
   * that uses this service is mounted.
   */
  iframe!: HTMLIFrameElement;

  /**
   * The Vue routes to ignore when syncing the routes between the Ember and Vue
   * apps.
   *
   * @private
   */
  ignoreRoutes;

  /**
   * Maps Vue routes to their corresponding Ember routes. If the route is the
   * same, it can be omitted from this lookup.
   *
   * @private
   */
  routeLookup;

  /**
   * The route that is currently being loaded.
   *
   * @private
   */
  // @ts-expect-error: `routeBeingLoaded` is not a property of `VueIntegrationService`
  routeBeingLoaded;

  /**
   * The role used for the last role change.
   *
   * @private
   */
  // @ts-expect-error: `lastRoleChange` is not a property of `VueIntegrationService`
  lastRoleChange;

  /**
   * Whether syncing the authentication should be skipped when Vue sends a
   * `loaded` message.
   *
   * NOTE: If we intentionally log Ember in before Vue, we want to wait until an
   *       `authChanged` message is received from Vue before syncing the
   *        authentication. Otherwise, the user will be logged out of Ember for
   *        a brief moment before being logged back in via the Vue
   *        authentication sync. [twl 20.Jul.23]
   *
   * @private
   */
  skipLoadedSyncAuthentication = false;

  /**
   * Called after an incoming message has been processed by this service with
   * the same parameters as `receiveMessage`.
   *
   * @private
   */
  afterMessageReceived: unknown;

  /**
   * Whether the Vue-Ember integration is enabled.
   */
  get enabled() {
    return !!this.vueAppUrl;
  }

  /**
   * The root element for the application this service is running within.
   *
   * @private
   */
  get rootElement() {
    // @ts-expect-error: `rootElement` is not a property of `ApplicationInstance`
    return document.querySelector(getOwner(this).rootElement);
  }

  /**
   * Construct and initialize this service.
   */
  constructor() {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments);

    this.logger = this.rootLogger.get('ember:vue-integration');
    this.logger.info('Initializing Vue integration service');
    // @ts-expect-error: owner is deprecated
    const config = getOwner(this).resolveRegistration('config:environment');
    const root = location.hostname.split('.')[0];
    // @ts-expect-error: `nonSsoSubdomains` is not a property of `VueIntegrationService`
    this.vueAppUrl = nonSsoSubdomains.includes(root)
      ? config.vueAppUrl
      : config.vueAppUrl.replace('employee-shell', `${root}.legacy`);

    this.vueAppUrl += '/ember-start';

    if (!this.enabled) {
      return;
    }

    this.state = 'connecting';
    this.ignoreRoutes = config.vueIgnoreRoutes ?? [];
    this.routeLookup = config.vueRouteLookup ?? {};

    // Disabling this lint rule, since this usage falls within the limited scope
    // of using observers to reflect state from Ember into another application
    /* eslint-disable-next-line ember/no-observers */
    this.sessionContext.addObserver('currentRole', (sessionContext) =>
      // @ts-expect-error: `sendRoleChange` is not a property of `VueIntegrationService`
      this.sendRoleChange(sessionContext.currentRole)
    );

    this.initializeIframe();

    this.#messenger = new WindowMessenger(
      // @ts-expect-error: `contentWindow` is not a property of `HTMLIFrameElement`
      this.iframe.contentWindow,
      this.vueAppUrl,
      this.receiveMessage.bind(this)
    );
    this.#messenger.logger = this.logger;

    this.logger.info('Vue integration service initialized');
  }

  /**
   * Create the iframe and load the Vue app into it.
   *
   * @private
   */
  initializeIframe() {
    this.iframe = document.createElement('iframe');
    this.iframe.src = this.vueAppUrl;
    // @ts-expect-error: `style` is not a property of `HTMLIFrameElement`
    this.iframe.style = ['position: absolute', 'display: none', 'transition: all 50ms'].join(';');

    // NOTE: Once attached to the body, we never remove it, since this would
    //       cause a reload of the iframe. Also, the iframe is being attached to
    //       the body itself and not a specific element. This is because the DOM
    //       element for an instance of an individual component is removed from
    //       the DOM when the component is removed from the component hierarchy,
    //       which forces a reload. Instead, we use CSS to absolutely position
    //       the iframe over the element in `loadRoute`. [twl 17.Jun.22]
    this.rootElement.appendChild(this.iframe);
  }

  /**
   * Loads the `route` and displays the iframe over the `element`, making it
   * appear as if the element contains the content from the route.
   *
   * @param {HTMLElement} element The element to position the loaded route over
   * @param {string} route The route in Vue to navigate to
   * @param {Object} query The query parameters to use for the route
   */
  async loadRoute(element: HTMLElement, route: string, query: Record<string, unknown>) {
    this.isRouteLoading = true;
    this.routeBeingLoaded = route;

    await this.navigateFrame(route, query);

    // Note that the iframe isn't displayed yet; that happens `receiveMessage`
    // once the Vue app tells us the page is loaded.
    this.sizeAndPositionFrame(element);
  }

  /**
   * Set the dimensions of the iframe to match the dimensions of `element` so
   * that the former perfectly overlays the latter.
   *
   * @private
   */
  sizeAndPositionFrame(element: HTMLElement) {
    const container = element.getBoundingClientRect();
    const rootContainer = this.rootElement.getBoundingClientRect();

    // Don't resize frame if the element is unmounted or with no width or height
    if (!container.width || !container.height) {
      return;
    }

    // NOTE: Offsets for `rootContainer` are used when testing. When deployed in
    //       production, the root element should be `body` and these offsets
    //       will be 0. [twl 26.Jan.23]
    this.iframe.style.top = `${container.top - rootContainer.top}px`;
    this.iframe.style.left = `${container.left - rootContainer.left}px`;
    this.iframe.style.width = `${container.width}px`;
    this.iframe.style.height = `${container.height}px`;
  }

  /**
   * Receive a message sent from the Vue App.
   *
   * @private
   */
  async receiveMessage(type: string, payload: Record<string, unknown>) {
    switch (type) {
      case 'loaded': {
        const authInfo = (await this.#messenger.send('connect')) as {
          authenticated: boolean;
          role: RoleType;
          token: string;
        };

        this.state = 'connected';

        // Let tests manually set the authentication via `login` or `logout`
        if (!this.skipLoadedSyncAuthentication && this.router.currentURL !== '/test-start') {
          await this.syncAuthentication(authInfo);
        }

        // If the Vue session times out, it sends a message to Ember to redirect
        // to `/logout`, but it then reloads and disconnects before Ember can
        // send a request to reload the entire frame via `logoutRedirect`,
        // leaving us stuck on `/logout`. Once Vue reconnects, redirect to login
        // if (this.router.currentURL === '/logout') {
        //   this.router.transitionTo('/logged-out');
        // }
        break;
      }

      case 'reloading':
        this.state = 'connecting';
        this.#messenger.isConnected = false;
        break;

      case 'authChanged':
        // @ts-expect-error: `authenticated` is not a property of `Record<string, unknown>`
        await this.syncAuthentication(payload);
        break;

      case 'mounted': {
        this.isRouteLoading = false;
        // @ts-expect-error: `route` is not a property of `Record<string, unknown>`
        this.activeVueRoute = payload?.route;
        break;
      }

      case 'navigate':
        // @ts-expect-error: `route` is not a property of `Record<string, unknown>`
        this.receiveNavigate(payload.route);
        break;

      case 'redirect':
        // @ts-expect-error: `url` is not a property of `Record<string, unknown>`
        window.location.href = payload.url;
        break;

      case 'prepareForPSLFSignature':
        // @ts-expect-error: `payload` is not a property of `Record<string, unknown>`
        this.pslfForm.prepareForPSLFSignatureFromVue(payload);
        break;

      default:
        console.warn(`Unknown message type received from Vue app: ${type}`);
    }

    if (this.afterMessageReceived) {
      // @ts-expect-error: `type` and `payload` are not properties of `Record<string, unknown>`
      this.afterMessageReceived(type, payload);
    }
  }

  /**
   * Ensures that authentication between the Ember app and Vue app are in sync,
   * either by both being authenticated with the same user or both being
   * unauthenticated.
   *
   * @private
   */
  async syncAuthentication({
    authenticated,
    token,
  }: {
    authenticated: boolean;
    role: RoleType;
    token: string;
  }) {
    this.#messenger.pauseIncoming();

    let transitionTo = undefined;

    // NOTE: We're no longer waiting for the `role` for Vue to be authenticated,
    //       since role selection is being migrated to Ember. [twl 2.Aug.23]
    const isVueAuthenticated = authenticated;

    this.state = 'connecting';
    this.skipLoadedSyncAuthentication = true;

    try {
      if (this.session.isAuthenticated && !isVueAuthenticated) {
        // await this.sessionContext.logout();
      } else if (!this.session.isAuthenticated && isVueAuthenticated) {
        if (token) {
          await this.session.authenticate('authenticator:saml', {
            access_token: token,
            noTransitionAfterAuth: true,
          });

          // Required to have the roles loaded before syncing the roles below
          await this.sessionContext.load();

          transitionTo = this.session.data.authenticated.routeAfterAuthentication;
        } else {
          console.warn('Attempt to sync authentication with no token provided');
        }
      }

      if (isVueAuthenticated && this.session.isAuthenticated) {
        // if (role) {
        //   const newRole = this.sessionContext.roles?.find(
        //     (userRole) =>
        //       role.roleType === userRole.role &&
        //       role.relationshipType === userRole.relationshipType &&
        //       role.companyId === get(userRole, 'company.legacyId')
        //   );

        //   if (newRole) {
        //     this.sessionContext.setCurrentRole(newRole);
        //     this.lastRoleChange = newRole;
        //   } else {
        //     this.logger.warn('Could not sync roles--no equivalent role', role);
        //   }
        // }

        this.state = 'authenticated';
      } else {
        this.state = 'connected';
      }

      // Ensure the transition happens after we're fully authenticated, otherwise
      // the app will execute multiple transitions
      if (transitionTo && this.sessionContext.currentRole) {
        // await this.router.transitionTo(transitionTo);
      }
    } catch (e) {
      this.logger.error('Failed to sync authentication -', e);

      // await this.router.transitionTo('auth-failed');
    }

    this.#messenger.resumeIncoming();
  }

  /**
   * Processes a `navigate` event sent from the Vue app.
   *
   * @private
   */
  receiveNavigate(route: string) {
    console.log(route);

    this.activeVueRoute = route;

    if (this.ignoreRoutes.includes(route)) {
      return;
    }

    const emberRoute = this.routeLookup[route] ?? route;

    this.router.transitionTo(emberRoute).catch((e) => {
      // @ts-expect-error: `name` is not a property of `Error`
      if (e.name !== 'UnrecognizedURLError') {
        throw e;
      }

      console.warn(
        `The route '${route}' passed from Vue is not defined in Ember.` +
          ` Add this route to 'ENV.vueIgnoreRoutes' or 'ENV.vueRouteLookup'` +
          ` in 'config/environment.js'.`
      );
    });
  }

  /**
   * Logs a user into the Vue app using the `credentials` and switches them to
   * the `role`.
   *
   * NOTE: This method should only called by the test framework, as `role` is
   *       not actually a user role, but is already formatted for the `login`
   *       message sent to Vue. [twl 18.Sep.23]
   *
   * @param {Object} credentials The credentials to use to login
   * @param {string} credentials.email The email of the user
   * @param {string} credentials.password The password of the user
   * @param {Object} role The role to log the user into
   * @param {number} role.companyId The ID of the company user has the role in
   * @param {string} role.relationshipType The type of relationship to log in as
   * @param {string} role.roleType The type of role
   */
  async login(credentials: CredentialsType, role: RoleType) {
    // NOTE: This should always trigger an `authChanged` even if the user is
    //       already logged in. This is because in Vue `authStore.startLogin`
    //       clears the token state before starting the login process. Thus, if
    //       the user is already logged in, calling this will first trigger an
    //       `authChanged` that the user is logged out, then an `authChanged`
    //       once the user is logged in again. [twl 11.Jan.23]
    await this.#messenger.send('login', {
      credentials,
      role,
    });
  }

  /**
   * Logs a user into the Vue app using an Ember `token`.
   *
   * @param {string} token The Ember token to log the user in with
   */
  async loginWithToken(token: string) {
    this.skipLoadedSyncAuthentication = true;
    // @ts-expect-error: not sure how to type.
    const { error, reasons } = await this.#messenger.send('loginWithToken', {
      token,
    });

    if (error) {
      const e = new Error(error);
      // @ts-expect-error: `reasons` is not a property of `Error`
      e.reasons = reasons;

      throw e;
    }
  }

  /**
   * Logs a user into the Vue app using the `token`, then optionally redirects
   * them to `redirect`.
   *
   * @param {string} token The Tio token to log the user in with
   * @param {string} redirect The path to redirect the user to after login
   */
  async loginWithSamlToken(token: string, redirect = '/', notification = '') {
    const vueAppUrl = new URL(this.vueAppUrl);
    const authUrl = `${vueAppUrl.origin}/auth-by-token?redirect=${redirect}&notification=${notification}#tio=${token}`;

    // HACK: Since Vue currently can't receive a URL fragment via
    //       `navigateFrame`, we directly overwrite the iframe source to the
    //       authentication endpoint. However, because we should immediately
    //       remove the token from the URL and because it would be weird to
    //       complete the rest of the login process at the `/auth-by-token` URL,
    //       once we get confirmation that the URL has been processed, we
    //       do a window redirect to `redirect`, upon which the app is reloaded
    //       and goes through the normal authentication handshake, but now with
    //       Vue authenticated.
    //
    //       Note there is no error checking to see if the token was valid. If
    //       if wasn't valid, the redirect will still happen, except the user
    //       will be redirected back to the login page. [twl 1.Mar.23]
    this.iframe.src = authUrl;
  }

  /**
   * Logs the user out of the Vue app.
   */
  async logout() {
    await this.#messenger.send('logout');
  }

  /**
   * Navigates to a new route within the Vue app, without reloading the frame.
   *
   * @param {string} route The route in Vue to navigate to
   * @param {Object} query The query parameters to use for the route
   */
  async navigateFrame(route: unknown, query: unknown) {
    if (this.session.data.authenticated.access_token) {
      await this.loginWithToken(this.session.data.authenticated.access_token);
      // @ts-expect-error: not going to figure this out
      await this.sendRoleChange(this.sessionContext.currentRole);
    }
    await this.#messenger.send('navigate', {
      route,
      // Convert `query` to a plain object, in case Ember passed a Proxy
      query: query && { ...query },
    });
  }

  /**
   * Changes the role within the Vue app.
   *
   * @private
   */
  sendRoleChange(role: RoleViewModel) {
    if (this.lastRoleChange === role) {
      return;
    }

    this.lastRoleChange = role;
    this.#messenger.send('changeRole', {
      role: {
        roleType: role.role,
        relationshipType: role.relationshipType,
        companyId: get(role, 'company.legacyId'),
      },
    });
  }

  willDestroy() {
    this.logger.info('Destroying Vue integration service');

    this.#messenger.disconnect();
    this.state = 'destroyed';

    // NOTE: When manually running acceptance tests, it can be useful to comment
    //       this out to see the state of the Vue app after a test has run.
    //       [twl 19.Jan.23]
    this.iframe.remove();
  }
}

export default VueIntegrationService;
