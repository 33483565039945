import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type EmployeeModel from 'tio-common/models/employee';
import { concat } from '@ember/helper';
import type PlanModel from 'tio-common/models/plan';
import TioExpandableSection from 'tio-common/components/tio/expandable-section';
import TioDescriptionRow from 'tio-common/components/tio/description-row';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import divide from 'ember-math-helpers/helpers/div';
import { t } from 'ember-intl';
import TioButton from 'tio-common/components/tio/button';
export interface PlanSummarySignature {
    Args: {
        employee: EmployeeModel;
        model: PlanModel;
        isOpen: boolean;
    };
    Element: HTMLElement;
}
export default class PlanSummary extends Component<PlanSummarySignature> {
    @service
    router: RouterService;
    @service
    store: typeof Store;
    @action
    goToPlans() {
        this.router.transitionTo('authenticated.admin.plans', this.args.model.company.id);
    }
    static{
        template(`
    <TioExpandableSection @isOpen={{@isOpen}} ...attributes>
      <:header>
        <h3 class="text-lg font-semibold truncate text-left">
          <span>{{t "plans.plans"}}</span>
        </h3>
      </:header>
      <:content>
        <div class="bg-white border">
          {{#if @employee.plan}}
            <TioDescriptionRow @cols="7" class="border-b" as |row|>
              <row.cell @title="Plan ID" @value={{@employee.plan.id}} />
              <row.cell @title="Plan Key" @value={{@employee.plan.companyKey}} />
              <row.cell @title="Plan Status" @value="placeholder" />
              {{! status attribute in employee model is commented out }}
              <row.cell @title="Plan Name" @value={{@employee.plan.name}} />
              {{#let
                (safeFormatDate @employee.plan.createdAt month="long" day="2-digit" year="numeric")
                as |createdAt|
              }}
                <row.cell @title="Plan Creation Date" @value={{createdAt}} />
              {{/let}}
              <row.cell @title="Plan Waiting Period" @value={{@employee.plan.waitingPeriod}} />
              <row.cell @title="Plan Type" @value="placeholder" />
              {{! there is no plan type attribute }}
            </TioDescriptionRow>
            <p class="p-2 uppercase leading-loose text-gray-500 font-semibold uppercase">
              {{t "contributions.default"}}
            </p>
            <TioDescriptionRow @cols="5" class="border-b" as |row|>
              <row.cell @title="Pay ParentPlus">
                <:value>
                  {{if @employee.plan.checkParentPlusLoans "No" "Yes"}}
                </:value>
              </row.cell>
              <row.cell @title="Min monthly payment required">
                <:value>
                  {{if @employee.plan.isMinMonthly "Yes" "No"}}
                </:value>
              </row.cell>
              <row.cell
                @title="Lifetime max contribution per employer"
                @value={{concat "\$" @employee.plan.lifetimeLimit}}
              />
              <row.cell
                @title="Annual max contribution per employee"
                @value={{concat "\$" @employee.plan.annualLimit}}
              />
              <row.cell @title="Cadence" @value={{@employee.plan.cadence}} />
            </TioDescriptionRow>
            <p class="p-2 uppercase leading-loose text-gray-500 font-semibold uppercase">
              {{t "plan_tiers"}}
            </p>
            {{#each @employee.plan.tiers as |tier|}}
              <TioDescriptionRow as |row|>
                <row.cell
                  @title={{concat (t "plans.from_cycle") " " tier.from-cycle}}
                  @value={{concat "\$" (divide tier.amount 100) " " tier.per}}
                />
              </TioDescriptionRow>
            {{/each}}
          {{else}}
            <p class="p-2 leading-loose text-gray-500">
              {{t "plans.no_plan_attached"}}
            </p>
          {{/if}}
          <hr class="border-gray-300" />
          <TioButton @onClick={{this.goToPlans}} class="mt-4" type="button" @outlined={{true}}>
            {{t "plans.view_all"}}
          </TioButton>
        </div>
      </:content>
    </TioExpandableSection>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
