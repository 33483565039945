import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import MaterialIcon from 'tio-common/components/material-icon';
import TioPageHeader from 'tio-common/components/tio/page/header';
import type EmployeeModel from 'tio-common/models/employee';
import AppPage from 'tio-employee/components/app/page';
import EmployeesCreateEmployee from 'tio-employee/components/employees/create-employee';
interface S {
    Args: {
        model: EmployeeModel;
    };
}
export default RouteTemplate<S>(template(`
    <AppPage>
      <TioPageHeader as |header|>
        <header.breadcrumbs as |b|>
          <b.crumb
            class="!relative !inline-block"
            @route="authenticated.admin.employees.index"
            @label="Employees"
          />
          <b.crumb
            class="!relative !inline-block"
            @route="authenticated.admin.employees.add"
            @label={{t "partner.employee.create_employee"}}
          />
        </header.breadcrumbs>
        <header.title>
          {{t "partner.employee.create_employee"}}
        </header.title>
      </TioPageHeader>
      <div>
        <span class="text-sm text-gray-400">
          <MaterialIcon @icon="info" class="text-sm top-5" />
          {{t "partner.employee.alert_info" htmlSafe=true}}
        </span>
      </div>
      <EmployeesCreateEmployee @model={{@model}} />
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
