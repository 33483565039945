import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import AppReadSecurityButton from 'tio-employee/components/app/read-security-button';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
interface PslfDashboardStartNewFormFaqsSig {
    Element: HTMLElement;
}
const PslfDashboardStartNewFormFaqsComponent: TOC<PslfDashboardStartNewFormFaqsSig> = template(`
  <article ...attributes>
    <div class="p-4">
      <h1 class="mb-6 text-lg font-semibold">
        {{t "pslf.start_new.questions_about_form"}}
      </h1>
      <h3 class="text-lg font-semibold text-left">{{t "pslf.start_new.is_form_new"}}</h3>
      <div class="ml-12 mb-4">
        {{t "pslf.start_new.is_form_new_answer"}}
      </div>
      <h3 class="text-lg font-semibold text-left">{{t "pslf.start_new.if_already_submitted"}}</h3>
      <div class="ml-12 mb-4">
        {{t "pslf.start_new.if_already_submitted_answer"}}
      </div>
      <h3 class="text-lg font-semibold text-left">{{t "pslf.start_new.why_submit_form"}}</h3>
      <div class="ml-12 mb-4">
        {{t "pslf.start_new.why_submit_form_answer"}}
      </div>
      <h3 class="text-lg font-semibold text-left">{{t "pslf.start_new.what_happens_on_submit"}}</h3>
      <div class="ml-12 mb-4">
        {{t "pslf.start_new.what_happens_on_submit_answer"}}
      </div>
      <h3 class="text-lg font-semibold text-left">{{t "pslf.start_new.what_happens_to_loans"}}</h3>
      <div class="ml-12 mb-4">
        {{t "pslf.start_new.what_happens_to_loans_answer"}}
      </div>
    </div>
    <AppReadSecurityButton class="my-6" />
  </article>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PslfDashboardStartNewFormFaqsComponent;
