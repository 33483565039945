import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import TuitionAssistanceFormsPreApprovalApplicationReview from 'tio-employee/components/tuition-assistance/forms/pre-approval/application-review';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import { t } from 'ember-intl';
import type TasGroupModel from 'tio-common/models/tas-group';
interface S {
    Args: {
        model: {
            programInstance: TasProgramInstanceModel;
            tasGroups: TasGroupModel[];
            activeInstances: TasProgramInstanceModel[];
        };
    };
}
export default RouteTemplate<S>(template(`
    <h3 class="font-semibold my-2 text-midnight">
      {{t "tuition_assistance.preapproval_app.application_review"}}
    </h3>

    <TuitionAssistanceFormsPreApprovalApplicationReview
      @programInstance={{@model.programInstance}}
      @tasGroups={{@model.tasGroups}}
      @activeInstances={{@model.activeInstances}}
    />
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
