import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import AppPage from 'tio-employee/components/app/page';
import TioPageHeaderTitle from 'tio-common/components/tio/page/header/title';
export default RouteTemplate(template(`
    <AppPage>
      <TioPageHeaderTitle>
        {{t "idr.default"}}
      </TioPageHeaderTitle>
      <div class="pb-16">
        {{outlet}}
      </div>
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
