import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import AppContentCard from 'tio-common/components/app/content-card';
import TioAlert from 'tio-common/components/tio/alert';
import TioButton from 'tio-common/components/tio/button';
import AppPage from 'tio-employee/components/app/page';
import AppPageArticle from 'tio-employee/components/app/page/article';
export default RouteTemplate(template(`
    <AppPage>
      <AppPageArticle>
        <TioAlert @type="info" @allowDismiss={{true}} class="mb-4">
          <:body>
            <p class="text-base">{{t "ten_twenty_cancellation.biden_announcement"}}</p>
          </:body>
        </TioAlert>
        <AppContentCard
          @title={{t "ten_twenty_cancellation.default"}}
          @titleClass="text-midnight text-xl font-bold mb-3"
          class="my-4"
        >
          <ul class="list-disc pl-4 text-base">
            <li class="mb-2">{{t "ten_twenty_cancellation.no_pell_detail"}}</li>
            <li>{{t "ten_twenty_cancellation.with_pell_detail"}}</li>
          </ul>
        </AppContentCard>
        <AppContentCard
          @title={{t "ten_twenty_cancellation.am_i_eligible"}}
          @titleClass="text-midnight text-xl font-bold mb-3"
          class="my-4"
        >
          <div class="flex flex-col md:flex-row md:grid-cols-2">
            <div class="flex flex-col md:col-span-1 mb-3">
              <div class="font-semibold mb-3">{{t
                  "ten_twenty_cancellation.borrower_eligibility"
                }}</div>
              <ul class="list-disc pl-4 text-base">
                <li>{{t "ten_twenty_cancellation.who_qualifies"}}</li>
              </ul>
            </div>
            <div class="flex flex-col md:col-span-1">
              <div class="font-semibold mb-3">{{t
                  "ten_twenty_cancellation.eligible_loan_types"
                }}</div>
              <ul class="list-disc pl-4 text-base">
                <li>{{t "ten_twenty_cancellation.only_fed_loans"}}</li>
                <li>{{t "ten_twenty_cancellation.not_held_by_ed"}}</li>
                <li>{{t "ten_twenty_cancellation.direct_consolidation"}}</li>
              </ul>
            </div>
          </div>
        </AppContentCard>
        <AppContentCard
          @title={{t "ten_twenty_cancellation.plan_details"}}
          @titleClass="text-midnight text-xl font-bold mb-3"
          class="my-4"
        >
          <div class="flex flex-col md:flex-row md:grid-cols-2">
            <div class="flex flex-col md:col-span-1 mb-3">
              <div class="font-semibold mb-3">{{t "ten_twenty_cancellation.max_forgiveness"}}</div>
              <ul class="list-disc pl-4 text-base">
                <li>{{t "ten_twenty_cancellation.no_pell"}} </li>
                <li>{{t "ten_twenty_cancellation.with_pell"}} </li>
              </ul>
            </div>
            <div class="flex flex-col md:col-span-1">
              <div class="font-semibold mb-3">{{t "ten_twenty_cancellation.tax_information"}}</div>
              <ul class="list-disc pl-4 text-base">
                <li>{{t "ten_twenty_cancellation.not_fed_maybe_state"}}</li>
              </ul>
            </div>
          </div>
        </AppContentCard>
        <AppContentCard
          @title={{t "ten_twenty_cancellation.next_steps"}}
          @titleClass="text-midnight text-xl font-bold mb-3"
          class="my-4"
        >
          <ul class="list-disc pl-4 text-base">
            <li>
              <div class="flex flex-row">
                {{t "ten_twenty_cancellation.click"}}
                <span class="text-ocean-600 font-semibold">{{t
                    "ten_twenty_cancellation.debt_relief_app_link"
                    htmlSafe=true
                  }}</span>
                {{t "ten_twenty_cancellation.to_apply"}}
              </div>
            </li>
            <li>{{t "ten_twenty_cancellation.income_app"}}</li>
            <li>{{t "ten_twenty_cancellation.time_frame"}}</li>
            <li>{{t "ten_twenty_cancellation.cancellation_app"}}</li>
            <li>{{t "ten_twenty_cancellation.cannot_cancel"}}</li>
            <li>
              {{t "ten_twenty_cancellation.dept_ed_guidance"}}
              <span class="text-ocean-600 font-semibold">
                {{t "ten_twenty_cancellation.one_time_cancellation_link" htmlSafe=true}}
              </span>
            </li>
          </ul>
          <div class="mx-5 my-10 flex justify-center">
            <TioButton
              @href="https://studentaid.gov/debt-relief/application"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{t "dashboard_tiles.click_to_apply"}}
            </TioButton>
          </div>
        </AppContentCard>
      </AppPageArticle>
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
