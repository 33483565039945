import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import AccountsTodo from './actions/accounts-todo';
import AppContentCard from 'tio-common/components/app/content-card';
import EstimatorTodo from './actions/estimator-todo';
import FormStatusTodo from '../dashboard/actions/form-status-todo';
import { Divider } from 'tio-ui/components/utilities';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import type AccountModel from 'tio-common/models/account';
import type PslfProfileModel from 'tio-common/models/pslf-profile';
import type RecommendationModel from 'tio-common/models/recommendation';
import type PersonModel from 'tio-common/models/person';
interface PslfDashboardActionsSignature {
    Args: {
        accounts: AccountModel[];
        loanSummary: LoanSummary;
        pslfProfile: PslfProfileModel;
        person: PersonModel;
        recommendation: RecommendationModel;
    };
    Element: HTMLElement;
}
const PslfDashboardActionsComponent: TOC<PslfDashboardActionsSignature> = template(`
  <AppContentCard @title={{t "pslf_dashboard.actions.default"}} ...attributes>
    <Divider class="my-2" />
    <ol>
      <FormStatusTodo @itemNo="1" @pslfProfile={{@pslfProfile}} />
      <AccountsTodo
        @accounts={{@accounts}}
        @itemNo="2"
        @person={{@person}}
        @loanSummary={{@loanSummary}}
      />
      <EstimatorTodo
        @accounts={{@accounts}}
        @itemNo="3"
        @loanSummary={{@loanSummary}}
        @person={{@person}}
        @recommendation={{@recommendation}}
      />
    </ol>
  </AppContentCard>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PslfDashboardActionsComponent;
