import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import AccountLinkingSyf from 'tio-employee/components/account-linking/syf';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import type RouterService from 'tio-employee/services/router';
import type FinancialInstitutionModel from 'tio-common/models/financial-institution';
import { t } from 'ember-intl';
import AppPage from '../../../components/app/page';
let SyfAccountLinkingRouteComponent = class SyfAccountLinkingRouteComponent extends Component {
    @service
    router: RouterService;
    @action
    didLink(institution1: FinancialInstitutionModel) {
        this.router.transitionTo('authenticated.syf.linking-confirmation', institution1.legacyId);
    }
    @action
    didCancel() {
        this.router.transitionTo('authenticated.syf');
    }
    static{
        template(`
    <AppPage @title={{t "account_linking.add_account"}}>
      <AccountLinkingSyf @onSuccess={{this.didLink}} @onCanceled={{this.didCancel}} />
    </AppPage>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(SyfAccountLinkingRouteComponent);
