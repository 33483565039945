import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import AppPage from 'tio-employee/components/app/page';
import AppPageHeroSection from 'tio-employee/components/app/page/hero-section';
import TioPageTabs from 'tio-common/components/tio/page/tabs';
import AppPageArticle from 'tio-employee/components/app/page/article';
import { t } from 'ember-intl';
export default RouteTemplate(template(`
    <AppPage>
      <AppPageHeroSection>
        <:title>
          {{t "questions_you_have.repayment_plans.what_are_repayments_q"}}
        </:title>
        <:body>
          {{t "questions_you_have.repayment_plans.what_are_repayments_a"}}
        </:body>
      </AppPageHeroSection>

      <div class="grid cols-1 sm:grid-cols-3 gap-4">
        <div class="p-10">
          <h1 class="font-semibold text-xl">
            {{t "questions_you_have.repayment_plans.most_people_standard_title"}}
          </h1>
          <p>
            {{t "questions_you_have.repayment_plans.most_people_standard_description"}}
          </p>
        </div>
        <div class="p-10">
          <h1 class="font-semibold text-xl">
            {{t "questions_you_have.repayment_plans.payment_too_high_title"}}
          </h1>
          <p>
            {{t "questions_you_have.repayment_plans.payment_too_high_description"}}
          </p>
        </div>
        <div class="p-10">
          <h1 class="font-semibold text-xl">
            {{t "questions_you_have.repayment_plans.eligibility_should_be_considered_title"}}
          </h1>
          <p>
            {{t
              "questions_you_have.repayment_plans.eligibility_should_be_considered_description"
              htmlSafe=true
            }}
          </p>
        </div>
      </div>

      <div class="pt-10 p-4">
        <h1 class="text-2xl text-midnight font-bold">
          {{t "questions_you_have.repayment_plans.repayment_plan_types"}}
        </h1>
      </div>
      <TioPageTabs as |tabs|>
        <tabs.tab
          @route="authenticated.questions-you-have.repayment-plans.index"
          @label={{t "questions_you_have.repayment_plans.standard.tab_title"}}
        />
        <tabs.tab
          @route="authenticated.questions-you-have.repayment-plans.graduated"
          @label={{t "questions_you_have.repayment_plans.graduated.tab_title"}}
        />
        <tabs.tab
          @route="authenticated.questions-you-have.repayment-plans.extended"
          @label={{t "questions_you_have.repayment_plans.extended.tab_title"}}
        />
        <tabs.tab
          @route="authenticated.questions-you-have.repayment-plans.income-driven"
          @label={{t "questions_you_have.repayment_plans.income_driven.tab_title"}}
        />
      </TioPageTabs>

      <AppPageArticle>
        {{outlet}}
      </AppPageArticle>
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
