import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import type TasApplicationModel from 'tio-common/models/tas-application';
import AppPageContent from 'tio-employee/components/app/page/content';
import TuitionAssistanceApplicationDetailsEmployeeInformation from 'tio-common/components/tuition-assistance/application-details/employee-information';
import TuitionAssistanceProgramDetailsApplicationInformation from 'tio-common/components/tuition-assistance/program-details/application-information';
import TuitionAssistanceProgramDetailsProgramInfoCard from 'tio-common/components/tuition-assistance/program-details/program-info-card';
import TuitionAssistanceApplicationDetailsApproverOverview from 'tio-common/components/tuition-assistance/application-details/approver-overview';
import TuitionAssistanceProgramDetailsAppDetailsReadonlyCourseCard from 'tio-common/components/tuition-assistance/program-details/app-details/readonly-course-card';
import TuitionAssistanceProgramDetailsAppDetailsScholarshipCard from 'tio-common/components/tuition-assistance/program-details/app-details/scholarship-card';
import TuitionAssistanceApplicationDetailsCoursesStatusApproval from 'tio-common/components/tuition-assistance/application-details/courses-status-approval';
import TuitionAssistanceProgramDetailsAppDetailsAppHistory from 'tio-common/components/tuition-assistance/program-details/app-details/app-history';
import type SessionContextService from 'tio-employee/services/session-context';
import { service } from '@ember/service';
interface RouteSignature {
    Args: {
        model: TasApplicationModel;
    };
}
let TuitionAssistanceApplicationsShowReadonlyRouteComponent = class TuitionAssistanceApplicationsShowReadonlyRouteComponent extends Component<RouteSignature> {
    @service
    sessionContext: SessionContextService;
    static{
        template(`
    <AppPageContent>
      <div class="flex gap-5">
        <div class="flex flex-col gap-5">
          <TuitionAssistanceApplicationDetailsEmployeeInformation
            @instance={{@model.tasProgramInstance}}
            @application={{@model}}
            @profileRoute="authenticated.admin.employees.show"
            @routeModel={{@model.tasProgramInstance.employee.id}}
          />
          <TuitionAssistanceProgramDetailsApplicationInformation
            @application={{@model}}
            @isAdminView={{true}}
            @canRequestRepayment={{this.sessionContext.user.isAccountOwnerOrEmployeeAdmin}}
            @isReadonlyView={{true}}
            @requestingApp="employee"
          />
          <TuitionAssistanceProgramDetailsProgramInfoCard
            @programInstance={{@model.tasProgramInstance}}
            @showAttachments={{true}}
          />
        </div>
        <div class="flex flex-col md:flex-row gap-5">
          <TuitionAssistanceApplicationDetailsApproverOverview
            @programTemplate={{@model.tasProgramInstance.tasProgramTemplate}}
          />
        </div>
      </div>
      <div class="flex flex-wrap my-8 gap-5 justify-center md:justify-start">
        {{#each @model.activeCourses as |course|}}
          <div>
            <TuitionAssistanceProgramDetailsAppDetailsReadonlyCourseCard
              @course={{course}}
              @showCustomFields={{true}}
            />
          </div>
        {{/each}}
        <div>
          <TuitionAssistanceProgramDetailsAppDetailsScholarshipCard
            @application={{@model}}
            @isEditable={{false}}
          />
        </div>
      </div>

      <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div class="grid-cols-1 lg:col-start-2">
          <TuitionAssistanceApplicationDetailsCoursesStatusApproval
            @application={{@model}}
            @programInstance={{@model.tasProgramInstance}}
            @routePrefix="authenticated"
            @isReadonlyView={{true}}
          />
        </div>
      </div>

      <TuitionAssistanceProgramDetailsAppDetailsAppHistory
        @application={{@model}}
        @viewType="admin"
      />
    </AppPageContent>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(TuitionAssistanceApplicationsShowReadonlyRouteComponent);
