import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedAdminTuitionAssistanceApplicationsShowRoute extends Route {
  @service declare store: StoreService;

  includes = [
    'tas-courses.tas-assets',
    'tas-program-instance.tas-program-template',
    'tas-program-instance.tas-program-template.conditions.public-assets',
    'tas-program-instance.employee.company.comments',
    'tas-program-instance.employee.person',
    'tas-program-instance.employee.tas-participant',
    'comments.person',
    'tas-assets',
    'tas-program-instance.tas-assets',
    'tas-assistances',
    'tags',
    'tas-program-limit',
  ];

  model(params: { tas_application_id: string }) {
    const { tas_application_id } = params;

    return this.store.findRecord('tas-application', tas_application_id, {
      include: this.includes.join(','),
      reload: true,
    });
  }
}
