import { template } from "@ember/template-compiler";
import { eq } from 'tio-ui/utilities';
import { t } from 'ember-intl';
import AppPage from 'tio-employee/components/app/page';
import RouteTemplate from 'ember-route-template';
import TioPageHeader from 'tio-common/components/tio/page/header';
import TuitionAssistanceFormsMentoring from 'tio-employee/components/tuition-assistance/forms/mentoring';
import TuitionAssistanceMentoringContactFooter from 'tio-employee/components/tuition-assistance/mentoring/contact-footer';
import type EmployeeModel from 'tio-common/models/employee';
export interface S {
    Args: {
        model: {
            employee: EmployeeModel;
            hasSupport: boolean;
        };
    };
}
export default RouteTemplate<S>(template(`
    <AppPage>
      <TioPageHeader as |header|>
        <header.title>
          {{t "sidebar.tuition_assistance"}}
        </header.title>
      </TioPageHeader>
      <p class="text-midnight text-lg font-semibold">
        {{t "tuition_assistance.mentoring.default"}}
      </p>
      <p class="text-midnight text-xl font-semibold pt-4">
        {{t "tuition_assistance.mentoring.how_can_mentoring_help"}}
      </p>
      <div class="max-w-4xl">
        <p class="pt-4">
          {{t "tuition_assistance.mentoring.mentoring_blurb"}}
        </p>
      </div>
      <p class="text-midnight text-xl font-semibold pt-6">
        {{t "tuition_assistance.mentoring.speak_with_mentor"}}
      </p>
      <p class="pt-4 pb-8">
        {{t "tuition_assistance.mentoring.answer_coach_questions"}}
      </p>
      <TuitionAssistanceFormsMentoring @employee={{@model.employee}} />
      {{#if (eq @model.hasSupport true)}}
        <TuitionAssistanceMentoringContactFooter />
      {{/if}}
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
