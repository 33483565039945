import { template } from "@ember/template-compiler";
import { array } from '@ember/helper';
import { t } from 'ember-intl';
import AppPage from 'tio-employee/components/app/page';
import AppPageContent from 'tio-employee/components/app/page/content';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import TioPageHeaderTitle from 'tio-common/components/tio/page/header/title';
import TuitionAssistanceProgramDetailsCancelRequest from 'tio-employee/components/tuition-assistance/program-details/cancel-request';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
export interface S {
    Args: {
        model: TasProgramInstanceModel;
    };
}
export default RouteTemplate<S>(template(`
    {{pageTitle (t "tuition_assistance.preapproval_app.default")}}
    <AppPage>
      <TioPageHeaderTitle>
        {{t "tuition_assistance.default"}}
      </TioPageHeaderTitle>
      <AppPageContent>
        {{outlet}}
        <TuitionAssistanceProgramDetailsCancelRequest
          @application={{@model}}
          @modelTypes={{array "tas-program-instance"}}
          @canCancel={{true}}
          @linkText={{t "tuition_assistance.program_details.cancel_app.delete_program"}}
        />
      </AppPageContent>
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
