import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AccountTable from 'tio-employee/components/accounts/account-table';
import AppPage from 'tio-employee/components/app/page';
import CardGeneric from 'tio-common/components/card/generic';
import CombinedAverageTrend from 'tio-employee/components/charts/combined-average-trend';
import Component from '@glimmer/component';
import HistoricalAccountTrend from 'tio-employee/components/charts/historical-account-trend';
import LoanSummaryChart from 'tio-employee/components/charts/loan-summary-chart';
import PrincipalAndInterestChart from 'tio-employee/components/charts/principal-and-interest-chart';
import RouteTemplate from 'ember-route-template';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import TioButton from 'tio-common/components/tio/button';
import type AccountModel from 'tio-common/models/account';
import type EmployeeService from 'tio-employee/services/employee';
import type UserModel from 'tio-common/models/user';
import AppAlertNoLinkedLoans from 'tio-employee/components/app/alert/no-linked-loans';
import { and, not } from 'tio-ui/utilities';
import FormSelect from '@frontile/forms-legacy/components/form-select';
interface RouteSignature {
    Args: {
        model: UserModel;
    };
}
let AccountActivityAccountRouteComponent = class AccountActivityAccountRouteComponent extends Component<RouteSignature> {
    @service
    employee: EmployeeService;
    @tracked
    showAddLoansModal = false;
    get user() {
        return this.args.model;
    }
    get accounts() {
        return this.user?.activeAccounts || [];
    }
    get loans() {
        return this.accounts.map((account1)=>account1.activeLoans).flat() || [];
    }
    get loansTotal() {
        const total1 = this.loans.reduce((sum1, loan1)=>{
            return (sum1 += loan1.currentBalance);
        }, 0);
        return total1.toFixed(2) as unknown as number;
    }
    get hasContributions() {
        return this.employee.hasContributions;
    }
    get accountWithContribution() {
        if (this.hasContributions) {
            // @ts-expect-error: this is an ED thing. Not typed.
            return this.accounts.findBy('publicOrdinalValue', 0);
        } else {
            return null;
        }
    }
    get showContributionSelector() {
        return this.hasContributions && this.accounts.length > 1;
    }
    @action
    toggleAddLoansModal() {
        this.showAddLoansModal = !this.showAddLoansModal;
    }
    @action
    async changeAccountWithContribution(account1: AccountModel) {
        this.accounts.forEach((a1)=>(a1.publicOrdinalValue = 1));
        account1.publicOrdinalValue = 0;
        return this.accounts.map((a1)=>a1.save());
    }
    static{
        template(`
    <AppPage @title="Student Loan Summary">
      {{#if (and (not @model.activeAccounts) @model.person.needsToAnswerWhyNoLinkedLoans)}}
        <div class="col-span-6 mb-4">
          <AppAlertNoLinkedLoans @person={{@model.person}} />
        </div>
      {{/if}}
      <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
        <CardGeneric class="h-[400px]">
          <:title>
            <span class="uppercase">
              {{t "current_balance"}}
              {{safeFormatNumber this.loansTotal style="currency" currency="USD"}}
            </span>
          </:title>
          <:content>
            <LoanSummaryChart @loans={{this.loans}} />
          </:content>
        </CardGeneric>
        <CardGeneric class="h-[400px]">
          <:title>
            <span class="uppercase">
              {{t "accounts.principal_and_interest" htmlSafe=true}}
              {{safeFormatNumber this.loansTotal style="currency" currency="USD"}}
            </span>
          </:title>
          <:content>
            <PrincipalAndInterestChart @loans={{this.loans}} />
          </:content>
        </CardGeneric>

        <CardGeneric class="h-[400px]">
          <:title>
            <span class="uppercase">{{t "accounts.historical_loan_trend"}}</span>
          </:title>
          <:content>
            <CombinedAverageTrend @loans={{this.loans}} as |axisDates|>
              <div class="-mt-4 whitespace-normal"> {{axisDates}} </div>
            </CombinedAverageTrend>
          </:content>
        </CardGeneric>
        <CardGeneric class="h-[400px]">
          <:title>
            <span class="uppercase">{{t "accounts.historical_account_trend"}}</span>
          </:title>
          <:content>
            <HistoricalAccountTrend @accounts={{this.accounts}} as |axisDates|>
              <div class="-mt-4 whitespace-normal"> {{axisDates}} </div>
            </HistoricalAccountTrend>
          </:content>
        </CardGeneric>
      </div>
      <div class="mt-20 pb-6 w-1/3">
        <TioButton @outlined={{true}} @linkTo="authenticated.slr.linking">
          {{t "slr.add_accounts"}}
        </TioButton>
        {{! TODO: where does this get saved? }}
        <br />
        {{#if this.showContributionSelector}}
          <form>
            <FormSelect
              data-legacy-input
              {{! @glint-expect-error: frontile doesn't support this yet}}
              @allowClear={{true}}
              @label="Select an account to receive contributions"
              @options={{this.accounts}}
              @selected={{this.accountWithContribution}}
              @onChange={{this.changeAccountWithContribution}}
              as |account|
            >
              {{! @glint-expect-error: frontile doesn't support this yet}}
              {{account.institutionName}}
            </FormSelect>
          </form>
        {{/if}}
      </div>
      <div class="mt-12 pb-6">
        {{#each @model.activeAccounts as |account|}}
          {{#if account.activeLoans.length}}
            <AccountTable @account={{account}} />
          {{/if}}
        {{/each}}
      </div>
    </AppPage>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AccountActivityAccountRouteComponent);
