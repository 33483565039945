import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import AppPage from 'tio-employee/components/app/page';
import AppPageContent from 'tio-employee/components/app/page/content';
import RouteTemplate from 'ember-route-template';
interface StrategyFinderDashboardRouteSignature {
    Blocks: {
        default: [];
    };
}
export default RouteTemplate<StrategyFinderDashboardRouteSignature>(template(`
    <AppPage @title={{t "common.strategy_finder.default"}}>
      <AppPageContent>
        {{outlet}}
      </AppPageContent>
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
