import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { fn } from '@ember/helper';
import { htmlSafe } from '@ember/template';
import { Modal, Footer } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { TrackedArray } from 'tracked-built-ins';
import Component from '@glimmer/component';
import embedPdf from 'tio-common/modifiers/embed-pdf';
import FormCheckbox from '@frontile/forms-legacy/components/form-checkbox';
import includes from 'ember-composable-helpers/helpers/includes';
import TioPdfModal from 'tio-common/components/tio/pdf-modal';
import type ConditionModel from 'tio-common/models/condition';
export interface TuitionAssistanceFormsAcceptConditionsSignature {
    Args: {
        conditions: readonly ConditionModel[];
        onChange: (value: ConditionModel[]) => void;
    };
}
export default class TuitionAssistanceFormsAcceptConditionsComponent extends Component<TuitionAssistanceFormsAcceptConditionsSignature> {
    // TODO: Not sure this is the correct type.
    @tracked
    conditionToView?: ConditionModel;
    acceptedTerms: ConditionModel[];
    constructor(owner1: unknown, args1: TuitionAssistanceFormsAcceptConditionsSignature['Args']){
        super(owner1, args1);
        this.acceptedTerms = new TrackedArray([]);
    }
    get isPdfCondition() {
        return this.conditionToView?.hasPdf;
    }
    @action
    didChangeAcceptanceOfTerm(condition1: ConditionModel, value1?: string) {
        if (value1) {
            // @ts-expect-error: ED needs types
            this.acceptedTerms.addObject(condition1);
        } else {
            // @ts-expect-error: ED needs types
            this.acceptedTerms.removeObject(condition1);
        }
        this.args.onChange?.([
            ...this.acceptedTerms
        ]);
    }
    @action
    close() {
        this.conditionToView = undefined;
    }
    static{
        template(`
    <div class="flex flex-col items-center justify-center my-6">
      {{#each @conditions as |condition|}}
        <div class="flex justify-between items-center text-base my-2 w-full">
          <div class="flex items-center">
            <FormCheckbox
              data-legacy-input
              @size="lg"
              @checked={{includes condition this.acceptedTerms}}
              {{! @glint-expect-error: mixing string and boolean }}
              @onChange={{fn this.didChangeAcceptanceOfTerm condition}}
              name="condition-prompt"
              @containerClass="inline mr-1"
            />
            <label for="condition-prompt" class="text-pretty">{{condition.prompt}}
            </label>
          </div>
          <button
            type="button"
            class="text-ocean-600 font-medium text-sm uppercase ml-2"
            {{on "click" (fn (mut this.conditionToView) condition)}}
          >
            {{t "view"}}
          </button>
        </div>
      {{/each}}
    </div>

    {{#if this.conditionToView}}
      {{#if this.conditionToView.hasPdf}}
        <TioPdfModal @show={{true}} @onClose={{this.close}} @closeButtonText={{t "common.close"}}>
          <div {{embedPdf this.conditionToView.pdfUrl}}></div>
        </TioPdfModal>
      {{else}}
        <Modal @isOpen={{true}} @onClose={{this.close}} class="min-h-64" @backdrop="faded" as |m|>
          <m.Header>
            <p class="font-semibold">{{this.conditionToView.prompt}}</p>
          </m.Header>
          <m.Body>
            {{htmlSafe this.conditionToView.fullText}}
          </m.Body>
          <m.Footer>
            <Footer @isRunning={{false}} @onSubmit={{this.close}} @submitText={{t "close"}} />
          </m.Footer>
        </Modal>
      {{/if}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
