import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import AppPage from 'tio-employee/components/app/page';
import TioPageTabs from 'tio-common/components/tio/page/tabs';
import AppPageArticle from 'tio-employee/components/app/page/article';
import { t } from 'ember-intl';
export default RouteTemplate(template(`
    <AppPage @title={{t "repayment_options.default"}}>
      <TioPageTabs as |tabs|>
        <tabs.tab
          @label={{t "consolidation.default"}}
          @route="authenticated.repaying-student-debt.repayment-options.index"
        />
        <tabs.tab
          @label={{t "refinancing.default"}}
          @route="authenticated.repaying-student-debt.repayment-options.refinancing"
        />
      </TioPageTabs>

      <AppPageArticle>
        {{outlet}}
      </AppPageArticle>
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
