import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import AppPage from 'tio-employee/components/app/page';
interface SlrRouteSignature {
    Blocks: {
        default: [];
    };
}
export default RouteTemplate<SlrRouteSignature>(template(`
    <AppPage>
      {{outlet}}
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
