import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import type EmployeeModel from 'tio-common/models/employee';
import AccountsLinkedAccountsCard from 'tio-employee/components/accounts/linked-accounts-card';
import AppAlertNoLinkedLoans from 'tio-employee/components/app/alert/no-linked-loans';
import RecommendationsDashRecommendations from 'tio-employee/components/recommendations/dash-recommendations';
import RecommendationsDashResults from 'tio-employee/components/recommendations/dash-results';
import RecommendationsMonthlyPaymentForgivenessCard from 'tio-employee/components/recommendations/monthly-payment-forgiveness-card';
interface S {
    Args: {
        model: EmployeeModel;
    };
}
export default RouteTemplate<S>(template(`
    <div class="pt-4 pb-8">
      <div class="grid grid-cols-6 gap-y-4 md:gap-4">
        {{#if @model.person.needsToAnswerWhyNoLinkedLoans}}
          <div class="col-span-6">
            <AppAlertNoLinkedLoans @person={{@model.person}} />
          </div>
        {{/if}}
        <div class="flex flex-col md:flex-row lg:flex-col gap-4 col-span-6 lg:col-span-2">
          <AccountsLinkedAccountsCard
            @accounts={{@model.accounts}}
            @loanSummary={{@model.loanSummary}}
            @person={{@model.person}}
            @programName={{t "dashboard_tiles.income_driven_repayment"}}
          />
          <RecommendationsDashRecommendations
            class="grow"
            @loanSummary={{@model.loanSummary}}
            @person={{@model.person}}
            @recommendation={{@model.mostRecentRecommendation}}
          />
        </div>
        {{#if @model.mostRecentRecommendation}}
          <RecommendationsDashResults
            class="lg:col-span-4 col-span-6"
            @allRecommendations={{@model.allRecommendations}}
            @accounts={{@model.accounts}}
            @loanSummary={{@model.loanSummary}}
            @person={{@model.person}}
            @programType="idr"
            @recommendation={{@model.mostRecentRecommendation}}
          />
        {{else}}
          <RecommendationsMonthlyPaymentForgivenessCard
            @accounts={{@model.accounts}}
            @loanSummary={{@model.loanSummary}}
            @person={{@model.person}}
            @programType="idr"
            @user={{@model.user}}
            class="lg:col-span-4 col-span-6"
            @isCanceling={{false}}
          />
        {{/if}}
      </div>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
