import { template } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import TioPageHeader from 'tio-common/components/tio/page/header';
interface AppPageSignature {
    Args: {
        title?: string;
    };
    Blocks: {
        default: [];
    };
    Element: HTMLDivElement;
}
const AppPageComponent: TOC<AppPageSignature> = template(`
  <div class="p-4 w-full" ...attributes>
    {{#if @title}}
      <TioPageHeader as |header|>
        <header.title>{{@title}}</header.title>
      </TioPageHeader>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppPageComponent;
