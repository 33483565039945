import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { concat, fn } from '@ember/helper';
import { eq, or } from 'tio-ui/utilities';
import { getSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { registerDestructor } from '@ember/destroyable';
import { restartableTask, timeout } from 'ember-concurrency';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { trackedFunction } from 'reactiveweb/function';
import AppPage from 'tio-employee/components/app/page';
import AppPageContent from 'tio-employee/components/app/page/content';
import Component from '@glimmer/component';
import ENV from 'tio-employee/config/environment';
import pageTitle from 'ember-page-title/helpers/page-title';
import Paginator from 'tio-common/components/tio/paginator';
import RouteTemplate from 'ember-route-template';
import SelectGeneric from 'tio-common/components/select/generic';
import TioPageTabs from 'tio-common/components/tio/page/tabs';
import TioSearchInput from 'tio-common/components/tio/search-input';
import TioTablist from 'tio-common/components/tio/tablist';
import type { Appearance } from 'tio-common/services/appearance';
import type { MultiDocumentAppearanceMap, MultiDocumentPersonAppearanceMap } from 'tio-common/services/multi-document-appearance';
import type AdminTasApplicationController from 'tio-employee/controllers/authenticated/admin/tuition-assistance/applications';
import type EnumModel from 'tio-common/models/enum';
import type IntlService from 'ember-intl/services/intl';
import type MultiDocumentAppearanceService from 'tio-common/services/multi-document-appearance';
import type PartnerService from 'tio-employee/services/partner';
import type SessionService from 'tio-employee/services/session';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TasProgramTemplateModel from 'tio-common/models/tas-program-template';
import Table from 'tio-common/components/table/index';
import WhosViewingComponent from 'tio-common/components/tio/whos-viewing';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import { LinkTo } from '@ember/routing';
const INPUT_DEBOUNCE = ENV.environment === 'test' ? 0 : 250;
interface S {
    Args: {
        controller: AdminTasApplicationController;
        model: TasApplicationModel[];
    };
}
let AdminTasApplicationsIndexRoute = class AdminTasApplicationsIndexRoute extends Component<S> {
    @service
    intl: IntlService;
    @service
    store: StoreService;
    @service
    partner: PartnerService;
    @service
    multiDocumentAppearance: MultiDocumentAppearanceService;
    @service
    session: SessionService;
    @tracked
    selectedAppStateEnum?: EnumModel;
    @tracked
    selectedProgramFilter?: TasProgramTemplateModel;
    @tracked
    currentlyBeingViewedApplications: MultiDocumentAppearanceMap = {};
    constructor(owner1: unknown, args1: S['Args']){
        super(owner1, args1);
        this.multiDocumentAppearance.connect(ENV.apiHost, ENV.apiKey, this.session.data.authenticated.access_token);
        const documentIds1 = this.args.model.map((application1)=>application1.id) as string[];
        this.loadMultiDocumentAppearanceData();
        this.setupMenus();
        registerDestructor(this, ()=>{
            this.multiDocumentAppearance.unsubscribe('tas_course_application', documentIds1);
        });
    }
    loadMultiDocumentAppearanceData() {
        const documentIds1 = this.args.model.map((application1)=>application1.id) as string[];
        if (this.multiDocumentAppearance.subscription) {
            this.multiDocumentAppearance.unsubscribe('tas_course_application', documentIds1);
        }
        this.multiDocumentAppearance.subscribe('tas_course_application', documentIds1, (message1: MultiDocumentAppearanceMap)=>{
            this.currentlyBeingViewedApplications = message1;
        });
    }
    @action
    currentlyBeingViewedApplicationsById(id1: string): Appearance[] | undefined {
        return (this.currentlyBeingViewedApplications[id1] as MultiDocumentPersonAppearanceMap[])?.flatMap((e1)=>Object.values(e1));
    }
    @action
    async setupMenus() {
        if (this.args.controller.state && !this.selectedAppStateEnum) {
            const selected1 = await this.store.query('enum', {
                filter: {
                    dot: this.args.controller.state
                }
            });
            this.selectedAppStateEnum = selected1.objectAt(0);
        }
        // Same logic as above
        if (this.args.controller.program && !this.selectedProgramFilter) {
            const selected1 = this.store.peekRecord('tasProgramTemplate', this.args.controller.program);
            this.selectedProgramFilter = selected1;
        }
    }
    stateOptionsData = trackedFunction(this, async ()=>{
        const states1 = await this.store.query('enum', {
            filter: {
                flavor: 'TAS.ApplicationState'
            }
        });
        return states1;
    });
    get stateOptions() {
        return this.stateOptionsData.value || [];
    }
    programOptionsData = trackedFunction(this, async ()=>{
        if (!this.partner?.company) {
            return [];
        }
        const programs1 = await this.store.query('tas-program-template', {
            filter: {
                company: this.partner.company?.id
            }
        });
        return programs1;
    });
    get programOptions() {
        return this.programOptionsData.value || [];
    }
    didSearch = restartableTask(async (string1)=>{
        await timeout(INPUT_DEBOUNCE);
        this.args.controller.search = string1;
    });
    @action
    setSelectedAppState(stateEnum1: EnumModel) {
        this.args.controller.state = stateEnum1?.dot || '';
        this.selectedAppStateEnum = stateEnum1;
    }
    @action
    setSelectedProgramFilter(model1: TasProgramTemplateModel) {
        this.selectedProgramFilter = model1;
        this.args.controller.program = model1?.id || '';
    }
    @action
    changePaginatorPage(page1: number) {
        this.args.controller.page = page1;
    }
    @action
    statusText(state1: string): string {
        if (this.intl.exists(`status_message.to.${state1}`)) {
            return this.intl.t(`status_message.to.${state1}`);
        }
        return this.intl.t('status_message.to.TAS.ApplicationState.UPDATED_ON');
    }
    static{
        template(`
    {{pageTitle (t "tuition_assistance.applications.default")}}
    <AppPage>
      <TioPageTabs as |tabs|>
        <tabs.tab
          @label={{t "tuition_assistance.applications.course_applications"}}
          @route="authenticated.admin.tuition-assistance.applications.index"
        />
        <tabs.tab
          @label={{t "tuition_assistance.program_instances.program_applications"}}
          @route="authenticated.admin.tuition-assistance.program-instances.index"
        />
      </TioPageTabs>

      <AppPageContent>
        <Table @stickyHeader={{true}} @isLoading={{false}}>
          <:thead as |options|>
            <tr class="bg-white">
              <th colspan="7">
                <div class="flex gap-4 text-sm">
                  <div class="basis-80">
                    {{! This superfluous label is aria-hidden because the search input has an explicit aria-label on it already }}
                    <label class="text-xs" aria-hidden={{true}}>{{t "search"}}</label>

                    <TioSearchInput
                      @placeholder="App ID, Employee ID, Email"
                      @searchString={{@controller.search}}
                      @onInput={{this.didSearch.perform}}
                      @onSubmit={{this.didSearch.perform}}
                      class="basis-96"
                    />
                  </div>

                  <SelectGeneric
                    @label={{t "tuition_assistance.applications.state_filter_label"}}
                    @selected={{this.selectedAppStateEnum}}
                    @options={{this.stateOptions}}
                    @onChange={{this.setSelectedAppState}}
                    {{! @glint-expect-error }}
                    @allowClear={{true}}
                    class="basis-80"
                  >
                    <:option as |state|>
                      {{t (concat "status_message.to." state.dot)}}
                    </:option>
                  </SelectGeneric>

                  <SelectGeneric
                    @label="Filter by program"
                    @selected={{this.selectedProgramFilter}}
                    @options={{this.programOptions}}
                    @onChange={{this.setSelectedProgramFilter}}
                    {{! @glint-expect-error }}
                    @allowClear={{true}}
                    class="basis-80"
                  >
                    <:option as |program|>
                      {{! @glint-expect-error: unify types }}
                      {{getSingleValueForTasField "PROGRAM_NAME" program.fields}}
                    </:option>
                  </SelectGeneric>
                </div>

                <TioTablist class="mb-6 border-b" as |tablist|>
                  <tablist.tab
                    @title="Approvals Waiting On Me"
                    @isSelected={{eq @controller.tab "urgent"}}
                    @onClick={{fn (mut @controller.tab) "urgent"}}
                    class="lg:min-w-72"
                  />
                  <tablist.tab
                    @title="Approvals Waiting On Others"
                    @isSelected={{eq @controller.tab "waiting"}}
                    @onClick={{fn (mut @controller.tab) "waiting"}}
                    class="lg:min-w-72"
                  />
                  <tablist.tab
                    @title="All Other Applications"
                    @isSelected={{eq @controller.tab "all"}}
                    @onClick={{fn (mut @controller.tab) "all"}}
                    class="lg:min-w-72"
                  />
                </TioTablist>
              </th>
            </tr>
            <options.tr>
              <options.th>{{t "tuition_assistance.preapproval_app.application_id"}}</options.th>
              <options.th>{{t "tuition_assistance.preapproval_app.payroll_id"}}</options.th>
              <options.th>{{t "tuition_assistance.applications.partner_name"}}</options.th>
              <options.th>{{t "tuition_assistance.applications.employee_name"}}</options.th>
              <options.th>{{t
                  "tuition_assistance.program_details.application_history.state"
                }}</options.th>
              <options.th>{{t "tuition_assistance.applications.last_action_by"}}</options.th>
              <options.th />
            </options.tr>
          </:thead>
          <:tbody as |options|>
            {{#each @model as |item|}}
              <options.tr>
                <options.td>
                  <LinkTo
                    @route="authenticated.admin.tuition-assistance.applications.show"
                    @model={{item.id}}
                    class="truncate text-ocean-600 underline"
                  >
                    {{item.id}}
                  </LinkTo>
                </options.td>
                <options.td>{{item.tasProgramInstance.employee.payrollId}}</options.td>
                <options.td>{{item.tasProgramInstance.employee.company.name}}</options.td>
                <options.td>{{item.tasProgramInstance.employee.fullName}}</options.td>
                <options.td>
                  {{this.statusText item.state}}
                </options.td>
                <options.td>
                  {{or item.latestStateChangePerson "-"}}
                  <br />
                  {{safeFormatDate
                    item.latestStateChangeDate
                    month="short"
                    day="2-digit"
                    year="numeric"
                  }}
                </options.td>
                <options.td>
                  {{#let (this.currentlyBeingViewedApplicationsById item.id) as |appearances|}}
                    {{#if appearances}}
                      <WhosViewingComponent @appearances={{appearances}} />
                    {{/if}}
                  {{/let}}
                </options.td>
              </options.tr>
            {{/each}}
          </:tbody>

          <:tfoot as |options|>
            <options.tr>
              <td colspan="7">
                <Paginator
                  {{! @glint-expect-error: ed needs types}}
                  @totalItems={{@model.meta.page.total}}
                  @pageSize={{@controller.limit}}
                  @currentPage={{@controller.page}}
                  @onChange={{this.changePaginatorPage}}
                />
              </td>
            </options.tr>
          </:tfoot>
        </Table>
      </AppPageContent>
    </AppPage>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AdminTasApplicationsIndexRoute);
