import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { t } from 'ember-intl';
import { on } from '@ember/modifier';
import { LinkTo } from '@ember/routing';
import { Select } from 'tio-ui/components/forms';
import ChevronLeft from 'ember-static-heroicons/components/outline-24/chevron-left';
import ChevronRight from 'ember-static-heroicons/components/outline-24/chevron-right';
import AppPage from 'tio-ui/components/app-page';
import Drawer from 'tio-ui/components/drawer';
import { eq, or } from 'tio-ui/utilities';
import type FinancialInstitutionModel from 'tio-common/models/financial-institution';
import type ObservabilityService from '../../../services/observability';
import type { ObservabilityUploadInstructions, ObservableSource } from '../../../services/observability';
import { getObservableProductRoute, getRecommendedDocumentType } from '../../../services/observability';
interface ObservabilityUploadRouteSignature {
    Args: {
        model: {
            source?: ObservableSource;
            financialInstitutions: FinancialInstitutionModel[];
        };
    };
    Blocks: {
        default: [];
    };
}
let ObservabilityUploadRoute = class ObservabilityUploadRoute extends Component<ObservabilityUploadRouteSignature> {
    @service
    observability: ObservabilityService;
    @tracked
    instructionsOpen: boolean = false;
    @tracked
    instructionsType?: 'nslds' | 'statement';
    @tracked
    financialInstitution?: FinancialInstitutionModel;
    get recommendedType(): 'nslds' | 'statement' {
        return getRecommendedDocumentType(this.args.model.source);
    }
    get optionalType(): 'nslds' | 'statement' {
        return this.recommendedType === 'statement' ? 'nslds' : 'statement';
    }
    @action
    openRecommendedInstructions(): void {
        this.instructionsType = this.recommendedType;
        this.instructionsOpen = true;
    }
    @action
    openOptionalInstructions(): void {
        this.instructionsType = this.optionalType;
        this.instructionsOpen = true;
    }
    @action
    closeInstructions(): void {
        this.instructionsOpen = false;
        this.instructionsType = undefined;
        this.financialInstitution = undefined;
    }
    @action
    setFinancialInstitution(name1: string): void {
        this.financialInstitution = this.args.model.financialInstitutions.find((institution1: FinancialInstitutionModel)=>institution1.name === name1);
    }
    get instructions(): ObservabilityUploadInstructions {
        if (this.instructionsType === 'nslds') {
            return this.observability.getNsldsInstructions();
        } else {
            console.log(this.financialInstitution);
            return this.observability.getStatementInstructions(this.financialInstitution?.name);
        }
    }
    static{
        template(`
    {{! template-lint-disable no-bare-strings }}
    <AppPage>
      <:title>
        <div class="flex w-full items-center">
          <LinkTo
            @route={{getObservableProductRoute @model.source}}
            class="align-bottom text-gray-900 font-medium"
          >
            <ChevronLeft class="w-5 inline" />
            Back
          </LinkTo>
        </div>
      </:title>
      <:content>
        <div class="w-full">
          <h1 class="tio-h1 bg-cross-hatch h-10">[[ page header ]]</h1>
          <p class="mb-8 bg-cross-hatch h-12">[[ subheader ]]</p>
          <h2 class="tio-h2">
            <span class="block text-fuscia-300 text-sm mb-2">
              {{t "observability.uploads.recommended_prefix"}}
            </span>
            {{#if (eq this.recommendedType "statement")}}
              {{t "observability.uploads.statement.prompt"}}
            {{else}}
              {{t "observability.uploads.nslds.prompt"}}
            {{/if}}
          </h2>
          {{!
            TODO: this seems like a richer button; consider adding CTA component
            to the UI kit - James 20241029
          }}
          <button
            {{on "click" this.openRecommendedInstructions}}
            type="button"
            class="flex w-full items-center sm:w-1/2 outline outline-1 outline-gray-200 bg-gray-50 p-4 hover:bg-gray-100 text-sm text-left text-gray-600 font-light"
          >
            <span class="block mr-4">
              {{#if (eq this.recommendedType "statement")}}
                {{t "observability.uploads.statement.cta"}}
              {{else}}
                {{t "observability.uploads.nslds.cta"}}
              {{/if}}
            </span>
            <ChevronRight class="w-6 !ml-auto flex-shrink-0" />
          </button>
          <hr class="my-6" />
          <h2 class="tio-h2">
            <span class="block text-gray-600 text-sm mb-2">
              {{t "observability.uploads.optional_prefix"}}
            </span>
            {{#if (eq this.recommendedType "statement")}}
              {{t "observability.uploads.statement.prompt"}}
            {{else}}
              {{t "observability.uploads.nslds.prompt"}}
            {{/if}}
          </h2>
          <button
            {{on "click" this.openOptionalInstructions}}
            type="button"
            class="flex w-full items-center sm:w-1/2 outline outline-1 outline-gray-200 bg-gray-50 p-4 hover:bg-gray-100 text-sm text-left text-gray-600 font-light"
          >
            <span class="block mr-4">
              {{#if (eq this.instructionsType "statement")}}
                {{t "observability.uploads.statement.cta"}}
              {{else}}
                {{t "observability.uploads.nslds.cta"}}
              {{/if}}
            </span>
            <ChevronRight class="w-6 !ml-auto flex-shrink-0" />
          </button>
          <div class="bg-cross-hatch my-12 h-64">[[ file uploader ]]</div>
        </div>
        <Drawer @isOpen={{this.instructionsOpen}} @onClose={{this.closeInstructions}} @size="lg">
          <div class="p-4">
            <h2 class="tio-h2 mb-10">{{this.instructions.title}}</h2>
            {{#if (eq this.instructionsType "statement")}}
              <label class="tio-h3">
                <span class="block font-semibold">
                  {{t "observability.uploads.financial_institution.label"}}
                </span>
                <span class="block text-sm leading-none">
                  {{t "observability.uploads.financial_institution.help"}}
                </span>
                <Select
                  @items={{@model.financialInstitutions}}
                  @allowEmpty={{true}}
                  @onAction={{this.setFinancialInstitution}}
                  @selectionMode="single"
                  class="mt-4"
                >
                  <:item as |i|>
                    <i.Item @key={{i.item.name}}>{{i.item.name}}</i.Item>
                  </:item>
                </Select>
              </label>
            {{/if}}
            {{#if (or (eq this.instructionsType "nslds") this.financialInstitution)}}
              {{#if (eq this.instructionsType "statement")}}
                <hr class="my-6" />
              {{/if}}
              <h1>{{this.instructions.header}}</h1>
              {{#if this.instructions.subheader}}
                <h2 class="text-sm">{{this.instructions.subheader}}</h2>
              {{/if}}
              <ol class="my-6 list-decimal">
                {{#each this.instructions.steps as |step|}}
                  <li class="ml-10">{{step}}</li>
                {{/each}}
              </ol>
              <p>{{this.instructions.footer}}</p>
            {{/if}}
          </div>
        </Drawer>
      </:content>
    </AppPage>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(ObservabilityUploadRoute);
