import { template } from "@ember/template-compiler";
import { array } from '@ember/helper';
import { t } from 'ember-intl';
import { service } from '@ember/service';
import AppPage from 'tio-employee/components/app/page';
import AppPageContent from 'tio-employee/components/app/page/content';
import Component from '@glimmer/component';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import TioButton from 'tio-common/components/tio/button';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import TioPageHeaderTitle from 'tio-common/components/tio/page/header/title';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import TuitionAssistanceLimitsSummary from 'tio-common/components/tuition-assistance/limits-summary';
import TuitionAssistanceProgramDetailsAppDetailsAppHistory from 'tio-common/components/tuition-assistance/program-details/app-details/app-history';
import TuitionAssistanceProgramDetailsAppDetailsCoursesAndScholarships from 'tio-common/components/tuition-assistance/program-details/app-details/courses-and-scholarships';
import TuitionAssistanceProgramDetailsApplicationInformation from 'tio-common/components/tuition-assistance/program-details/application-information';
import TuitionAssistanceProgramDetailsCancelRequest from 'tio-employee/components/tuition-assistance/program-details/cancel-request';
import TuitionAssistanceProgramDetailsProgramInfoCard from 'tio-common/components/tuition-assistance/program-details/program-info-card';
import ProgressTracker from 'tio-common/components/tio/progress-tracker';
import ClaimsFinancialsCalculationsReadonlyCard from 'tio-common/components/tuition-assistance/program-details/claims-financials/calculations-readonly-card';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type ProgressBuilder from 'tio-common/services/progress-builder';
import type { Step } from 'tio-common/components/tio/progress-tracker';
interface S {
    Args: {
        model: TasApplicationModel;
    };
}
let CourseApplicationsShowIndexComponent = class CourseApplicationsShowIndexComponent extends Component<S> {
    constructor(owner1: unknown, args1: S['Args']){
        super(owner1, args1);
        this.progressBuilder.fetchApprovers();
    }
    @service
    progressBuilder: ProgressBuilder;
    get disableSubmitForReview() {
        if (this.args.model.tasProgramInstance.tasProgramTemplate.courseGradeRequired) {
            return this.args.model.isMissingCourseGrades || this.args.model.isMissingRequiredEvidence;
        }
        return this.args.model.isMissingRequiredEvidence;
    }
    get steps() {
        return this.progressBuilder.applicationStepList(this.args.model) as Step[];
    }
    static{
        template(`
    {{pageTitle (t "tuition_assistance.program_details.default")}}

    <AppPage>
      <TioPageHeaderTitle>
        {{t "tuition_assistance.default"}}
      </TioPageHeaderTitle>
      <TioPageBreadcrumbs class="mb-4" as |b|>
        <b.crumb @route="authenticated.tuition-assistance.dashboard" @label={{t "dashboard"}} />
        <b.crumb
          @route="authenticated.tuition-assistance.programs.instance.index"
          @label={{@model.tasProgramInstance.programName}}
        />
        <b.crumb
          @route="authenticated.tuition-assistance.programs.instance.course-applications.show"
          @label={{@model.displayName}}
        />
      </TioPageBreadcrumbs>

      <AppPageContent>
        <div class="my-8">
          <ProgressTracker class="hidden md:block" @steps={{this.steps}} />
        </div>
        <div class="flex flex-wrap mb-6 mt-4 gap-6">
          <TuitionAssistanceProgramDetailsProgramInfoCard
            @programInstance={{@model.tasProgramInstance}}
            @showAttachments={{true}}
            class="w-full md:w-max"
          />
          <div class="flex flex-col flex-wrap gap-4 w-full md:w-max">
            <TuitionAssistanceLimitsSummary
              @application={{@model}}
              @employee={{@model.tasProgramInstance.employee}}
              @labelClass="col-span-4"
              @valueClass="col-span-3 text-violet-800 !text-left"
              as |Summary|
            >
              {{#unless Summary.isUnlimitedMaximumAnnualBenefit}}
                <Summary.Container
                  @title={{t "tuition_assistance.program_details.program_benefits"}}
                  @titleClass="font-semibold"
                >
                  <Summary.MaxAnnualBenefitLineItem />
                </Summary.Container>
              {{/unless}}
            </TuitionAssistanceLimitsSummary>
            <TuitionAssistanceProgramDetailsApplicationInformation
              @application={{@model}}
              @isAdminView={{false}}
              @canRequestRepayment={{false}}
              @requestingApp="employee"
            />
          </div>
          {{#if @model.tasProgramInstance.tasProgramTemplate.calculateTotalByClaimsFinancials}}
            <ClaimsFinancialsCalculationsReadonlyCard
              class="grow md:max-w-xl"
              @claimsFinancialsFields={{@model.claimsFinancialsCalculatorInput}}
            />
          {{/if}}
        </div>
        <TuitionAssistanceProgramDetailsAppDetailsCoursesAndScholarships @application={{@model}}>
          {{#if @model.canSubmitForPayment}}
            <div class="flex items-center my-4">
              <TioButton
                class="w-full max-w-72"
                @onClick={{transitionTo
                  "authenticated.tuition-assistance.programs.instance.course-applications.show.complete.review"
                  @model.id
                }}
                @disabled={{this.disableSubmitForReview}}
              >
                {{t "tuition_assistance.program_details.status.submit_for_review"}}
              </TioButton>
            </div>
          {{/if}}
        </TuitionAssistanceProgramDetailsAppDetailsCoursesAndScholarships>
        <TuitionAssistanceProgramDetailsAppDetailsAppHistory
          @application={{@model}}
          @viewType="employee"
        />
        <TuitionAssistanceProgramDetailsCancelRequest
          @application={{@model}}
          @modelTypes={{array "tas-application"}}
          @linkText={{t "tuition_assistance.program_details.cancel_app.delete_course"}}
          @canCancel={{@model.canCancelApplication}}
        />
      </AppPageContent>
    </AppPage>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(CourseApplicationsShowIndexComponent);
