import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import AppPageHeroSection from 'tio-employee/components/app/page/hero-section';
import TioPageTabs from 'tio-common/components/tio/page/tabs';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
interface QuestionsLoanForgivenessSignature {
    Args: {
        incomeRoute: string;
        indexRoute: string;
        stateRoute: string;
        teacherRoute: string;
    };
    Blocks: {
        default: [];
    };
}
const QuestionsLoanForgivenessComponent: TOC<QuestionsLoanForgivenessSignature> = template(`
  <div>
    <AppPageHeroSection>
      <:title>
        {{t "pslf.questions.what_is_loan_forgiveness_q"}}
      </:title>
      <:body>
        {{t "pslf.questions.what_is_loan_forgiveness_a"}}
      </:body>
    </AppPageHeroSection>
    <div>
      <h1 class="pl-4 pt-6 text-lg font-semibold text-gray-600">{{t
          "pslf.questions.whats_the_catch.title"
        }}</h1>
    </div>
    <div class="flex flex-col sm:grid sm:grid-cols-3">
      <p class="text-sm p-6 text-gray-600">{{t "pslf.questions.whats_the_catch.catch_1"}}</p>
      <p class="text-sm p-6 text-gray-600">{{t "pslf.questions.whats_the_catch.catch_2"}}</p>
      <p class="text-sm p-6 text-gray-600">{{t "pslf.questions.whats_the_catch.catch_3"}}</p>
    </div>
    <div class="pt-10 p-4">
      <h1 class="text-2xl text-midnight font-bold">
        {{t "pslf.questions.loan_forgiveness_programs"}}
      </h1>
    </div>
    <TioPageTabs as |tabs|>
      <tabs.tab @route={{@indexRoute}} @label="{{t 'pslf.questions.public_service.tab_title'}}" />
      <tabs.tab @route={{@incomeRoute}} @label="{{t 'pslf.questions.income_driven.tab_title'}}" />
      <tabs.tab @route={{@teacherRoute}} @label="{{t 'pslf.questions.teacher.tab_title'}}" />
      <tabs.tab @route={{@stateRoute}} @label="{{t 'pslf.questions.state_and_local.tab_title'}}" />
    </TioPageTabs>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default QuestionsLoanForgivenessComponent;
