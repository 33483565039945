import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import NewTypeTwoOrThreeApp from 'tio-employee/components/tas/applications/new/type-two-or-three';
export interface S {
    Args: {
        model: {
            application: TasApplicationModel;
            instance: TASProgramInstanceModel;
        };
    };
}
export default RouteTemplate<S>(template(`<NewTypeTwoOrThreeApp @model={{@model}} />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
