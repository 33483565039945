import Service, { service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import { htmlSafe } from '@ember/template';
import type { SafeString } from '@ember/template';

export type ObservabilityUploadInstructions = {
  title: string;
  header: string;
  subheader?: string | undefined;
  steps: SafeString[];
  footer: string;
};

const SERVICERS = ['nelnet', 'mohela', 'aidvantage', 'edfinancial', 'americaned'];
const STATEMENT_INSTRUCTIONS_TRANSLATION_BASE = 'observability.uploads.statement.instructions';
const NSLDS_INSTRUCTIONS_TRANSLATION_BASE = 'observability.uploads.nslds.instructions';

const OBSERVABLE_PRODUCT_ROUTES = {
  slr: 'authenticated.slr.dashboard',
  pslf: 'authenticated.pslf.dashboard',
  syf: 'authenticated.syf.dashboard',
};

export type ObservableSource = keyof typeof OBSERVABLE_PRODUCT_ROUTES;

export const getObservableProductRoute = (
  source?: keyof typeof OBSERVABLE_PRODUCT_ROUTES
): string => {
  if (source && source in OBSERVABLE_PRODUCT_ROUTES) {
    return OBSERVABLE_PRODUCT_ROUTES[source];
  }
  return 'authenticated.dashboard';
};

export const getRecommendedDocumentType = (source?: string): 'nslds' | 'statement' => {
  const nsldsSources = ['pslf', 'wellness'];
  return nsldsSources.includes(source as string) ? 'nslds' : 'statement';
};

const getInstitutionMatch = (institutionName = ''): string | undefined => {
  const keyMatch = SERVICERS.find((key) =>
    institutionName.toLowerCase().replace(/\s+/g, '').includes(key)
  );
  return keyMatch;
};

export default class ObservabilityService extends Service {
  @service declare intl: IntlService;

  @action
  getStatementInstructionSection(section: string, institutionName?: string): string {
    const servicer = getInstitutionMatch(institutionName);
    const translation = [STATEMENT_INSTRUCTIONS_TRANSLATION_BASE, section].join('.');
    return this.intl.t(translation, { servicer });
  }

  @action
  getNsldsInstructionSection(section: string): string {
    const translation = [NSLDS_INSTRUCTIONS_TRANSLATION_BASE, section].join('.');
    return this.intl.t(translation);
  }

  @action
  getDelimitedStatementInstructionSection(section: string, institutionName?: string): SafeString[] {
    const servicer = getInstitutionMatch(institutionName);
    const translation = [STATEMENT_INSTRUCTIONS_TRANSLATION_BASE, section].join('.');
    const options = { linkClass: 'tio-link' };
    const result = this.intl.t(translation, { servicer, ...options });
    return result.split('|').map(htmlSafe);
  }

  @action
  getDelimitedNsldsInstructionSection(section: string): SafeString[] {
    const translation = [NSLDS_INSTRUCTIONS_TRANSLATION_BASE, section].join('.');
    const result = this.intl.t(translation);
    return result.split('|').map(htmlSafe);
  }

  @action
  getStatementInstructions(institutionName?: string): ObservabilityUploadInstructions {
    return {
      title: this.intl.t(`${STATEMENT_INSTRUCTIONS_TRANSLATION_BASE}.title`),
      header: this.getStatementInstructionSection('header', institutionName),
      subheader: this.getStatementInstructionSection('subheader', institutionName),
      steps: this.getDelimitedStatementInstructionSection('steps', institutionName),
      footer: this.getStatementInstructionSection('footer', institutionName),
    };
  }

  @action
  getNsldsInstructions(): ObservabilityUploadInstructions {
    return {
      title: this.intl.t(`${NSLDS_INSTRUCTIONS_TRANSLATION_BASE}.title`),
      header: this.getNsldsInstructionSection('header'),
      steps: this.getDelimitedNsldsInstructionSection('steps'),
      footer: this.getNsldsInstructionSection('footer'),
    };
  }
}
