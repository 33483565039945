import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import AppPage from 'tio-employee/components/app/page';
import AppPageHeroSection from 'tio-employee/components/app/page/hero-section';
import AppPageArticle from 'tio-employee/components/app/page/article';
import TioButton from 'tio-common/components/tio/button';
import MaterialIcon from 'tio-common/components/material-icon';
import { Divider } from 'tio-ui/components/utilities';
import { t } from 'ember-intl';
export default RouteTemplate(template(`
    <AppPage>
      <AppPageHeroSection>
        <:title>
          {{t "planning_for_college.scholarship.hero_title"}}
        </:title>
        <:body>
          {{t "planning_for_college.scholarship.hero_detail"}}
        </:body>
      </AppPageHeroSection>
      <AppPageArticle>

        {{! Search text & button }}
        <section class="p-6">
          <p class="px-12 pb-12 text-gray-500 font-semibold text-center sm:items-center">
            {{t "planning_for_college.scholarship.learn_about" htmlSafe=true}}
          </p>
          <div class="flex justify-center">
            <TioButton
              @href="https://www.lendkey.com/scholarship-search/?sk=AFF-Tuitionio&utm_source=AFF-Tuitionio&utm_medium=referral&utm_campaign=AFF"
            >
              {{t "planning_for_college.scholarship.search_button" htmlSafe=true}}</TioButton>
          </div>
        </section>

        {{! Pros of a scholarship section }}
        <section class="text-gray-600">
          <h1 class="p-10 text-2xl text-center font-semibold">
            {{t "planning_for_college.scholarship.pros_scholarship"}}
          </h1>
          <div class="grid grid-col sm:grid-row sm:gap-12 sm:grid-cols-2">
            <div class="grid grid-col gap-4">
              <MaterialIcon @icon="thumb_up" class="flex justify-center text-3xl text-ocean-600" />
              <p class="text-center text-lg">
                {{t "planning_for_college.scholarship.pro_1"}}
              </p>
            </div>
            <div class="flex grid grid-col gap-4 pt-10 sm:pt-0">
              <MaterialIcon
                @icon="workspace_premium"
                class="flex justify-center text-3xl text-ocean-600"
              />
              <p class="text-center text-lg">
                {{t "planning_for_college.scholarship.pro_2"}}
              </p>
            </div>
          </div>
        </section>

        <Divider class="my-9" />

        {{! cons of a scholarship section }}
        <section class="p-6">
          <h1 class="text-2xl text-center text-gray-600 font-semibold">
            {{t "planning_for_college.scholarship.cons_scholarship"}}
          </h1>
          <p class="pt-4 text-center text-gray-500 text-lg">
            {{t "planning_for_college.scholarship.con_1"}}
          </p>
        </section>

        <Divider class="my-9" />

        {{! How to apply section }}
        <section class="p-6">
          <h1 class="text-2xl text-center text-gray-600 font-semibold">
            {{t "planning_for_college.scholarship.how_to_apply"}}
          </h1>
          {{! Step 1 - Search }}
          <div class="pt-10 sm:p-10 flex flex-row">
            <div
              class="justify-self-center shrink-0 w-8 h-8 bg-ocean-600 border border-ocean-600 text-white rounded-full text-center items-center leading-8"
            >
              {{t "number_one"}}
            </div>
            <span class="ml-6 text-xl text-gray-500 font-bold text-center">
              {{t "planning_for_college.scholarship.search" htmlSafe=true}}
            </span>
          </div>
          <div class="py-4 sm:px-10">
            <p class="px-12">
              {{t "planning_for_college.scholarship.learn_about" htmlSafe=true}}
            </p>
          </div>
          <Divider class="my-2" />

          {{! Step 2 - Apply }}
          <div class="pt-10 sm:p-10 flex flex-row">
            <div
              class="justify-self-center shrink-0 w-8 h-8 bg-ocean-600 border border-ocean-600 text-white rounded-full text-center items-center leading-8"
            >
              {{t "number_two"}}
            </div>
            <div class="ml-6 text-xl text-gray-500 font-bold flex items-center">
              {{t "planning_for_college.scholarship.apply" htmlSafe=true}}
            </div>
          </div>
          <div class="py-4 sm:px-10">
            <ul class="list-disc px-12">
              <li>
                <p class="">
                  {{t "planning_for_college.scholarship.apply_li_1"}}
                </p>
              </li>
              <li>
                <p class="">
                  {{t "planning_for_college.scholarship.apply_li_2"}}
                </p>
              </li>
            </ul>
          </div>
          <Divider class="my-2" />

          {{! Step 3 - Keep Up the Good Work }}
          <div class="pt-10 sm:p-10 flex flex-row">
            <div
              class="justify-self-center shrink-0 w-8 h-8 bg-ocean-600 border border-ocean-600 text-white rounded-full text-center items-center leading-8"
            >
              {{t "number_three"}}
            </div>
            <span class="ml-6 text-xl text-gray-500 font-bold text-center">
              {{t "planning_for_college.scholarship.keep_up" htmlSafe=true}}
            </span>
          </div>
          <div class="py-4 sm:px-10">
            <p class="px-12">
              {{t "planning_for_college.scholarship.keep_up_detail"}}
            </p>
          </div>
        </section>
        <Divider class="my-2" />
      </AppPageArticle>
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
