import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import AppPage from 'tio-employee/components/app/page';
import QuestionsLoanForgiveness from 'tio-employee/components/questions/loan-forgiveness';
export default RouteTemplate(template(`
    <AppPage>
      <QuestionsLoanForgiveness
        @indexRoute="authenticated.repaying-student-debt.loan-forgiveness.index"
        @incomeRoute="authenticated.repaying-student-debt.loan-forgiveness.income-driven"
        @teacherRoute="authenticated.repaying-student-debt.loan-forgiveness.teacher"
        @stateRoute="authenticated.repaying-student-debt.loan-forgiveness.state-and-local"
      >
        {{outlet}}
      </QuestionsLoanForgiveness>
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
