import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import AppPage from 'tio-employee/components/app/page';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import RecommendationsResultsRecommendationHistory from 'tio-employee/components/recommendations/results/recommendation-history';
import type RecommendationModel from 'tio-common/models/recommendation';
interface S {
    model: {
        allRecommendations: RecommendationModel[];
    };
}
export default RouteTemplate<S>(template(`
    <AppPage>
      <TioPageBreadcrumbs class="mb-4" as |b|>
        <b.crumb
          @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.index"
          @label="Dashboard"
        />
        <b.crumb
          @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.recommendation-history"
          @label="Recommendation History"
        />
      </TioPageBreadcrumbs>
      <RecommendationsResultsRecommendationHistory
        @allRecommendations={{@model.allRecommendations}}
        @programType="sf"
      />
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
