import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedObservabilityUploadRoute extends Route {
  @service declare store: StoreService;

  queryParams = { source: { refreshModel: false } };

  async model(params: { source: string }) {
    const institutionsQuery = {
      filter: {
        isActive: true,
      },
    };

    const financialInstitutions = await this.store.query(
      'financial-institution',
      institutionsQuery
    );
    return { source: params.source, financialInstitutions };
  }
}
