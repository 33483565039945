import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import type TasApplicationModel from 'tio-common/models/tas-application';
import TioButton from 'tio-common/components/tio/button';
import TuitionAssistanceProgramCard from 'tio-common/components/tuition-assistance/program-card';
import type RouterService from '@ember/routing/router-service';
import UiPill from 'tio-common/components/ui/pill';
interface S {
    Args: {
        model: TasApplicationModel;
    };
}
let AdminTasDashboardRoute = class AdminTasDashboardRoute extends Component<S> {
    @service
    router: RouterService;
    get programTemplates() {
        return this.args.model.templates;
    }
    get requestedAmount() {
        return '$862,000.00';
    }
    get paidAmount() {
        return '$107,500.00';
    }
    @action
    goToReports() {
        this.router.transitionTo('authenticated.admin.reports');
    }
    @action
    goToApplications() {
        this.router.transitionTo('authenticated.admin.tuition-assistance.applications.index');
    }
    static{
        template(`
    <div class="mx-6 py-5 pr-4">
      <h1 class="text-midnight text-lg font-semibold mb-8">
        {{t "dashboard"}}
      </h1>

      <div class="flex flex-row">
        <TioButton @onClick={{this.goToApplications}}>
          <div class="px-6">
            {{t "tuition_assistance.dashboard.go_to_applications"}}
          </div>
        </TioButton>
      </div>
      <div class="font-semibold uppercase my-8">
        {{t "tuition_assistance.programs"}}
      </div>
      <div class="flex flex-wrap gap-4 pb-16">
        {{#each this.programTemplates as |template|}}
          <TuitionAssistanceProgramCard
            @buttonText="View Program Setup"
            @buttonRoute="authenticated.admin.tuition-assistance.program-templates.show"
            @buttonRouteModel={{template.id}}
            @title={{template.programName}}
            @titleClass="text-center pt-6"
          >
            {{#if template.isTrial}}
              <div class="flex justify-center items-center">
                <UiPill @label={{t "common.trial"}} @bgColorClass="bg-orange-700" />
              </div>
            {{/if}}
          </TuitionAssistanceProgramCard>
        {{else}}
          {{t "tuition_assistance.no_programs_launched"}}
        {{/each}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AdminTasDashboardRoute);
