import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import type UserModel from 'tio-common/models/user';
import SecuritySettings from './my-profile/security-settings';
import type SessionContextService from '../../../services/session-context';
import type SessionService from '../../../services/session';
import AppPage from 'tio-employee/components/app/page';
export interface MyProfileSignature {
    Args: {
        user: UserModel;
    };
}
export default class MyProfile extends Component<MyProfileSignature> {
    @service
    session: SessionService;
    @service
    sessionContext: SessionContextService;
    @tracked
    isOpen = true;
    @tracked
    isLoading = false;
    @tracked
    error = null;
    @tracked
    hasSubmitted = false;
    get employee() {
        return this.sessionContext.currentEmployee;
    }
    get currentCompany() {
        return this.employee?.company;
    }
    get familyPlanAllowed() {
        return this.currentCompany?.companySetting?.allowFamilyPlan;
    }
    static{
        template(`
    <AppPage>
      <div class="mx-4">
        <SecuritySettings @user={{@user}} @employee={{this.employee}} />
      </div>
    </AppPage>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
