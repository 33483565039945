import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import AppPage from 'tio-employee/components/app/page';
import RouteTemplate from 'ember-route-template';
import type EmployeeModel from 'tio-common/models/employee';
import TioPageHeaderTitle from 'tio-common/components/tio/page/header/title';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import pageTitle from 'ember-page-title/helpers/page-title';
// This file isn't even used as of now
export interface S {
    Args: {
        model: {
            employee: EmployeeModel;
            hasSupport: boolean;
        };
    };
}
export default RouteTemplate<S>(template(`
    {{pageTitle (t "tuition_assistance.messages.default")}}

    <AppPage>
      <TioPageHeaderTitle>
        {{t "tuition_assistance.default"}}
      </TioPageHeaderTitle>
      <TioPageBreadcrumbs class="mb-4" as |b|>
        <b.crumb @route="authenticated.tuition-assistance.dashboard" @label={{t "dashboard"}} />
        <b.crumb
          @route="authenticated.tuition-assistance.messages"
          @label={{t "tuition_assistance.messages.default"}}
        />
      </TioPageBreadcrumbs>
      <div class="pb-16">
        {{! <TuitionAssistanceMessenger /> }}
      </div>
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
