import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { dropTask, all } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import type ConditionModel from 'tio-common/models/condition';
import type Intl from 'ember-intl/services/intl';
import { not } from 'tio-ui/utilities';
import type SessionContextService from 'tio-employee/services/session-context';
import type Store from '@ember-data/store';
import { t } from 'ember-intl';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import TioConfirmAlert from 'tio-common/components/tio/confirm-alert';
import TioErrorMessages from 'tio-common/components/tio/error-messages';
import TuitionAssistanceFormsAcceptConditions from '../forms/accept-conditions';
import type ErrorsSignature from 'tio-employee/types/errors';
export interface WithdrawCompleteSignature {
    Args: {
        programInstance: TasProgramInstanceModel;
        isHeldParticipant: boolean;
    };
    Element: HTMLDivElement;
}
export default class TuitionAssistanceProgramDetailsWithdrawCompleteComponent extends Component<WithdrawCompleteSignature> {
    @service
    sessionContext: SessionContextService;
    @service
    store: typeof Store;
    @service
    intl: Intl;
    @tracked
    hasSubmitted: boolean | false = false;
    @tracked
    didSubmitSuccessfully: boolean | false = false;
    @tracked
    submitError?: ErrorsSignature;
    @tracked
    acceptedConditions: ConditionModel[] = [];
    @tracked
    action: string | null = null;
    @tracked
    showDialog: boolean | false = false;
    @tracked
    dialogText = {
        header: '',
        body: ''
    };
    get conditionsForSubmit() {
        return (this.args.programInstance?.tasProgramTemplate?.programApproveWithdrawCompleteConditions || []);
    }
    get showConditionsForSubmit() {
        return this.conditionsForSubmit.length && !this.isHeldParticipant;
    }
    get hasAgreedAllConditions() {
        if (!this.conditionsForSubmit.length || this.isHeldParticipant) {
            return true;
        }
        return this.conditionsForSubmit.length === this.acceptedConditions.length;
    }
    get adapterMethod() {
        const actionMap1 = {
            withdraw: 'requestProgramWithdrawal',
            complete: 'requestProgramCompletion'
        };
        return this.action ? actionMap1[this.action as keyof typeof actionMap] : '';
    }
    get markProgramCompleteDialogText() {
        return this.isHeldParticipant ? {
            header: this.intl.t('tuition_assistance.program_details.mark_program_complete.held_participant.header'),
            body: this.intl.t('tuition_assistance.program_details.mark_program_complete.held_participant.body')
        } : {
            header: this.intl.t('tuition_assistance.program_details.mark_program_complete.header'),
            body: this.intl.t('tuition_assistance.program_details.mark_program_complete.body')
        };
    }
    get withdrawProgramDialogText() {
        return this.isHeldParticipant ? {
            header: this.intl.t('tuition_assistance.program_details.withdraw_program.held_participant.header'),
            body: this.intl.t('tuition_assistance.program_details.withdraw_program.held_participant.body')
        } : {
            header: this.intl.t('tuition_assistance.program_details.withdraw_program.header'),
            body: this.intl.t('tuition_assistance.program_details.withdraw_program.body')
        };
    }
    get isHeldParticipant() {
        return this.args.isHeldParticipant;
    }
    @action
    async markProgramComplete() {
        if (!this.isHeldParticipant) {
            await this.submitTask.perform();
        }
        this.closeDialog();
    }
    @action
    async withdrawProgram() {
        if (!this.isHeldParticipant) {
            await this.submitTask.perform();
        }
        this.closeDialog();
    }
    @action
    didUpdateAcceptedConditions(conditions1: ConditionModel[] = []) {
        this.acceptedConditions = conditions1;
    }
    @action
    handleCompleteWithdrawAction() {
        switch(this.action){
            case 'complete':
                this.markProgramComplete();
                break;
            case 'withdraw':
                this.withdrawProgram();
                break;
            default:
                break;
        }
    }
    submitTask = dropTask(async ()=>{
        if (!this.hasAgreedAllConditions) {
            return;
        }
        try {
            const method1 = this.adapterMethod;
            this.hasSubmitted = true;
            delete this.submitError;
            await this.saveAgreementsForConditions.perform();
            await this.store.adapterFor('tas-program-instance')[method1](this.args.programInstance);
            this.didSubmitSuccessfully = true;
        } catch (e1) {
            console.error(e1);
            this.submitError = e1;
        }
    });
    saveAgreementsForConditions = dropTask(async ()=>{
        const user1 = this.sessionContext.user;
        const agreements1 = this.acceptedConditions.map((condition1)=>{
            return this.store.createRecord('agreement', {
                user: user1,
                condition: condition1
            });
        });
        const promises1 = agreements1.map((agreement1)=>{
            return agreement1.save();
        });
        const saved1 = await all(promises1);
        return saved1;
    });
    @action
    closeDialog() {
        this.showDialog = false;
        this.action = null;
        this.submitError = undefined;
        this.hasSubmitted = false;
        this.acceptedConditions = [];
    }
    @action
    openDialog(action1: string) {
        this.action = action1;
        this.dialogText = action1 === 'complete' ? this.markProgramCompleteDialogText : this.withdrawProgramDialogText;
        this.showDialog = true;
    }
    static{
        template(`
    <div ...attributes>
      <div class="flex justify-center">
        {{#if @programInstance.canMarkComplete}}
          <button
            type="button"
            class="tio-copy px-8 py-2"
            {{on "click" (fn this.openDialog "complete")}}
          >
            {{t "tuition_assistance.program_details.mark_program_complete.default"}}
          </button>
        {{/if}}
        {{#if @programInstance.canWithdraw}}
          <button
            type="button"
            class="tio-copy px-8 py-2"
            {{on "click" (fn this.openDialog "withdraw")}}
          >
            {{t "tuition_assistance.program_details.withdraw_program.default"}}
          </button>
        {{/if}}
      </div>
    </div>

    <TioConfirmAlert
      @show={{this.showDialog}}
      @onCancel={{this.closeDialog}}
      @onConfirm={{this.handleCompleteWithdrawAction}}
      @confirmButtonText={{if this.isHeldParticipant "Close" "Confirm"}}
      @hideCancel={{if this.isHeldParticipant true false}}
      @disabled={{not this.hasAgreedAllConditions}}
    >
      <div class="mx-14">
        <h3 class="text-center mb-6 font-semibold text-tio-gray-700 text-lg md:text-xl">
          {{this.dialogText.header}}
        </h3>
        <p>
          {{this.dialogText.body}}
        </p>
        {{#if this.showConditionsForSubmit}}
          <TuitionAssistanceFormsAcceptConditions
            @conditions={{this.conditionsForSubmit}}
            @onChange={{this.didUpdateAcceptedConditions}}
          />
        {{/if}}
        <TioErrorMessages
          @showErrors={{this.hasSubmitted}}
          @error={{this.submitError}}
          class="text-center"
        />
      </div>
    </TioConfirmAlert>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
