//import { concat } from '@ember/helper';
import { template } from "@ember/template-compiler";
import { action, get } from '@ember/object';
import { currentEmployerApproval } from 'tio-common/validation-schema/forms/pslf-approver';
import { dropTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { not } from 'tio-ui/utilities';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AppPage from 'tio-employee/components/app/page';
import AppPageContent from 'tio-employee/components/app/page/content';
import Component from '@glimmer/component';
import DefinitionList from 'tio-common/components/ui/definition-list';
import errorsForField from 'tio-common/helpers/errors-for-field';
import FormCheckbox from '@frontile/forms-legacy/components/form-checkbox';
import FormInput from '@frontile/forms-legacy/components/form-input';
import FormRadioGroup from '@frontile/forms-legacy/components/form-radio-group';
import MaterialIcon from 'tio-common/components/material-icon';
import RouteTemplate from 'ember-route-template';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import TioAlert from 'tio-common/components/tio/alert';
import TioButton from 'tio-common/components/tio/button';
import { Divider } from 'tio-ui/components/utilities';
import { Footer, Modal } from 'tio-ui/components/modal';
import tippy from 'ember-tippy/modifiers/tippy';
import type { IntlService } from 'ember-intl';
import type PslfFormModel from 'tio-common/models/pslf-form';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
export interface AdminPslfFormReviewSignature {
    Args: {
        model: PslfFormModel;
    };
    Element: HTMLElement;
}
let AdminPslfFormReviewRoute = class AdminPslfFormReviewRoute extends Component<AdminPslfFormReviewSignature> {
    @service
    store: typeof Store;
    @service
    intl: IntlService;
    @service
    router: RouterService;
    @tracked
    employmentStartDate: string = '';
    @tracked
    employmentEndDate: string = '';
    @tracked
    currentEmployee: boolean = true;
    @tracked
    weeklyHours: number = 0;
    @tracked
    employmentStatus: string = '';
    @tracked
    employerInfoCorrect: boolean = true;
    @tracked
    loading: boolean = false;
    @tracked
    showConfirmationModal: boolean = false;
    @tracked
    hasSubmitted: boolean = false;
    constructor(owner1: unknown, args1: AdminPslfFormReviewSignature['Args']){
        super(owner1, args1);
        this.assignBorrowerResponses();
    }
    assignBorrowerResponses() {
        this.weeklyHours = parseInt(get(this.args.model.employerFields, 'employment-hours') || '0');
        this.employmentStartDate = get(this.args.model.employerFields, 'employment-start-date') || '';
        this.employmentEndDate = get(this.args.model.employerFields, 'employment-end-date') || '';
    }
    get borrowerFields() {
        return this.args.model.borrowerFields;
    }
    get employerFields() {
        return this.args.model.employerFields;
    }
    get formEmployeeName() {
        return this.borrowerFields['borrower-name'];
    }
    get formModel() {
        return {
            employmentStartDate: this.employmentStartDate,
            employmentEndDate: this.employmentEndDate,
            weeklyHours: this.weeklyHours,
            currentEmployee: this.currentEmployee,
            employmentStatus: this.employmentStatus,
            employerInfoCorrect: this.employerInfoCorrect
        };
    }
    get employmentStatusText() {
        return this.employmentStatus === 'fullTime' ? this.intl.t('pslf.workflow.full_time_employee') : this.intl.t('pslf.workflow.part_time_employee');
    }
    get currentEmployeeText() {
        return this.currentEmployee ? this.intl.t('pslf.employer_workflow.current_employee') : this.intl.t('pslf.employer_workflow.prior_employee');
    }
    get formValidationErrors() {
        const schema1 = currentEmployerApproval;
        try {
            schema1?.validateSync?.(this.formModel, {
                abortEarly: false,
                context: {}
            });
        } catch (e1) {
            return e1.inner || [];
        }
        return [];
    }
    @action
    blurWeeklyHoursInput() {
        let element1 = document.getElementById('weekly-hours');
        if (element1) {
            element1.blur();
        }
    }
    @action
    toggleConfirmationModal() {
        this.showConfirmationModal = !this.showConfirmationModal;
    }
    @action
    preSubmit() {
        this.hasSubmitted = true;
        if (this.formValidationErrors.length) {
            console.log('validation errors', this.formValidationErrors);
            this.loading = false;
            return;
        }
        this.toggleConfirmationModal();
    }
    @action
    async submit() {
        this.hasSubmitted = true;
        this.submitTask.perform();
    }
    submitTask = dropTask(async ()=>{
        this.loading = true;
        const outgoingForm1 = this.args.model;
        outgoingForm1.employerFields = {
            ...this.args.model.employerFields,
            'employment-start-date': this.employmentStartDate,
            'employment-end-date': this.employmentEndDate,
            'employment-continues': this.currentEmployee,
            'employment-hours': this.weeklyHours.toString(),
            'employment-status': this.employmentStatus
        };
        this.router.transitionTo('authenticated.admin.pslf.form.sign', outgoingForm1.id, {
            queryParams: {
                signerType: 'employer'
            }
        });
        this.loading = false;
        return;
    });
    static{
        template(`
    <AppPage @title={{t "pslf.employer_workflow.partner_approver_page_header"}}>
      <AppPageContent>
        <form ...attributes {{on "wheel" this.blurWeeklyHoursInput}}>
          {{! EMPLOYER INFO TO VERIFY  }}
          <p class="mt-6 mb-4 text-lg font-semibold">{{t
              "pslf.employer_workflow.review_employer_info"
              name=this.formEmployeeName
            }}</p>
          <div class="flex flex-row">
            <p class="font-semibold mr-2">{{t "pslf.workflow.employer_name"}}:</p>
            <p>{{get this.employerFields "employer-name"}}</p>
          </div>
          <div class="flex flex-row">
            <p class="font-semibold mr-2">{{t "pslf.employer_workflow.fein_header"}}:</p>
            <p>{{get this.employerFields "employer-fein"}}</p>
          </div>
          <div class="flex flex-row">
            <p class="font-semibold mr-2">{{t "accounts.address"}}:</p>
            <p>{{@model.fullEmployerAddress}}</p>
          </div>
          {{#if (get this.employerFields "employer-website")}}
            <div class="flex flex-row">
              <p class="font-semibold mr-2">{{t "pslf.workflow.employer_website"}}:</p>
              <p>{{get this.employerFields "employer-website"}}</p>
            </div>
          {{/if}}
          <FormRadioGroup
            data-legacy-input
            @label={{t "pslf.workflow.is_employer_info_correct"}}
            @value={{this.employerInfoCorrect}}
            @onChange={{fn (mut this.employerInfoCorrect) (not this.employerInfoCorrect)}}
            @containerClass="py-4"
            @errors={{errorsForField "employerInfoCorrect" schemaErrors=this.formValidationErrors}}
            @hasSubmitted={{this.hasSubmitted}}
            as |Radio|
          >
            {{! @glint-expect-error: children of form groups }}
            <Radio @value={{true}} @label={{t "common.yes"}} />
            {{! @glint-expect-error: children of form groups }}
            <Radio @value={{false}} @label={{t "common.no"}} />
          </FormRadioGroup>

          {{#if this.employerInfoCorrect}}
            <Divider class="my-9" />
            <p class="text-lg font-semibold">
              {{t "common.pslf.employment_dates"}}</p>
            <FormCheckbox
              data-legacy-input
              @label={{t "common.pslf.forms.still_employed"}}
              @checked={{this.currentEmployee}}
              @onChange={{fn (mut this.currentEmployee) (not this.currentEmployee)}}
              @containerClass="py-4"
            />

            <FormInput
              data-legacy-input
              type="date"
              @label={{t "pslf.workflow.start_date"}}
              @value={{this.employmentStartDate}}
              @onInput={{fn (mut this.employmentStartDate)}}
              @errors={{errorsForField
                "employmentStartDate"
                schemaErrors=this.formValidationErrors
              }}
              @hasSubmitted={{this.hasSubmitted}}
              @containerClass="py-4"
            />
            {{#unless this.currentEmployee}}
              <FormInput
                data-legacy-input
                type="date"
                @label={{t "pslf.workflow.end_date"}}
                @value={{this.employmentEndDate}}
                @onInput={{fn (mut this.employmentEndDate)}}
                @errors={{errorsForField
                  "employmentEndDate"
                  schemaErrors=this.formValidationErrors
                }}
                @hasSubmitted={{this.hasSubmitted}}
              />
            {{/unless}}

            <p class="text-lg font-semibold pt-4 pb-2">
              {{t "pslf.employer_workflow.average_hours_per_week"}}
            </p>
            <p>{{t "pslf.employer_workflow.hours_sub" htmlSafe=true}}</p>
            <FormInput
              data-legacy-input
              @hint={{t "pslf.employer_workflow.average_hours_per_week"}}
              type="number"
              {{! @glint-expect-error: wants a string}}
              @value={{this.weeklyHours}}
              {{! @glint-expect-error: wants a string}}
              @onInput={{fn (mut this.weeklyHours)}}
              @errors={{errorsForField "weeklyHours" schemaErrors=this.formValidationErrors}}
              @hasSubmitted={{this.hasSubmitted}}
              @containerClass="py-4"
              id="weekly-hours"
            />
            <div class="flex flex-row items-center">
              <p class="text-lg font-semibold">{{t
                  "pslf.employer_workflow.employment_status_current"
                  name=this.formEmployeeName
                }}</p>
              <MaterialIcon
                @icon="info"
                class="mx-2 text-lg leading-5 align-bottom cursor-default text-ocean-600"
                {{tippy (t "pslf.employer_workflow.full_time_means")}}
              />
            </div>
            <FormRadioGroup
              data-legacy-input
              @value={{this.employmentStatus}}
              {{! @glint-expect-error: onChange wants a second param of the event as well as the value }}
              @onChange={{fn (mut this.employmentStatus)}}
              @errors={{errorsForField "employmentStatus" schemaErrors=this.formValidationErrors}}
              @hasSubmitted={{this.hasSubmitted}}
              @containerClass="py-4"
              as |Radio|
            >{{! @glint-expect-error: children of form groups }}
              <Radio @value="fullTime" @label={{t "common.full_time"}} />
              {{! @glint-expect-error: children of form groups }}
              <Radio @value="partTime" @label={{t "common.part_time"}} />
            </FormRadioGroup>

            <TioButton
              class="w-52 mx-auto visited:text-white my-4"
              @onClick={{this.preSubmit}}
              @loading={{this.loading}}
              @disabled={{not this.employerInfoCorrect}}
            >{{t "common.submit"}}</TioButton>

            <Modal
              @isOpen={{this.showConfirmationModal}}
              @onClose={{this.toggleConfirmationModal}}
              class="min-h-64 !max-w-screen-sm"
              @backdrop="faded"
              as |m|
            >
              <m.Header class="text-midnight font-semibold ml-4 mt-4">
                {{t "pslf.employer_workflow.is_info_correct"}}</m.Header>
              <m.Body>
                <div class="mb-4 flex flex-col justify-center mx-4">
                  <p>{{t "pslf.employer_workflow.please_review"}}</p>
                  <TioAlert @type="warning" @allowDismiss={{false}} class="my-4">
                    <:body>
                      <p class="text-sm">
                        {{t "pslf.employer_workflow.warning" htmlSafe=true}}
                      </p>
                    </:body>
                  </TioAlert>
                  <DefinitionList class="px-0" as |options|>
                    <div class={{options.wrappingClass}}>
                      <options.dt class="font-semibold mr-2">{{t
                          "partner.employee.employee_status"
                        }}:</options.dt>
                      <options.dd>{{this.currentEmployeeText}}</options.dd>
                    </div>
                    <div class={{options.wrappingClass}}>
                      <options.dt class="font-semibold mr-2">{{t
                          "pslf.workflow.start_date"
                        }}:</options.dt>
                      <options.dd>{{safeFormatDate
                          this.employmentStartDate
                          month="long"
                          day="2-digit"
                          year="numeric"
                          timeZone="UTC"
                        }}</options.dd>
                    </div>
                    <div class={{options.wrappingClass}}>
                      <options.dt class="font-semibold mr-2">{{t
                          "pslf.employer_workflow.average_hours_per_week"
                        }}:</options.dt>
                      <options.dd>{{this.weeklyHours}}</options.dd>
                    </div>
                    <div class={{options.wrappingClass}}>
                      <options.dt class="font-semibold mr-2">{{t
                          "pslf.employer_workflow.employment_type"
                        }}:</options.dt>
                      <options.dd>{{this.employmentStatusText}}</options.dd>
                    </div>
                  </DefinitionList>
                </div>
              </m.Body>
              <m.Footer>
                <Footer
                  @onCancel={{this.toggleConfirmationModal}}
                  @onSubmit={{this.submit}}
                  @isRunning={{false}}
                  @submitText={{t "pslf.employer_workflow.proceed_with_info"}}
                  @cancelText={{t "pslf.employer_workflow.edit_info"}}
                />
              </m.Footer>
            </Modal>
          {{else}}
            <div class="p-4 my-4 rounded border bg-gray-200 border-gray-200 w-1/2 flex flex-row">
              <MaterialIcon @icon="info" class="m-3" />
              <p>{{t "pslf.employer_workflow.if_incorrect" email="support@tuition.io"}}</p>
            </div>
          {{/if}}
        </form>
      </AppPageContent>
    </AppPage>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AdminPslfFormReviewRoute);
