import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import type PlanModel from 'tio-common/models/plan';
import AppPage from 'tio-employee/components/app/page';
import TioPageHeader from 'tio-common/components/tio/page/header';
import PlanWellnessCard from 'tio-employee/components/plan/wellness-card';
import PlanContributionCard from 'tio-employee/components/plan/contribution-card';
import { t } from 'ember-intl';
import { eq } from 'tio-ui/utilities';
interface S {
    Args: {
        model: PlanModel;
    };
}
export default RouteTemplate<S>(template(`
    <AppPage>
      <TioPageHeader as |header|>
        <header.breadcrumbs as |b|>
          <b.crumb @route="authenticated.admin.employees.index" @label="Employees" />
          <b.crumb
            @route="authenticated.admin.plans"
            @label={{t "plans.plans"}}
            @model={{@model.id}}
          />
        </header.breadcrumbs>
        <header.title>{{t "plans.plans"}}</header.title>
      </TioPageHeader>
      <h1>
        {{t "plans.wellness_plan"}}
      </h1>
      {{#each @model.plans as |plan|}}
        {{#if (eq plan.cadence "NEVER")}}
          <PlanWellnessCard @plan={{plan}} />
        {{/if}}
      {{/each}}

      <h1>
        {{t "plans.contribution_plans"}}
      </h1>
      {{#each @model.plans as |plan|}}
        {{#unless (eq plan.cadence "NEVER")}}
          <PlanContributionCard @plan={{plan}} />
        {{/unless}}
      {{/each}}
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
