import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';

export default class AuthenticatedTasApplicationsShowRoute extends Route {
  @service declare router: RouterService;
  @service declare store: StoreService;
  @service declare tuitionAssistance: TuitionAssistanceService;

  includes = [
    'tas-courses',
    'tas-courses.tas-assets',
    'tas-program-instance',
    'tas-program-instance.tas-program-template',
    'tas-program-instance.tas-program-template.conditions.public-assets',
    'tas-program-instance.tas-program-template.tas-field-options',
    'tas-program-instance.employee.person',
    'tas-program-instance.employee.tas-participant',
    'tas-assets',
    'tas-assistances',
    'tags',
  ];

  queryParams = {
    applicationSubmitted: { refreshModel: true },
  };

  async model({ tas_application_id }: { tas_application_id: string }) {
    const application = await this.store.findRecord('tas-application', tas_application_id, {
      include: this.includes.join(','),
      reload: true,
    });

    await this.tuitionAssistance.loadEligibilitiesForCurrentEmployee();

    await application.loadHistory();

    return application;
  }

  afterModel(model: TasApplicationModel) {
    // TAS.TODO: This is an init setup, but will need to be modified for all types
    const type = model.tasProgramInstance.tasProgramTemplate.typeClassification;
    // If in ATTEND, it means that the app has not been submitted
    if (type === 'TAS.ProgramType.2' && model.state === 'TAS.ApplicationState.ATTEND') {
      this.router.transitionTo('authenticated.tas.applications.type-two.new', model.id);
    } else if (
      type === 'TAS.ProgramType.3' &&
      model.state === 'TAS.ApplicationState.DEFINE_COURSES'
    ) {
      this.router.transitionTo('authenticated.tas.applications.type-three.new', model.id);
    }
  }
}
