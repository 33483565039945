import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import AppPage from 'tio-employee/components/app/page';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import TioPageHeaderTitle from 'tio-common/components/tio/page/header/title';
import AddDependent from 'tio-employee/components/tuition-assistance/forms/add-dependents';
import TuitionAssistanceDashboardApplyToPrograms from 'tio-employee/components/tuition-assistance/dashboard/apply-to-programs';
import TuitionAssistanceDashboardMyPrograms from 'tio-employee/components/tuition-assistance/dashboard/my-programs';
import TuitionAssistanceDependentTable from 'tio-employee/components/tuition-assistance/dashboard/dependent-table';
import TioHeader from 'tio-common/components/tio/page/hero/index';
import type EmployeeModel from 'tio-common/models/employee';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';
import TuitionAssistanceDashboardManageDependents from 'tio-employee/components/tuition-assistance/dashboard/manage-dependents';
export interface DependentProgramDashboardSignature {
    Args: {
        model: {
            hasDependents: boolean;
            employee: EmployeeModel;
            activeInstances: TasProgramInstanceModel[];
            templates: TasProgramTemplate[];
            pastInstances: TasProgramInstanceModel[];
        };
    };
}
export default RouteTemplate<DependentProgramDashboardSignature>(template(`
    {{pageTitle (t "dashboard")}}
    <AppPage>
      <TioPageHeaderTitle>
        {{t "tuition_assistance.default"}}
      </TioPageHeaderTitle>
      <h1 class="font-bold uppercase my-6">{{t "common.tuition_assistance.dependent_programs"}}</h1>
      {{#if @model.employee.hasDependents}}
        <div class="w-2/3">
          <h3 class="text-lg font-semibold my-2">{{t
              "common.tuition_assistance.dependent_table.table_title"
            }}</h3>
          <TuitionAssistanceDependentTable @employee={{@model.employee}} />
        </div>
        <div class="flex -ml-2 mt-4">
          <AddDependent @employee={{@model.employee}} />
        </div>
      {{else}}
        <div class="flex">
          <div class="w-1/2">
            <TioHeader
              @headerBeginning="Apply for"
              @highlightText="Tuition Assistance"
              @headerEnd="Apply for Dependent Tuition Assistance to further your dependents’ training and education."
              @description="Take control of your professional development, expand your opportunities, and lead a fulfilling and meaningful life through continued education. Your dependents are also eligible!"
            />
          </div>
          <div class="flex w-1/2 justify-center">
            <TuitionAssistanceDashboardManageDependents @employee={{@model.employee}} />
          </div>
        </div>
      {{/if}}
      <TuitionAssistanceDashboardMyPrograms
        @programStatus="active"
        @programInstances={{@model.activeInstances}}
        @sectionHeader={{t "tuition_assistance.dashboard.active_programs"}}
        class="mb-12"
      />
      <TuitionAssistanceDashboardApplyToPrograms
        @programTemplates={{@model.templates}}
        @programInstances={{@model.activeInstances}}
        class="mb-12"
      />
      {{#if @model.pastInstances.length}}
        <TuitionAssistanceDashboardMyPrograms
          @programStatus="past"
          @programInstances={{@model.pastInstances}}
          @sectionHeader={{t "tuition_assistance.dashboard.past_programs"}}
          class="mb-12"
        />
      {{/if}}
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
