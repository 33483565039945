import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { Divider } from 'tio-ui/components/utilities';
import { dropTask } from 'ember-concurrency';
import { formatAsDollars, toLocaleDateString } from 'tio-common/utils/format';
import { getAllValuesForTasField, getSingleValueForTasField, setSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { Footer, Modal } from 'tio-ui/components/modal';
import { not, or } from 'tio-ui/utilities';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tasStateLabel, tasStateIntent } from 'tio-common/utils/tuition-assistance/state';
import { tracked } from '@glimmer/tracking';
import { fn } from '@ember/helper';
import AcceptConditions from 'tio-employee/components/tuition-assistance/forms/accept-conditions';
import ActionableList from 'tio-ui/components/actionable-list';
import Alert from 'tio-employee/components/tas/applications/alert';
import ApplicationProcess from 'tio-employee/components/tuition-assistance/application/application-process';
import AppPage from 'tio-ui/components/app-page';
import AttachmentList from 'tio-common/components/tuition-assistance/forms/upload-attachments/attachment-list';
import Badge from 'tio-ui/components/badge';
import CardGeneric from 'tio-ui/components/card-generic';
import CheckCircle from 'ember-static-heroicons/components/outline-24/check-circle';
import CheckCircleSolid from 'ember-static-heroicons/components/solid-24/check-circle';
import ClipboardDocumentList from 'ember-static-heroicons/components/outline-24/clipboard-document-list';
import Component from '@glimmer/component';
import CourseSubmitted from 'tio-employee/components/tas/applications/course-submitted';
import DescriptionList from 'tio-ui/components/description-list';
import divide from 'ember-math-helpers/helpers/div';
import NavTabs from 'tio-ui/components/nav-tabs';
import PaymentDetailsCard from 'tio-common/components/tas/payment-details-card';
import RouteTemplate from 'ember-route-template';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import Table from 'tio-common/components/table/index';
import TasApplicationsShowController from 'tio-employee/controllers/authenticated/tas/applications/show';
import TasPageHeader from 'tio-employee/components/tas/page-header';
import TioButton from 'tio-common/components/tio/button';
import TioErrorMessages from 'tio-common/components/tio/error-messages';
import UploadAttachments from 'tio-common/components/tuition-assistance/forms/upload-attachments';
import TuitionAssistanceFormsCoursesGradeSelectOptions from 'tio-common/components/tuition-assistance/forms/courses/grade-select-options';
import PencilSquare from 'ember-static-heroicons/components/outline-24/pencil-square';
import type ConditionModel from 'tio-common/models/condition';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';
import TASCourseModel from 'tio-common/models/tas-course';
interface RouteSignature {
    Args: {
        model: TasApplicationModel;
        controller: TasApplicationsShowController;
    };
}
let AuthenticatedTasApplicationsShowComponent = class AuthenticatedTasApplicationsShowComponent extends Component<RouteSignature> {
    @tracked
    acceptedConditions: ConditionModel[] = [];
    @tracked
    submitError = null;
    @tracked
    hasSubmitted = false;
    @tracked
    isGradeModalOpen = false;
    @tracked
    courseToEdit: null | TASCourseModel = null;
    @service
    store: StoreService;
    get programTemplate() {
        return this.args.model.tasProgramInstance.tasProgramTemplate;
    }
    get evidenceAttachments() {
        return this.args.model.evidenceAssets;
    }
    get applicationApprovalAttachments() {
        return this.args.model.applicationApprovalAssets;
    }
    get attachmentsListDescription() {
        return (getAllValuesForTasField('COMPLETION_DOCUMENTS_ATTACHMENT_FIELD', this.programTemplate.fields).join(', ') || 'None Required');
    }
    get coursesApprovalAttachments() {
        return (getAllValuesForTasField('COURSE_APPROVAL_DOCUMENTS_ATTACHMENT_FIELD', this.programTemplate.fields).join(', ') || 'None Required');
    }
    get requireAttachmentUploads() {
        if (this.programTemplate.isCertificateProgram) {
            return (this.programTemplate.requireAttachmentsForCourseCompletion || this.programTemplate.isEvidenceRequired);
        }
        return this.programTemplate.isEvidenceRequired;
    }
    get conditionsForSubmit() {
        const conditionsMap1 = {
            'TAS.ProgramType.3': this.programTemplate.applicationApproveEvidenceConditions
        };
        return (conditionsMap1[this.programTemplate.typeClassification as keyof typeof conditionsMap] || []);
    }
    get showSubmitForEvidenceReview() {
        return this.args.model.state === 'TAS.ApplicationState.ATTEND';
    }
    submitForEvidenceApproval = dropTask(async ()=>{
        try {
            this.submitError = null;
            this.hasSubmitted = true;
            await this.store.adapterFor('tas-application').approveCourseEvidence(this.args.model);
        } catch (error1) {
            console.error('Failed to submit application:', error1);
            this.submitError = error1;
        }
    });
    get hasAgreedAllConditions() {
        if (!this.conditionsForSubmit.length) {
            return true;
        }
        return this.conditionsForSubmit.length === this.acceptedConditions.length;
    }
    get hasRequiredAttachments() {
        return this.args.model.hasRequiredEvidence;
    }
    get canSubmitApplication() {
        return (this.hasAgreedAllConditions && this.hasRequiredAttachments && this.args.model.state === 'TAS.ApplicationState.ATTEND');
    }
    get expenseType(): string {
        const expenseType1 = getSingleValueForTasField('EXPENSE_TYPE', this.programTemplate.fields);
        return expenseType1 ? `${expenseType1}` : `Education`;
    }
    @action
    closeApplicationSubmittedModal() {
        this.args.controller.applicationSubmitted = false;
    }
    @action
    didUpdateAcceptedConditions(conditions1: ConditionModel[] = []) {
        this.acceptedConditions = conditions1;
    }
    @action
    openGradeModal(course1: TASCourseModel) {
        this.isGradeModalOpen = true;
        this.courseToEdit = course1;
    }
    @action
    closeGradeModal() {
        this.isGradeModalOpen = false;
        this.courseToEdit = null;
    }
    @action
    updateGrade(grade1: string) {
        setSingleValueForTasField('COURSE_GRADE', grade1, this.courseToEdit!.fields);
        this.courseToEdit!.save();
    }
    static{
        template(`
    <AppPage @overrideTitle={{true}}>
      <:title>
        <TasPageHeader>
          <:title as |title|>
            <title.BackLink @route="authenticated.tas.dashboard">
              {{t "tas.default"}}
            </title.BackLink>
          </:title>
        </TasPageHeader>
      </:title>
      <:content>
        <div class="grid sm:grid-cols-4 gap-4">
          <div class="sm:col-span-3">
            <h1 class="tio-h1">{{@model.applicationName}}</h1>
            <NavTabs @model={{@model}} class="mb-4" as |navtabs|>
              <navtabs.item @route="authenticated.tas.applications.show" @model={{@model}}>
                {{t "tas.applications.default"}}
              </navtabs.item>
              <navtabs.item @route="authenticated.tas.applications.history" @model={{@model}}>
                {{t "tas.history.default"}}
              </navtabs.item>
            </NavTabs>
            <section class="mb-12">
              <Alert @application={{@model}} />
              <h2
                class="font-medium text-tio-gray-700 my-4"
              >{{@model.tasProgramInstance.programName}}</h2>
              {{! APPLICANT TABLE }}
              <Table @isLoading={{false}}>
                <:thead>
                  {{#let
                    "py-3 pl-4 pr-3 text-left text-sm uppercase font-semibold text-gray-900"
                    as |cellClass|
                  }}
                    <tr>
                      <th scope="col" class="{{cellClass}} sm:pl-0">
                        {{t "tas.applications.applicant"}}
                      </th>
                      <th class={{cellClass}}>{{t "tas.applications.amount"}}</th>
                      <th class={{cellClass}}>{{t "tas.applications.submission_date"}}</th>
                      <th class={{cellClass}}>{{t "tas.applications.status"}}</th>
                    </tr>
                  {{/let}}
                </:thead>
                <:tbody>
                  <tr>
                    {{#let "whitespace-nowrap px-3 py-4 text-sm text-gray-500" as |cellClass|}}
                      <td
                        class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                      >
                        {{@model.applicant}}
                      </td>
                      <td class={{cellClass}}>
                        {{formatAsDollars @model.requestedTotal}}
                      </td>
                      <td class={{cellClass}}>
                        {{toLocaleDateString @model.courseSubmittedDate}}
                      </td>
                      <td class={{cellClass}}>
                        {{#if @model.inRepayment}}
                          <Badge @intent={{tasStateIntent @model.paymentState}}>
                            {{tasStateLabel @model.paymentState}}
                          </Badge>
                        {{else}}
                          <Badge @intent={{tasStateIntent @model.state @model.fromState}}>
                            {{tasStateLabel @model.state @model.fromState}}
                          </Badge>
                        {{/if}}
                      </td>
                    {{/let}}
                  </tr>
                </:tbody>
              </Table>
            </section>
            {{! APPLICATION DETAILS }}
            <section class="mb-12">
              <h2 class="tio-h2">{{t "tas.applications.details"}}</h2>
              <Divider class="my-2" />
              <DescriptionList class="sm:w-2/3" as |List|>
                <List.Group>
                  <List.Term>{{t "tas.applications.application_name"}}</List.Term>
                  <List.Details>{{@model.applicationName}}</List.Details>
                </List.Group>
                <List.Group>
                  <List.Term>{{t "tas.applications.start_date"}}</List.Term>
                  <List.Details>{{toLocaleDateString @model.coursesBeginDate}}</List.Details>
                </List.Group>
              </DescriptionList>
            </section>
            {{! COURSE DETAILS - EXPENSES }}
            <section class="mb-12">
              <h2 class="tio-h2">{{this.expenseType}}</h2>
              <Divider class="my-2" />
              <ActionableList @striped={{true}} as |l|>
                {{#each @model.tasCourses as |course|}}
                  <l.Row class="w-full">
                    <l.Term class="w-1/3">{{course.courseName}}</l.Term>
                    <l.Item class="w-1/3 m-auto">
                      {{safeFormatNumber
                        (divide course.courseTotal 100)
                        style="currency"
                        currency="USD"
                      }}
                    </l.Item>
                    <l.Item
                      class="flex items-center m-auto font-semibold text-ocean-600 cursor-pointer"
                      {{on "click" (fn this.openGradeModal course)}}
                    >
                      {{or course.courseGrade "+Result"}}
                      <PencilSquare class="ml-2 h-4 w-4" />
                    </l.Item>
                  </l.Row>
                {{/each}}
              </ActionableList>
            </section>
            {{! ATTACHMENTS }}
            <section class="mb-12">
              <h2 class="tio-h2">{{t "tas.applications.attachments"}}</h2>
              <Divider class="my-2" />
              <div class="flex items-center w-full">
                <AttachmentList
                  @assets={{@model.evidenceAssets}}
                  @disabled={{true}}
                  class="w-full md:w-1/2 lg:w-1/3"
                />
              </div>
            </section>
          </div>
          {{! Evidence Submission and Payment Details }}
          <div class="col-span-1">
            <div class="max-w-sm mx-auto">
              {{! Submit For Evidence Review }}
              {{#if this.showSubmitForEvidenceReview}}
                <CardGeneric class="h-fit !w-full pt-0 !px-4">
                  <:header>
                    <header
                      class="border-b bg-tio-gray-25 flex items-center gap-2 py-2 !px-6 text-sm font-medium tracking-wide -mx-4"
                    >
                      <ClipboardDocumentList class="h-8 w-8" />
                      <span>{{t "application.submit_evidence_for_review"}}</span>
                    </header>
                  </:header>
                  <:body>
                    <section class="mb-5">
                      <p class="text-sm">
                        <span class="text-error-400 ml-1">
                          *
                        </span>
                        {{t "benefits_summary.required_items"}}
                      </p>
                      <div>
                        <div class="flex flex-row justify-between items-center">
                          <p class="font-medium">{{t "benefits_summary.attachments"}}
                            <span class="text-error-400 ml-1">
                              {{#if this.requireAttachmentUploads}}*{{/if}}
                            </span>
                          </p>

                          <div>
                            <UploadAttachments
                              @programTemplate={{this.programTemplate}}
                              @classification="COMPLETION_DOCUMENTS"
                              @attachable={{@model}}
                              @attachableType="tasApplication"
                              @useDropzone={{false}}
                              @iconOnlyUpload={{true}}
                              @hideAttachments={{true}}
                              @hideInstructions={{true}}
                              {{!-- @disabled={{this.disableAttachmentUpload}} --}}
                            />
                          </div>
                        </div>

                        <div class="flex items-center gap-3">
                          {{#if @model.isMissingRequiredEvidence}}
                            <CheckCircle class="h-6 w-6 text-tio-gray-200" />
                          {{else}}
                            <CheckCircleSolid class="h-6 w-6 text-violet-500" />
                          {{/if}}
                          <p>{{this.attachmentsListDescription}}</p>
                        </div>
                      </div>
                      <Divider class="mt-4" />

                      {{#if this.conditionsForSubmit.length}}
                        <AcceptConditions
                          @conditions={{this.conditionsForSubmit}}
                          @onChange={{this.didUpdateAcceptedConditions}}
                        />
                      {{/if}}
                    </section>

                    <div class="py-4">
                      <Button
                        class="flex justify-center w-full py-3 px-6 me-2 mb-2 font-medium text-lg text-white focus:outline-none bg-ocean-600 rounded-lg hover:bg-ocean-400"
                        disabled={{not this.canSubmitApplication}}
                        @isRunning={{this.submitForEvidenceApproval.isRunning}}
                        {{on "click" this.submitForEvidenceApproval.perform}}
                      >
                        {{t "benefits_summary.submit_evidence"}}
                      </Button>
                      <p class="text-sm font-light text-center">
                        {{t "benefits_summary.helper_text"}}
                      </p>
                      {{#if this.submitError}}
                        <TioErrorMessages
                          @showErrors={{this.hasSubmitted}}
                          @error={{this.submitError}}
                          class="text-sm"
                          @icon="warning"
                        />
                      {{/if}}
                    </div>
                  </:body>
                </CardGeneric>
              {{/if}}

              <PaymentDetailsCard
                @isPaid={{@model.isPaid}}
                @requestedTotal={{@model.requestedTotal}}
                @payments={{@model.paymentsHistory}}
              />
              <ApplicationProcess
                @application={{@model}}
                @programTemplate={{this.programTemplate}}
              />
            </div>
          </div>
        </div>
      </:content>
    </AppPage>

    <Modal
      @isOpen={{@controller.applicationSubmitted}}
      @onClose={{this.closeApplicationSubmittedModal}}
      class="min-h-64 !max-w-screen-sm"
      @backdrop="faded"
      as |m|
    >
      <m.Header class="text-midnight font-semibold">
        {{t "tuition_assistance.submitted.application_submitted"}}</m.Header>
      <m.Body>
        <CourseSubmitted @application={{@model}} @courseApprovalRequired={{true}} />
        <div class="mb-4 flex justify-center">
          <TioButton
            @onClick={{this.closeApplicationSubmittedModal}}
            @outlined={{true}}
            class="w-48"
          >
            {{t "common.close"}}
          </TioButton>
        </div>
      </m.Body>
    </Modal>
    <Modal
      @isOpen={{this.isGradeModalOpen}}
      @onClose={{this.closeGradeModal}}
      class="!max-w-screen-sm"
      @backdrop="faded"
      as |m|
    >
      <m.Header>
        <h1 class="text-midnight font-semibold">{{t "benefits_summary.add_result"}}</h1>
      </m.Header>
      <m.Body>
        <div class="flex justify-between mb-6">
          <h2 class="text-xl font-semibold text-gray-700">
            {{! @glint-expect-error}}
            {{getSingleValueForTasField "COURSE_NAME" this.courseToEdit.fields}}
          </h2>
          <div class="w-72">
            <TuitionAssistanceFormsCoursesGradeSelectOptions
              {{! @glint-expect-error}}
              @programTemplate={{this.courseToEdit.tasApplication.tasProgramInstance.tasProgramTemplate}}
              @updateCourseGrade={{this.updateGrade}}
              {{! @glint-expect-error}}
              @fieldsCopy={{this.courseToEdit.fields}}
              @renderInPlace={{false}}
            />
          </div>
        </div>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onCancel={{this.closeGradeModal}}
          @onSubmit={{this.closeGradeModal}}
        />
      </m.Footer>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AuthenticatedTasApplicationsShowComponent);
