import ApplicationAdapter from './application';

/**
 * An extended adapter for the `TasParticipant` model.
 *
 * @memberof adapters
 */

class TasProgramTemplateAdapter extends ApplicationAdapter {
  /**
   * Sends a request to apply for assistance for a specific TAS program template.
   *
   * @param {string}  templateId - The ID of the TAS program template.
   * @param {string}  employeeId - The ID of the employee applying for assistance.
   * @param {string}  eligibilityDate - The eligibility date for applying.
   *
   * @return {Promise<object>} The response data from the server.
   * @throws {Error} If the request fails or the server returns an error.
   */

  async initializeTypedProgram(
    templateId: string,
    employeeId: string,
    eligibilityDate: string
  ): Promise<void> {
    const url = this.buildURL('tas-program-templates', templateId) + '/apply-for-assistance';
    const payload = {
      employee_id: employeeId,
      eligibility_date: eligibilityDate,
    };

    try {
      const response = await this.ajax(url, 'POST', { data: payload });
      return response.data;
    } catch (error) {
      // Handle any errors here, possibly rethrowing or logging them
      console.error('Error applying for assistance:', error);
      throw error; // or handle it in a way that makes sense for your application
    }
  }
}

export default TasProgramTemplateAdapter;
