import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import AppPage from 'tio-employee/components/app/page';
import TioPageHeader from 'tio-common/components/tio/page/header';
import TioPageTabs from 'tio-common/components/tio/page/tabs';
import AppPageArticle from 'tio-employee/components/app/page/article';
import { t } from 'ember-intl';
export default RouteTemplate(template(`
    <div class="h-screen pb-20 w-full overflow-auto">
      <AppPage>
        <TioPageHeader as |header|>
          <header.title>{{t "kitchen_sink.welcome"}}</header.title>
          <header.breadcrumbs as |b|>
            <b.crumb @route="authenticated.index" @label="Home" />
            <b.crumb @route="kitchen-sink" @label="Kitchen Sink" />
          </header.breadcrumbs>
        </TioPageHeader>

        <TioPageTabs as |tabs|>
          <tabs.tab @route="kitchen-sink.index" @label="common" />
          <tabs.tab @route="kitchen-sink.employee" @label="employee" />
        </TioPageTabs>
        <AppPageArticle>
          {{outlet}}
        </AppPageArticle>
      </AppPage>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
