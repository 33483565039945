import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type SessionContextService from 'tio-employee/services/session-context';
import type { IntlService } from 'ember-intl';
import type { NotificationsService } from '@frontile/notifications';
import type TASProgramTemplateModel from 'tio-common/models/tas-program-template';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
// import type RouterService from '@ember/routing/router-service';

export default class AuthenticatedTuitionAssistanceDependentProgramsRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;
  @service declare notifications: NotificationsService;
  @service declare intl: IntlService;

  async model() {
    const currentEmployee = this.sessionContext.currentEmployee;

    let templates = await this.store.query('tas-program-template', {
      filter: {
        state: 'TAS.ProgramTemplateState.LAUNCHED',
        byEligibility: currentEmployee.id,
        company: currentEmployee.company.id,
      },
      include: 'company.company-setting',
      reload: true,
      page: {
        limit: 100,
      },
    });

    let activeInstances = await this.store.query('tas-program-instance', {
      filter: { employee: currentEmployee.id, active: true },
      include: 'tas-program-template,tas-applications',
      page: {
        limit: 100,
      },
    });

    let pastInstances = await this.store.query('tas-program-instance', {
      filter: { employee: currentEmployee.id, past: true },
      include: 'tas-program-template',
    });

    const employee = await this.store.findRecord('employee', currentEmployee.id, {
      include: 'dependents',
    });

    const isDependentProgram = (program: TASProgramTemplateModel) => {
      return program.isDependentProgram;
    };

    const isDependentInstance = (instance: TASProgramInstanceModel) => {
      return instance.isDependentProgram;
    };
    templates = await templates.filter(isDependentProgram);
    activeInstances = await activeInstances.filter(isDependentInstance);
    pastInstances = await pastInstances.filter(isDependentInstance);

    return {
      activeInstances,
      pastInstances,
      employee,
      templates,
    };
  }
}
