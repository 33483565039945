import { template } from "@ember/template-compiler";
import type PersonModel from 'tio-common/models/person';
import RecommendationHistory from './results/recommendation-history';
import ResultsDashboardTable from '../forgiveness-estimator/results-dashboard-table';
import screenIs from 'tio-common/helpers/screen-is';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
import type RecommendationModel from 'tio-common/models/recommendation';
import WhatHappensNext from './results/what-happens-next';
import type AccountModel from 'tio-common/models/account';
export interface RecDashResultsSig {
    Args: {
        allRecommendations: RecommendationModel[];
        accounts: AccountModel[];
        loanSummary: LoanSummary;
        person: PersonModel;
        programType: 'pslf' | 'sf' | 'idr';
        recommendation: RecommendationModel;
    };
    Element: HTMLElement;
}
const DashResults: TOC<RecDashResultsSig> = template(`
  {{#if (screenIs "md")}}
    <ResultsDashboardTable
      @recommendation={{@recommendation}}
      @programType={{@programType}}
      ...attributes
    >
      <WhatHappensNext
        class="col-span-3"
        @accounts={{@accounts}}
        @person={{@person}}
        @programType={{@programType}}
        @loanSummary={{@loanSummary}}
      />
      <RecommendationHistory
        class="col-span-7"
        @programType={{@programType}}
        @allRecommendations={{@allRecommendations}}
      />
    </ResultsDashboardTable>
  {{else}}
    <ResultsDashboardTable
      class="col-span-6"
      @programType={{@programType}}
      @recommendation={{@recommendation}}
    />
    <WhatHappensNext
      class="col-span-6"
      @accounts={{@accounts}}
      @person={{@person}}
      @programType={{@programType}}
      @loanSummary={{@loanSummary}}
    />
    <RecommendationHistory
      class="col-span-6"
      @programType={{@programType}}
      @allRecommendations={{@allRecommendations}}
    />
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DashResults;
