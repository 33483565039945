import { template } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
import divide from 'ember-math-helpers/helpers/div';
import { or } from 'tio-ui/utilities';
import Table from 'tio-common/components/table/index';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import type AccountingPaymentModel from 'tio-common/models/accounting-payment';
interface S {
    Args: {
        isLoading: boolean;
        model: AccountingPaymentModel[];
    };
    Element: HTMLTableElement;
}
const ContributionsAccountingPaymentsTable: TOC<S> = template(`
  <Table @isLoading={{@isLoading}} ...attributes>
    <:thead>
      <tr class="font-semibold text-left bg-ocean-50">
        {{#let "px-2 py-1 border border-gray-300" as |cellClass|}}
          <th class={{cellClass}}>{{t "contributions.payment_date"}}</th>
          <th class={{cellClass}}>{{t "contributions.applied_to_account"}}</th>
          <th class={{cellClass}}>{{t "contributions.payment"}}</th>
        {{/let}}
      </tr>
    </:thead>
    <:tbody>
      {{#let "px-2 py-1 border border-gray-300" as |cellClass|}}
        {{#each @model as |payment|}}
          <tr>
            <td class={{cellClass}}>
              {{safeFormatDate payment.transaction_date month="short" day="2-digit" year="numeric"}}
            </td>
            <td class={{cellClass}}>
              {{or payment.servicer (t "unknown")}}
            </td>
            <td class={{cellClass}}>
              {{#if payment.amount}}
                {{safeFormatNumber (divide payment.amount 100) style="currency" currency="USD"}}
              {{/if}}
            </td>
          </tr>
        {{else}}
          <tr>
            <td colspan="3" class={{cellClass}}>
              {{t "contributions.no_payments_found"}}
            </td>
          </tr>
        {{/each}}
      {{/let}}
    </:tbody>
  </Table>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ContributionsAccountingPaymentsTable;
