import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { t } from 'ember-intl';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import FormRadioGroup from '@frontile/forms-legacy/components/form-radio-group';
import MaterialIcon from 'tio-common/components/material-icon';
import TioButton from 'tio-common/components/tio/button';
interface SyfAddOrLinkRouteSignature {
    Blocks: {
        default: [];
    };
}
type ContinueTargetOption = 'authenticated.syf.account-linking' | 'authenticated.syf.payments.new';
// NB: unused as of https://tuitionio.atlassian.net/browse/DEV-585, could be reintroduced
// later - james 2024-02-12
let SyfAddOrLinkComponent = class SyfAddOrLinkComponent extends Component<SyfAddOrLinkRouteSignature> {
    @tracked
    continueTarget: ContinueTargetOption = 'authenticated.syf.account-linking';
    @action
    setContinueTarget(newValue1: unknown) {
        this.continueTarget = newValue1 as ContinueTargetOption;
    }
    static{
        template(`
    <h2 class="text-midnight text-lg font-semibold mt-4 mb-8">
      {{t "syf.payments.missing.header"}}
    </h2>
    <div class="grid md:grid-cols-4">
      <div class="md:col-start-2 md:col-span-2">
        <div class="w-3/4 sm:w-96 m-auto mb-12">
          {{svgJar "smartphone-config" role="img"}}
        </div>
        <h3 class="font-semibold text-lg mb-4 text-gray-700">
          {{t "syf.payments.missing.subheader"}}
        </h3>
        <FormRadioGroup
          data-legacy-input
          @value={{this.continueTarget}}
          @onChange={{this.setContinueTarget}}
          @size="lg"
          class="mb-12"
          as |Radio|
        >
          {{! @glint-expect-error: look into frontile types}}
          <Radio @value="authenticated.syf.account-linking" @containerClass="mb-6">
            <div class="mb-3 text-gray-700">
              {{t "syf.payments.missing.options.link_account_label"}}
              <span class="text-violet-600 font-medium ml-3">
                {{t "syf.payments.missing.options.link_account_annotation"}}
              </span>
            </div>
            <p class="text-sm text-gray-700">
              {{t "syf.payments.missing.options.link_account_detail"}}
            </p>
          </Radio>
          {{! @glint-expect-error: look into frontile types}}
          <Radio @value="authenticated.syf.payments.new" @containerClass="mb-6">
            <div class="mb-3 text-gray-700">{{t
                "syf.payments.missing.options.add_payment_label"
              }}</div>
            <p class="mb-3 text-sm text-gray-700">
              {{t "syf.payments.missing.options.add_payment_detail"}}
            </p>
            <div class="flex">
              <MaterialIcon @icon="error_outline" class="!text-error-400 !border-error-400 mr-3" />
              <p class="text-sm text-gray-700">
                {{t "syf.payments.missing.options.add_payment_disclaimer"}}
              </p>
            </div>
          </Radio>
        </FormRadioGroup>
        <div class="flex justify-around">
          <TioButton @linkTo="authenticated.syf.dashboard" @outlined={{true}} class="w-1/4">
            {{t "syf.payments.missing.cancel"}}
          </TioButton>
          <TioButton @linkTo={{this.continueTarget}} class="w-1/4">
            {{t "syf.payments.missing.submit"}}
          </TioButton>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(SyfAddOrLinkComponent);
