import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import AppPage from 'tio-employee/components/app/page';
import TioPageHeaderTitle from 'tio-common/components/tio/page/header/title';
import { t } from 'ember-intl';
interface SyfRouteSignature {
    Blocks: {
        default: [];
    };
}
export default RouteTemplate<SyfRouteSignature>(template(`
    <AppPage>
      <TioPageHeaderTitle>
        {{t "syf.default"}}
      </TioPageHeaderTitle>
      <div class="pb-16">
        {{outlet}}
      </div>
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
