import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import { t } from 'ember-intl';
import AppPage from 'tio-employee/components/app/page';
import AppPageContent from 'tio-employee/components/app/page/content';
import Component from '@glimmer/component';
import PslfSignFormPage from 'tio-employee/components/pslf/sign-form-page';
import RouteTemplate from 'ember-route-template';
import TioPageHeaderTitle from 'tio-common/components/tio/page/header/title';
import type { IntlService } from 'ember-intl';
import type PslfAdminFormSignController from 'tio-employee/controllers/authenticated/admin/pslf/form/sign';
import type PslfFormModel from 'tio-common/models/pslf-form';
export interface PslfFormSignRouteSignature {
    Args: {
        model: PslfFormModel;
        controller: PslfAdminFormSignController;
    };
}
let AdminPslfFormSignRoute = class AdminPslfFormSignRoute extends Component<PslfFormSignRouteSignature> {
    @service
    intl: IntlService;
    get borrowerName() {
        return this.args.model.borrowerFields['borrower-name'];
    }
    get isBorrower() {
        return this.args.controller.signerType === 'borrower';
    }
    get alreadySigned() {
        const status1 = this.args.model.status;
        const isSigned1 = this.isBorrower ? ![
            'created',
            'pending'
        ].includes(status1) : ![
            'created',
            'borrower_signed'
        ].includes(status1);
        return isSigned1;
    }
    get employerMessage() {
        return this.intl.t('pslf.complete_pslf.done_employer_subheader', {
            date: this.intl.formatDate(this.args.model.borrowerCompletedAt),
            name: this.borrowerName
        });
    }
    get continueLink() {
        return this.isBorrower ? 'authenticated.pslf.dashboard.index' : 'authenticated.admin.pslf';
    }
    static{
        template(`
    <AppPage>
      <TioPageHeaderTitle>
        {{t "pslf.default_full"}}
      </TioPageHeaderTitle>
      <AppPageContent>
        <PslfSignFormPage @form={{@model}} @signer={{@controller.signerType}} />
      </AppPageContent>
    </AppPage>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AdminPslfFormSignRoute);
