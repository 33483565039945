import { template } from "@ember/template-compiler";
import AppPage from 'tio-employee/components/app/page';
//import TioPageTabs from 'tio-common/components/tio/page/tabs';
import RouteTemplate from 'ember-route-template';
import type ProfileController from 'tio-employee/controllers/authenticated/profile';
import { t } from 'ember-intl';
import type UserModel from 'tio-common/models/user';
import MyProfile from '../../components/app/profile/my-profile';
interface S {
    Args: {
        controller: ProfileController;
        model: UserModel;
    };
}
export default RouteTemplate<S>(//  NOTE: This route should only be called if a user directly
//  visits the '/' path within this app *while logged in*.
template(`
    <AppPage @title={{t "header.user_menu.title" name=@model.person.firstName}}>
      <MyProfile @user={{@model}} />
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
