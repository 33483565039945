import { service } from '@ember/service';
import { set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import jwt_decode from 'jwt-decode';
import SessionService from 'ember-simple-auth/services/session';
import type ApiService from '../api';
import type RoleView from 'tio-common/models/role-view';
import type RootLoggerService from '../root-logger';
import type RouterService from '@ember/routing/router-service';
import type { RouteInfo, RouteInfoWithAttributes } from '@ember/routing/router-service';

interface AuthSessionPayload {
  iat: number;
  exp: number;
  id: number;
  employeeIds: number[];
  roles: RoleView[];
}

const warnBeforeExpirationMin = 3;

export default class AppSessionService extends SessionService {
  @service declare api: ApiService;
  @service declare rootLogger: RootLoggerService;
  @service declare router: RouterService;

  // @ts-expect-error: most likely a bug
  logger = this.rootLogger.get('service:session');

  /**
   * A Unix timestamp representing the time at which the session will expire.
   */
  @tracked expireAt = 0;

  /**
   * Whether the session is expiring soon. This is set to `true` during the last few minutes of the
   * session, as defined by the internal constant `warnBeforeExpirationMin`.
   */
  @tracked isSessionExpiring = false;

  /**
   * Returns a value that is deleted when the session is invalidated.
   *
   * @param {string} property The name of the property to get
   *
   * @returns {any} The value of the property
   */
  getTransientValue(property: string) {
    return this.data.transient?.[property];
  }

  /**
   * Sets a value that is deleted when the session is invalidated.
   *
   * @param {string} property The name of the property to set
   * @param {any} value The value to set
   */
  setTransientValue(property: string, value: unknown) {
    // NOTE: To ensure the value is persisted to local storage or a cookie, we
    //       need to set the entire `transient` object. Also, `this.set` is
    //       overridden in `SessionService`, so this is not a "classic" method.
    //       [twl 26.Jul.23]

    set(this, 'data.transient', {
      ...this.data.transient,
      [property]: value,
    });
  }

  /**
   * Returns a value that persists indefinitely.
   *
   * @param {string} property The name of the property to get
   *
   * @returns {any} The value of the property
   */
  getPermanentValue(property: string) {
    return this.data.permanent?.[property];
  }

  /**
   * Sets a value that persists indefinitely.
   *
   * @param {string} property The name of the property to set
   * @param {any} value The value to set
   */
  setPermanentValue(property: string, value: unknown) {
    // NOTE: To ensure the value is persisted to local storage or a cookie, we
    //       need to set the entire `permanent` object.  Also, `this.set` is
    //       overridden in `SessionService`, so this is not a "classic" method.
    //       [twl 26.Jul.23]

    set(this, 'data.permanent', {
      ...this.data.permanent,
      [property]: value,
    });
  }

  setup() {
    super.setup().then(() => this.#configureSessionTimers());
  }

  handleAuthentication(routeAfterAuthentication: unknown) {
    // `noTransitionAfterAuth` is passed in the data parameter to `authenticate`
    const { noTransitionAfterAuth } = this.session.get('authenticated');

    this.data.authenticated.routeAfterAuthentication = routeAfterAuthentication;

    if (!noTransitionAfterAuth) {
      super.handleAuthentication(routeAfterAuthentication);
    }

    this.#configureSessionTimers();
  }

  handleInvalidation(route?: string | null) {
    const logoutRoute = '/logout';

    set(this, 'data.transient', {});

    if (route) {
      this.setRouteAfterAuthentication(route);
    }

    this.router.transitionTo(logoutRoute);
  }

  /**
   * Saves the route to redirect to after the user is fully authenticated.
   * Uses sessionStorage to persist the route.
   *
   * @param {string | null} currentRoute The current route to save
   */
  setRouteAfterAuthentication(currentRoute?: string | null) {
    const route = currentRoute || this.#routeInfoUrl(this.attemptedTransition?.to);
    if (route && !['/logged-out', '/logout', '/'].includes(route)) {
      sessionStorage.setItem('transitionAfterAuth', route);
    }
  }

  /**
   * Transitions to the route saved in sessionStorage after the user is fully authenticated.
   */
  transitionToRouteAfterAuthentication() {
    const route = sessionStorage.getItem('transitionAfterAuth');

    if (route) {
      this.router.transitionTo(route);
      sessionStorage.removeItem('transitionAfterAuth');
    }
  }

  async renew() {
    // TODO: Think about using an endpoint that isn't SAML-specific. This technically works, but
    //       it's overloading the purpose of this endpoint. [twl 18.Oct.23]
    const tokenInfo = await this.api.call('/saml/token_exchange');

    await this.authenticate('authenticator:saml', tokenInfo);

    this.#configureSessionTimers();
  }

  /**
   * Parses attemptedTransition.to object to get the route name and query params.
   * most of this logic is borrowed from this thread: https://discord.com/channels/480462759797063690/496695347582861334/1267497942877470770
   *
   * @param {RouteInfo | RouteInfoWithAttributes,} routeInfo The route info object from attemptedTransition
   */
  #routeInfoUrl(routeInfo?: RouteInfo | RouteInfoWithAttributes) {
    if (!routeInfo) {
      return;
    }
    const targetRoute = routeInfo.name;
    const allRouteParamValues: unknown[] = [];
    let allRouteQueryParams = {};

    // @ts-expect-error: not sure on this one, its expecting to return a boolean but breaks when return anything. jpc
    routeInfo.find((info) => {
      info.paramNames.forEach((paramName: string) => {
        const paramValue = info.params?.[paramName];
        if (paramValue) {
          allRouteParamValues.push(paramValue);
        }
        allRouteQueryParams = {
          ...allRouteQueryParams,
          ...info.queryParams,
        };
      });
    });

    return this.router.urlFor(targetRoute, ...allRouteParamValues, {
      queryParams: allRouteQueryParams,
    });
  }

  #configureSessionTimers() {
    const { access_token } = this.session.get('authenticated');
    if (!access_token) {
      return;
    }
    const payload: AuthSessionPayload = jwt_decode(access_token);

    this.isSessionExpiring = false;
    this.expireAt = payload.exp * 1000;

    // `#setTimer` triggers immediately if the second parameter is in the past, so set `expire`
    // first, so we don't set `isSessionExpiring` if the session is already expired
    this.#setTimer('expire', this.expireAt, () => {
      if (this.isAuthenticated) {
        this.logger.debug(`Session expired`);
        // Passing the currentURL to handleInvalidation to store the previous route for redirect after login
        this.handleInvalidation(this.router.currentURL);
      }
    });
    this.#setTimer('warn', this.expireAt - warnBeforeExpirationMin * 60 * 1000, () => {
      if (this.isAuthenticated) {
        this.logger.debug(
          `Session expires in ${warnBeforeExpirationMin} min at ${new Date(this.expireAt)}`
        );
        this.isSessionExpiring = true;
      }
    });
  }

  #setTimer(type: string, timestamp: number, callback: () => void) {
    const idProperty = `${type}TimerId`;
    const delay = timestamp - Date.now();

    if (this[idProperty]) {
      clearTimeout(this[idProperty]);
    }

    if (delay > 0) {
      this[idProperty] = setTimeout(callback, delay);

      this.logger.debug(
        `Set '${type}' timer for ${delay / 1000 / 60} min at ${new Date(timestamp)}`
      );
    } else {
      callback();
    }
  }
}
